import { Col, Row } from "react-bootstrap";
import {
    AppFormLabel,
    AppIcon,
    ClickAction,
    LinkAction,
} from "../../components";
import { FroalaEditorParser } from "../../components/FroalaEditorView";
import "./assets/scss/style.scss";
import { useState } from "react";
import { useTranslation } from "react-i18next";
interface AppQuestionListItemProps {
    question: any;
    handleDelete?: (id: number) => void;
    isPrepQuestionList?: boolean;
    isPrepPreviewList?: boolean;
    showActions?: boolean;
    handleCheckBox?: (e, id) => void;
    checked?: boolean;
    configTotalQue?: number;
    selectedTotalQue?: number;
}

export const AppQuestionListItem = ({
    question,
    checked,
    handleDelete,
    isPrepQuestionList,
    isPrepPreviewList,
    showActions = true,
    handleCheckBox,
    configTotalQue = 0,
    selectedTotalQue = 0,
}: AppQuestionListItemProps) => {
    const [isExapnded, setIsExpanded] = useState(false);
    const { t } = useTranslation();
    const [selected, setSelected] = useState(checked || false);
    const examName = question?.exams?.filter((e) => e.name != undefined);
    const tags = question?.tags?.filter((tag) => tag.name != undefined);

    return (
        <div
            className={`col-12 mb-3 mb-lg-4 mt-2 pb-5 p-4 ps-2 card question-wrapper ${question.difficultyLevel} ${isPrepQuestionList && checked ? "active " : ""} ${isPrepQuestionList ? "cursor-pointer" : ""} `}
            onClick={() => {
                if (
                    isPrepQuestionList &&
                    (selectedTotalQue < configTotalQue || selected)
                ) {
                    setSelected(!selected);
                    if (handleCheckBox) handleCheckBox(!selected, question._id);
                }
            }}
        >
            <Row>
                <Col
                    className="question-icon p-0"
                    onClick={(e) => {
                        e.stopPropagation();
                        return isExapnded
                            ? setIsExpanded(false)
                            : setIsExpanded(true);
                    }}
                >
                    {isExapnded ? (
                        <AppIcon name={"ChevronDown"} />
                    ) : (
                        <AppIcon name={"next"} />
                    )}
                </Col>
                <Col className="ps-0">
                    <Row className={"mb-2"}>
                        <Col>
                            <p className=" question m-0">
                                <FroalaEditorParser
                                    model={question?.question?.description}
                                />
                            </p>
                        </Col>
                    </Row>
                    <Row className={" author-info"}>
                        <Col style={{ maxWidth: "max-content" }}>
                            <Row>
                                <Col style={{ maxWidth: "max-content" }}>
                                    {question.isMultiSelect
                                        ? " Multi"
                                        : " Single"}
                                </Col>
                            </Row>
                        </Col>
                        |
                        <Col
                            style={{ maxWidth: "max-content" }}
                            className="d-flex justify-content-end"
                        >
                            Used: {question.linkedCount}
                        </Col>
                    </Row>

                    {isExapnded && (
                        <Col className={"mb-2 mt-3"}>
                            <AppFormLabel label={"Options"} required={false} />
                            {question.options?.map((option, i) => {
                                return (
                                    <div
                                        className={`d-flex question-list-options mt-3  ${option.isCorrect ? "active" : ""}`}
                                    >
                                        <div className="d-flex">
                                            <span className="me-2">
                                                {i == 0
                                                    ? "A. "
                                                    : i == 1
                                                      ? "B. "
                                                      : i == 2
                                                        ? "C. "
                                                        : "D. "}
                                            </span>
                                            <FroalaEditorParser
                                                model={option?.description}
                                            />
                                        </div>
                                        {option.isCorrect && (
                                            <i
                                                className="fa fa-check-circle ms-2"
                                                style={{
                                                    marginTop: "2px",
                                                    color: "green",
                                                }}
                                                aria-hidden="true"
                                            ></i>
                                        )}
                                    </div>
                                );
                            })}
                            {question?.solution?.description && (
                                <Col className="mt-3 p-3 solution-wrapper">
                                    <AppFormLabel
                                        label={t(
                                            "question.users.form.label.solution"
                                        )}
                                        required={false}
                                    />
                                    <FroalaEditorParser
                                        model={question?.solution?.description}
                                    />
                                </Col>
                            )}

                            {(examName?.length ||
                                question?.subject?.name ||
                                question?.topic?.name) && (
                                <Row>
                                    <Col style={{ maxWidth: "max-content" }}>
                                        <div
                                            className={`exam-info-wrapper mb-2 mt-4`}
                                        >
                                            <span className={"me-2"}>
                                                {examName.map(
                                                    (exam, i) =>
                                                        `${i !== 0 ? ", " : ""}${exam.name}`
                                                )}
                                            </span>
                                            {question?.subject?.name && (
                                                <>
                                                    |
                                                    <span
                                                        className={"me-2 ms-2"}
                                                    >
                                                        {
                                                            question?.subject
                                                                ?.name
                                                        }
                                                    </span>
                                                </>
                                            )}
                                            {question?.topic?.name && (
                                                <>
                                                    |
                                                    <span className="ms-2">
                                                        {question?.topic?.name}
                                                    </span>
                                                </>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            )}
                            {!!tags?.length && (
                                <Row className={"mb-2"}>
                                    <Col
                                        style={{ maxWidth: "max-content" }}
                                        className={"d-flex"}
                                    >
                                        {question?.tags?.map((tag) => {
                                            return (
                                                <div
                                                    key={tag._id}
                                                    className={`info-item f16 mb-2 me-2`}
                                                >
                                                    {tag.name}
                                                </div>
                                            );
                                        })}
                                    </Col>
                                </Row>
                            )}
                        </Col>
                    )}
                </Col>

                {showActions &&
                    (!(!question.isAuthor || question.linkedCount > 0) ||
                        isPrepPreviewList) && (
                        <Col className="questionlist-actions">
                            {!isPrepPreviewList && (
                                <LinkAction
                                    url={`/questions/${question._id}`}
                                    disable={
                                        !question.isAuthor ||
                                        question.linkedCount > 0
                                    }
                                    icon="edit"
                                />
                            )}
                            {handleDelete && (
                                <ClickAction
                                    icon={"delete"}
                                    confirmationTitle={t(
                                        "admin.users.list.delete.modal.confirmationTitle"
                                    )}
                                    confirmation={t(
                                        "admin.users.list.delete.modal.confirmation"
                                    )}
                                    onClick={() => handleDelete(question._id)}
                                    disable={
                                        isPrepPreviewList
                                            ? false
                                            : !question.isAuthor ||
                                              question.linkedCount > 0
                                    }
                                />
                            )}
                        </Col>
                    )}
            </Row>
        </div>
    );
};
