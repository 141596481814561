import ROUTES from "./routes.json";
import CONSTANTS from "./constants.json";
import { FileTypeInfo } from "../AppModule/models";
export * from "./helper";

const R = ROUTES;
const C = CONSTANTS;
const { Upload, Role } = CONSTANTS;
const { FILETYPE: FILE_TYPE, FILETYPEINFO: FILE_TYPE_INFO } = Upload;
const { FILETYPEINFO_USER_PROFILE } = FILE_TYPE_INFO;
const UserProfileFileInfo = FILETYPEINFO_USER_PROFILE as FileTypeInfo;
const { FILETYPE_USER_PROFILE } = FILE_TYPE;
const USER_PROFILE_TYPE = FILETYPE_USER_PROFILE;
const ROLES = Role.ROLE;

export { FILE_TYPE, FILE_TYPE_INFO, USER_PROFILE_TYPE, UserProfileFileInfo };

export { R, ROUTES, CONSTANTS, C, ROLES };
