import * as yup from "yup";

const validations = {
    firstName: {
        min: 2,
    },
    lastName: {
        min: 2,
    },
    plainPassword: {
        min: 6,
    },
};
const schema = (isEditMode: boolean) => {
    let validationShape = {
        Name: yup.string().required(),
        TotalQuestions: yup.string().required(),
        totalTime: yup.string().required(),
        correctMarks: yup.number().required(),
        skippedMarks: yup.number().required(),
        incorrectMarks: yup.number().required(),
        showSubjectWeightage: yup.boolean(),
    };
    if (!isEditMode) {
        validationShape = {
            ...validationShape,
        };
    }

    return yup.object().shape(validationShape);
};

const resetPasswordSchema = yup.object().shape({
    plainPassword: yup.string().required(),
    confirmPassword: yup
        .string()
        .oneOf(
            [yup.ref("plainPassword"), undefined],
            "Passwords must be match"
        ),
});

export { schema, validations, resetPasswordSchema };
