import * as yup from "yup";

const schema = () => {
    const validationShape = {
        Name: yup.string().required(),
        PrepConfig: yup.string().required(),
    };

    return yup.object().shape(validationShape);
};

export { schema };
