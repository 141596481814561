import React, { Fragment, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { find as _find } from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    AppPageHeader,
    AppBreadcrumb,
    AppFormActions,
    AppCard,
    AppFormInput,
    AppFormInputPassword,
    AppFormSelect,
    AppButton,
    // AppLoader,
} from "../../../AppModule/components";
import { User } from "../../models";
import { UserApi } from "../../apis";
import { errorToast, successToast, validation } from "../../../AppModule/utils";
import { PrimitiveObject } from "../../../AppModule/models";

import { useTranslation, useEntityAddEdit } from "../../../AppModule/hooks";
import { schema } from "./schema";
import { UserResetPasswordModal } from "./UserResetPasswordModal";
import { useNavigate } from "react-router-dom";
import { buildConfig } from "../../../AuthModule/contexts";

type UpdateProfileForm<T> = {
    [key: string]: T;
};

export const UserAddEdit = (): JSX.Element => {
    const { id, isEditMode, data, isLoading } = useEntityAddEdit<User>(
        new User("")
    );
    const navigate = useNavigate();
    const role = [
        { name: "super Admin" },
        { name: "Admin" },
        { name: "Teacher" },
    ];
    const [showResetPasswordModal, setShowResetPasswordModal] =
        useState<boolean>(false);
    const { t } = useTranslation();

    const { control, handleSubmit, formState } = useForm<User>({
        resolver: yupResolver<any>(schema(isEditMode)),
        mode: "all",
    });
    const getRolesOption = () => {
        const FilterRoute = role;

        return FilterRoute?.map((e: any) => {
            return {
                value: e.name,
                label: e.name,
            };
        });
    };
    const getUserRole = () => {
        if (isEditMode) {
            const userRole: any = {
                value: "id",
                label: "admin",
            };
            return userRole;
        }
        return {};
    };

    const submitForm = async (formData: UpdateProfileForm<any>) => {
        if (formData.relationManager === "") delete formData.relationManager;

        delete formData.userField;

        return UserApi.AddUser(
            formData,
            buildConfig(localStorage?.getItem("ep_token") || ({} as any))
        )
            .then((response: any) => {
                if (response.success) successToast(response?.message);
                else errorToast(response?.message);
                navigate("..");
            })
            .catch((e) => {
                errorToast(e);
            });
    };
    const onSubmit = async (formData: User) => {
        // eslint-disable-next-line no-console
        console.log("formData", formData);
        return submitForm(formData);
    };

    if (isLoading) {
        // return <AppLoader />;
    }

    const { errors } = formState;

    return (
        <Fragment>
            <AppBreadcrumb
                linkText={t("common.breadcrumb.users")}
                linkUrl={".."}
            />
            <AppPageHeader
                title={
                    isEditMode
                        ? t("admin.users.form.header.editTitle")
                        : t("admin.users.form.header.title")
                }
            />
            <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col md={12}>
                        <AppCard>
                            <Row className="m-0">
                                <Col className="p-0" md={12} lg={6}>
                                    <AppFormInput
                                        lg={12}
                                        xl={12}
                                        name={"firstName"}
                                        label={t(
                                            "admin.users.form.label.firstName"
                                        )}
                                        defaultValue={data.firstName}
                                        required={false}
                                        {...validation(
                                            "firstName",
                                            formState,
                                            isEditMode
                                        )}
                                        errorMessage={errors.firstName?.message}
                                        control={control as any}
                                    />
                                    <AppFormInput
                                        lg={12}
                                        xl={12}
                                        name={"lastName"}
                                        defaultValue={data.lastName}
                                        label={t(
                                            "admin.users.form.label.lastName"
                                        )}
                                        required={false}
                                        {...validation(
                                            "lastName",
                                            formState,
                                            isEditMode
                                        )}
                                        errorMessage={errors.lastName?.message}
                                        control={control as any}
                                    />
                                    <AppFormInput
                                        lg={12}
                                        xl={12}
                                        className=""
                                        defaultValue={data.email}
                                        name={"email"}
                                        label={t(
                                            "admin.users.form.label.email"
                                        )}
                                        required={true}
                                        {...validation(
                                            "email",
                                            formState,
                                            isEditMode
                                        )}
                                        errorMessage={errors.email?.message}
                                        control={control as any}
                                    />
                                    <AppFormSelect
                                        id={"role"}
                                        name={"role"}
                                        label={t(
                                            "admin.users.form.label.userRole"
                                        )}
                                        className="role-container "
                                        md={12}
                                        lg={12}
                                        xl={12}
                                        required={true}
                                        {...validation(
                                            "role",
                                            formState,
                                            isEditMode
                                        )}
                                        defaultValue={getUserRole()}
                                        placeholder={"Role"}
                                        errorMessage={errors.role?.message}
                                        options={getRolesOption()}
                                        control={control as any}
                                        transform={{
                                            output: (
                                                template: PrimitiveObject
                                            ) => template?.value,
                                            input: (value: string) => {
                                                return _find([], {
                                                    value,
                                                });
                                            },
                                        }}
                                    />
                                    {!isEditMode && [
                                        <AppFormInputPassword
                                            lg={12}
                                            className=" mb-0"
                                            xl={12}
                                            name={"plainPassword"}
                                            label={t(
                                                "admin.users.form.label.password"
                                            )}
                                            required={true}
                                            {...validation(
                                                "plainPassword",
                                                formState,
                                                isEditMode
                                            )}
                                            errorMessage={
                                                errors.plainPassword?.message
                                            }
                                            control={control as any}
                                        />,
                                        <AppFormInputPassword
                                            lg={12}
                                            xl={12}
                                            className=" mb-0"
                                            name={"confirmPassword"}
                                            label={t(
                                                "admin.users.form.label.confirmPassword"
                                            )}
                                            required={true}
                                            {...validation(
                                                "confirmPassword",
                                                formState,
                                                isEditMode
                                            )}
                                            errorMessage={
                                                errors.confirmPassword?.message
                                            }
                                            control={control as any}
                                        />,
                                    ]}

                                    {isEditMode && (
                                        <Col className="col-md-12">
                                            <AppButton
                                                onClick={() =>
                                                    setShowResetPasswordModal(
                                                        true
                                                    )
                                                }
                                                variant="primary"
                                            >
                                                {t(
                                                    "admin.users.form.label.setDefaultPassword"
                                                )}
                                            </AppButton>
                                        </Col>
                                    )}
                                </Col>

                                {/* <Form.Group className="mb-3 px-3 col-md-12 col-lg-6 user-profile-uploader">
                                    <Form.Label>
                                        {t(
                                            "admin.users.form.label.profilePicture"
                                        )}
                                    </Form.Label>
                                    <AppUploader
                                        withCropper={true}
                                        accept="image/*"
                                        fileInfo={
                                            FILETYPEINFO_USER_PROFILE as FileTypeInfo
                                        }
                                        imagePath={data.imageName}
                                        onFileSelect={onFileSelect}
                                        onDelete={() => {
                                            setValue("imageName", "");
                                        }}
                                    />
                                </Form.Group> */}

                                <Col md={12}>
                                    <hr />
                                </Col>
                            </Row>
                        </AppCard>
                    </Col>

                    <AppFormActions
                        isEditMode={isEditMode}
                        navigation={navigate}
                        isLoading={formState.isSubmitting}
                    />
                </Row>
            </Form>
            <UserResetPasswordModal
                userId={id}
                show={showResetPasswordModal}
                handleChangeStatus={(status) =>
                    setShowResetPasswordModal(status)
                }
            />
        </Fragment>
    );
};
