import React, { FC } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { useTranslation } from "../../hooks";
import { AppFormLabel } from "../../components/AppFormLabel";

import "./assets/scss/style.scss";

export interface AppCategorySwitcherType {
    activeCat: string;
    activeOnly?: boolean;
    onChange?: (cat: string) => void;
    languagesData?: [];
    errorMessage?: string;
}

export const AppCategorySwitcher: FC<AppCategorySwitcherType> = ({
    activeCat,
    onChange = () => {},
    errorMessage,
}): JSX.Element => {
    const { t } = useTranslation();

    const categories = ["Easy", "Medium", "Hard"];
    return (
        <Row className={"app-language-switcher"}>
            <Col xs={12}>
                <AppFormLabel
                    label={t("common.label.chooseDifficulty")}
                    required
                />
            </Col>
            <Col xs={12} className="d-flex mb-4 mt-2 flex-wrap">
                {categories?.map((e) => {
                    return (
                        <div
                            key={e}
                            className={`lang-item f16 me-2 mb-2 ${
                                activeCat === e && "active"
                            }`}
                            onClick={() => {
                                onChange(e);
                            }}
                        >
                            {e}
                        </div>
                    );
                })}
            </Col>
            <Form.Control.Feedback type="invalid">
                {errorMessage}
            </Form.Control.Feedback>
        </Row>
    );
};
