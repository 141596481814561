import React, { FC } from "react";
import { AppButton, AppButtonProps } from "../AppButton";
import { AppIcon } from "../AppIcon";
import "./assets/scss/style.scss";

export interface AppFilterButtonProps extends AppButtonProps {
    activeFilter?: number;
}
export const AppFilterButton: FC<AppFilterButtonProps> = ({
    activeFilter = 0,
    ...props
}) => {
    props.className = `${props.className ?? ""} btn-filter ${
        activeFilter > 0 ? "active" : ""
    }`;
    props.variant = "secondary";
    return (
        <AppButton {...props}>
            <AppIcon className="mr-2" name="Filter" />
            {props.children}
            {activeFilter > 0 ? (
                <span className="count ml-2">{activeFilter}</span>
            ) : null}
        </AppButton>
    );
};
