import React, { FC, useEffect, useRef } from "react";
import { Col, Form, Row, InputGroup } from "react-bootstrap";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, takeUntil } from "rxjs/operators";
import { Canceler } from "axios";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "../../hooks";
import { AppButton } from "../AppButton";
import { AppIcon } from "../AppIcon";
import { cancelAllPrevRequest } from "../../utils";
import "./assets/scss/style.scss";

export interface AppListPageToolbarProps {
    createLink?: string;
    grantedControl?: boolean;
    createLabel?: string;
    onQuickFilterChange?: (s: string) => void;
    cancelTokenSources?: Canceler[];
    showSearchBar?: boolean;
}

export const AppListPageToolbar: FC<AppListPageToolbarProps> = ({
    createLink,
    createLabel = "common.button.create",
    onQuickFilterChange = () => {},
    cancelTokenSources = [],
    grantedControl = true,
    showSearchBar = true,
}): JSX.Element => {
    const search$ = useRef(new Subject<string>());
    const destroy$ = new Subject<string>();
    const { t } = useTranslation();
    const navigate = useNavigate();
    useEffect(() => {
        search$.current
            .pipe(
                debounceTime(300),
                distinctUntilChanged(),
                takeUntil(destroy$)
            )
            .subscribe((search: any) => {
                cancelAllPrevRequest(cancelTokenSources);
                onQuickFilterChange(
                    search.trim()?.length > 3 ? search.trim() : ""
                );
            });
        return () => {
            destroy$.next();
            destroy$.complete();
        };
    });

    function handleQuickSearch(event: React.ChangeEvent<HTMLInputElement>) {
        search$.current.next(event.currentTarget?.value);
    }

    return (
        <Row className="app-page-header">
            <Col className={"d-flex justify-content-end"} xs="12" sm="auto">
                <Row className={"justify-content-end ms-2"}>
                    {showSearchBar ? (
                        <Col
                            xs={createLink && grantedControl ? 6 : 12}
                            sm="auto"
                            className="mb-2 header-column"
                        >
                            <InputGroup className="header-search">
                                <Form.Control
                                    onChange={handleQuickSearch}
                                    placeholder={t("common.button.search")}
                                    type={"search"}
                                ></Form.Control>
                                <InputGroup.Text id="basic-addon1">
                                    <AppIcon name="Search" />
                                </InputGroup.Text>
                            </InputGroup>
                        </Col>
                    ) : null}
                    {createLink && grantedControl && (
                        <Col
                            xs={showSearchBar ? 6 : 12}
                            sm="auto"
                            className="mb-2 create-column"
                        >
                            <AppButton
                                className={"text-capitalize w-100"}
                                variant={"secondary"}
                                onClick={() => {
                                    navigate(createLink);
                                }}
                            >
                                + {t(createLabel)}
                            </AppButton>
                        </Col>
                    )}
                </Row>
            </Col>
        </Row>
    );
};
