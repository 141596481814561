import {
    faChevronLeft,
    faUser,
    faChevronRight,
    faChevronDown,
    faChevronUp,
    faCog,
    faUserFriends,
    faBars,
    faTrash,
    faPen,
    faPlus,
    faFolderTree,
    faTimes,
    faSearch,
    faThLarge,
    faArrowLeft,
    faSignOut,
    faEye,
    faEyeSlash,
    faClone,
    faBox,
    faMicrophone,
    faEnvelope,
    faDownload,
    faFilter,
    faUpload,
    faCloudDownload,
    faCloudUpload,
    faCalendar,
    faVideo,
    faUserPlus,
    faCommentAltLines,
    faCalendarPlus,
    faCommentAlt,
    faShare,
    faDoorOpen,
    faDesktop,
    faGlobe,
    faProjector,
    faUnlink,
    faChartBar,
    faChartPie,
    faList,
    faExternalLink,
    faArrowToLeft,
    faArrowToRight,
    faInfo,
    faRedoAlt,
    faArrows,
} from "@fortawesome/pro-regular-svg-icons";
import { AppIconMap } from "../../models";

export const appIconMap: AppIconMap = {
    ChevronLeft: faChevronLeft,
    container: faBox,
    ChevronDown: faChevronDown,
    ChevronUp: faChevronUp,
    CommentAlt: faCommentAlt,
    ArrowLeft: faArrowLeft,
    back: faChevronLeft,
    next: faChevronRight,
    administrator: faCog,
    Settings: faCog,
    Users: faUserFriends,
    FaShare: faShare,
    dashboard: faThLarge,
    CloudDownload: faCloudDownload,
    CloudUpload: faCloudUpload,
    delete: faTrash,
    edit: faPen,
    add: faPlus,
    ListTree: faFolderTree,
    X: faTimes,
    Search: faSearch,
    Menu: faBars,
    List: faBars,
    Grid: faThLarge,
    User: faUser,
    Eye: faEye,
    EyeOff: faEyeSlash,
    SignOut: faSignOut,
    faClone,
    Clone: faClone,
    Microphone: faMicrophone,
    Email: faEnvelope,
    Download: faDownload,
    Filter: faFilter,
    Upload: faUpload,
    calendar: faCalendar,
    faCalendar,
    Video: faVideo,
    AddUserPlus: faUserPlus,
    Conversation: faCommentAltLines,
    CalendarPlus: faCalendarPlus,
    DoorOpen: faDoorOpen,
    Desktop: faDesktop,
    Globe: faGlobe,
    Projector: faProjector,
    faUnlink,
    faChartBar,
    Chart: faChartBar,
    ChartPie: faChartPie,
    faList,
    List2: faList,
    Info: faInfo,
    faExternalLink,
    faArrowToLeft,
    faArrowToRight,
    faRedoAlt,
    faArrows,
};
