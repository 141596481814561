import React, { Fragment, useState, useRef, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { isString as _isString } from "lodash";
import { GridApi } from "ag-grid-community";
import { Canceler } from "axios";
import { appGridColDef } from "./app-grid-col-def";
import { appGridFrameworkComponents } from "./app-grid-framework-components";
import { UserApi } from "../../apis";
import { User } from "../../models";
import {
    AppPageHeader,
    AppListPageToolbar,
    AppFilterButton,
    AppLoader,
} from "../../../AppModule/components";
import { AppGrid } from "../../../AppModule/containers/AppGrid";
import { errorToast, successToast, showLoader } from "../../../AppModule/utils";
import "./assets/scss/list.scss";
import { UnprocessableEntityErrorResponse } from "../../../AppModule/models";
import { useTranslation } from "../../../AppModule/hooks";
import { buildConfig } from "../../../AuthModule/contexts";
import {
    AppContext,
    ContainerTypes,
} from "../../../AppModule/contexts/AppContext";

type UpdateRoleForm = {
    role: string;
};

function createCore() {
    let stateContainer: number[] = [];
    let userList: User[] = [];

    const handleState = (newState: number, setter: any) => {
        const state = stateContainer;
        const index = state.indexOf(newState);
        if (index !== -1) {
            stateContainer = [
                ...state.slice(0, index),
                ...state.slice(index + 1),
            ];
        } else {
            stateContainer = [...state, newState];
        }
        setter(stateContainer);
    };
    const getUserList = () => {
        return userList;
    };

    const clearList = (setter: any) => {
        stateContainer = [];
        setter(stateContainer);
    };
    const handleUserList = (users: User[]) => {
        userList = users;
    };

    return {
        getState: () => stateContainer,
        handleState,
        getUserList,
        handleUserList,
        clearList,
    };
}
const core = createCore();

export const UserList = (): JSX.Element => {
    const [totalItems, setTotalItems] = useState<number>(0);
    const appGridApi = useRef<GridApi>();
    const cancelTokenSourcesRef = useRef<Canceler[]>([]);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const { t } = useTranslation();
    const { state, dispatch } = React.useContext(AppContext);
    const { isRefreshLoading } = state;

    useEffect(() => {
        dispatch({
            type: ContainerTypes.SHOW_APP_NAV,
        });
    }, []);

    const getDataSource = async () => {
        if (!state.isRefreshLoading) {
            dispatch({
                type: ContainerTypes.LOADING,
            });
        }
        let data = [];
        await UserApi.UserList<User>(
            buildConfig(localStorage?.getItem("ep_token") || ({} as any))
        )
            .then(({ error, response }) => {
                if (error !== null) {
                    if (_isString(error)) {
                        errorToast(error);
                    }
                } else if (response !== null) {
                    core.handleUserList(response?.items);
                    setTotalItems(response?.totalItems);
                    data = response as any;
                }
            })
            .finally(() => {
                dispatch({
                    type: ContainerTypes.REFRESH_LOADED,
                });
                dispatch({
                    type: ContainerTypes.LOADED,
                });
            });
        return data;
    };

    async function uploadFile(e: any) {
        const formData = new FormData();
        formData.append("file", e.target.files?.[0]);
        showLoader(t("admin.users.list:importingusers"));
    }
    async function handleDelete(id: number) {
        UserApi.deleteById(id).then(({ error }) => {
            if (error !== null) {
                if (_isString(error)) {
                    errorToast(error);
                }
            } else {
                successToast(t("admin.users.list:toast.deleted.success"));
                appGridApi.current?.refreshServerSideStore({
                    purge: false,
                    route: [],
                });
            }
        });
    }

    async function handleRoleChange(id: number, newRole: string) {
        UserApi.createOrUpdate<UpdateRoleForm>(id, { role: newRole }).then(
            ({ error, errorMessage }) => {
                if (error instanceof UnprocessableEntityErrorResponse) {
                    errorToast(errorMessage);
                } else if (errorMessage) {
                    errorToast(errorMessage);
                } else {
                    successToast(t("admin.users.list:toast.update.success"));
                }
            }
        );
    }

    async function handleFilter(search: string) {
        appGridApi.current?.setFilterModel({
            user_search: {
                filter: search,
            },
        });
    }

    return (
        <Fragment>
            {isRefreshLoading ? <AppLoader /> : null}
            <AppPageHeader title={"Users"} customToolbar>
                <div className="d-block mb-3 mb-md-5">
                    <div className="d-block d-sm-flex pt-2 justify-content-end user-header-width">
                        <AppFilterButton
                            className="user-filter mr-2"
                            onClick={() => {
                                // setShowFilter(true);
                            }}
                        >
                            {t("common.button:filter")}
                        </AppFilterButton>
                        <AppListPageToolbar
                            createLink={"/admin/users/new"}
                            createLabel="common.button.create"
                            onQuickFilterChange={handleFilter}
                            cancelTokenSources={cancelTokenSourcesRef.current}
                        />
                    </div>
                    {/* <div className="d-flex impexp-options justify-content-end">
                        {isGranted(role, ROLE_ADMIN) && (
                        <>
                            <AppButton
                                onClick={() => {
                                    handleExport();
                                }}
                                className="mb-2 ms-2 p-3 user-ex"
                                variant="secondary"
                            >
                                <AppIcon className="mr-2" name="Upload" />
                                {t("common.button:export")}
                            </AppButton>
                        </>
                         )} 

                        {!isGranted(role, ROLE_SUPER_ADMIN) &&
                            isGranted(role, ROLE_ADMIN) && (
                        <>
                            <AppButton
                                onClick={() => {
                                    handleImport();
                                }}
                                className="p-3 user-imp mb-2 ms-2"
                                variant="secondary"
                                hidden={true}
                            >
                                <AppIcon className="mr-2" name="Download" />
                                {t("common.button:import")}
                            </AppButton>
                        </>
                        )}
                    </div> */}
                </div>
            </AppPageHeader>

            <input
                ref={fileInputRef}
                onChange={uploadFile}
                id="select-file"
                type="file"
                accept=".csv"
                hidden={true}
            />
            <Row>
                <Col>
                    <AppGrid
                        frameworkComponents={appGridFrameworkComponents}
                        columnDef={appGridColDef({
                            onPressDelete: handleDelete,
                            onSelectChange: handleRoleChange,
                        })}
                        getDataSource={getDataSource}
                        totalItems={totalItems}
                        onReady={(event) => {
                            appGridApi.current = event.api;
                        }}
                    />
                </Col>
            </Row>
        </Fragment>
    );
};
