import React, { CSSProperties, FC } from "react";
import { Variant } from "react-bootstrap/types";
import "./assets/scss/style.scss";

export interface AppLoaderProps {
    containerClassName?: string;
    containerStyle?: CSSProperties;
    spinnerClassName?: string;
    spinnerContainerStyle?: CSSProperties;
    spinnerAnimation?: "border" | "grow";
    spinnerSize?: "sm";
    spinnerVariant?: Variant;
    coverContainer?: boolean;
}

export const AppLoader: FC<AppLoaderProps> = ({
    containerClassName = "",
    containerStyle,
    coverContainer = false,
}) => (
    <div
        className={`loader ${
            coverContainer
                ? `loaderContainer ${containerClassName}`
                : "loaderWholePage"
        }`}
        style={containerStyle}
    >
        <div className="default-spinner">
            <div className="logo"></div>

            <div className="dot-pulse"></div>
        </div>
    </div>
);
