import React, { FC, useState } from "react";
import { useTranslation } from "../../hooks";
import { AppIcon } from "../AppIcon";
import { AppModal } from "../AppModal";
import { Link } from "react-router-dom";
export interface AppGridCustomLinkAction extends AppGridCustomAction {
    url: string;
}
export interface AppGridActionProps {
    editAction?: AppGridLinkAction;
    deleteAction?: AppGridClickAction;
    customLinkActions?: AppGridCustomLinkAction[];
    customClickActions?: AppGridCustomClickAction[];
}
export interface AppGridCustomClickAction extends AppGridCustomAction {
    onClick: () => void;
    confirmation?: string;
    confirmationTitle?: string;
}
interface AppGridLinkAction {
    url: string;
    icon?: string;
    text?: string;
    disable?: boolean;
}

interface AppGridClickAction {
    onClick: () => void;
    disable?: boolean;
    confirmation?: string;
    confirmationTitle?: string;
}

interface AppGridCustomAction {
    icon?: string;
    text?: string;
    disable?: boolean;
    className?: string;
    iconHtml?: JSX.Element | string;
}

interface LinkActionProps extends AppGridCustomAction {
    url: string;
}

export const LinkAction: FC<LinkActionProps> = ({
    url,
    icon,
    text,
    disable = false,
    className,
    iconHtml = null,
}): JSX.Element => {
    const { t } = useTranslation();
    if (!text && !icon) {
        return <></>;
    }

    const renderTextOrIcon = () => {
        return (
            iconHtml || (
                <>
                    {icon ? <AppIcon name={icon} /> : null}
                    {text ? t(text) : null}
                </>
            )
        );
    };

    if (disable) {
        return (
            <a
                href="#"
                className={"disabled text-muted"}
                onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                }}
            >
                {renderTextOrIcon()}
            </a>
        );
    }

    return (
        <Link className={className} to={url}>
            {renderTextOrIcon()}
        </Link>
    );
};

interface ClickActionProps extends AppGridCustomAction {
    onClick?: () => void;
    confirmation?: string;
    text?: string;
    confirmationTitle?: string;
    className?: string;
}

export const ClickAction: FC<ClickActionProps> = ({
    icon,
    disable = false,
    onClick,
    confirmationTitle = "Delete Action",
    confirmation,
    text,
    className = "",
    iconHtml = null,
}): JSX.Element => {
    const [show, setShow] = useState(false);
    if (!onClick) {
        return <></>;
    }
    const handlePositive = () => {
        setShow(false);
        onClick();
    };
    const handleNegative = () => {
        setShow(false);
    };

    return (
        <>
            {/* TODO: move it to grid or else, otherwise repeat multiple times on rows, on iterations */}
            {confirmation ? (
                <AppModal
                    show={show}
                    title={confirmationTitle}
                    handleClose={handleNegative}
                    handleDelete={handlePositive}
                    bodyContent={confirmation}
                />
            ) : null}
            <a
                className={
                    disable
                        ? `${className} disabled text-muted cursor-pointer`
                        : `${className} cursor-pointer`
                }
                onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    if (!disable) {
                        if (confirmation) {
                            setShow(true);
                        } else {
                            onClick();
                        }
                    }
                }}
            >
                {iconHtml || (icon ? <AppIcon name={icon} /> : null)}
                {text}
            </a>
        </>
    );
};

export const AppGridAction: FC<AppGridActionProps> = ({
    editAction,
    deleteAction,
    customLinkActions,
    customClickActions = [],
}): JSX.Element => {
    return (
        <div className="actions">
            {customClickActions?.map(({ icon, text, ...rest }, index) => (
                <ClickAction key={index} icon={icon} text={text} {...rest} />
            ))}
            {editAction ? <LinkAction icon={"edit"} {...editAction} /> : null}
            {deleteAction ? (
                <ClickAction icon={"delete"} {...deleteAction} />
            ) : null}
            {customLinkActions?.map(({ icon, text, ...rest }, index) => (
                <LinkAction key={index} icon={icon} text={text} {...rest} />
            ))}
        </div>
    );
};
