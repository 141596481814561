import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    AppPageHeader,
    AppBreadcrumb,
    AppFormActions,
    AppCard,
    AppFormInput,
    AppLoader,
    AppFormSwitch,
    AppFormRange,
    // AppLoader,
} from "../../components";
import { PrepConfig } from "../../models";
import { errorToast, successToast, validation } from "../../../AppModule/utils";

import { useTranslation } from "../../../AppModule/hooks";
import { schema } from "./schema";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext, ContainerTypes } from "../../contexts/AppContext";
import { PrepConfigApi } from "../../apis/PrepConfigApi";
import { buildConfig } from "../../../AuthModule/contexts";

export const PerpConfigAddEdit = (): JSX.Element => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { state, dispatch } = React.useContext(AppContext);
    const { isLoading, isRefreshLoading } = state;
    const [data, setData] = useState<PrepConfig>();
    const [category, setCategory] = useState(
        data?.showSubjectWeightage || false
    );
    const navigate = useNavigate();

    const { control, handleSubmit, formState } = useForm<any>({
        resolver: yupResolver<any>(schema(false)),
        mode: "all",
    });
    useEffect(() => {
        const GetData = async () => {
            if (!isRefreshLoading) {
                dispatch({
                    type: ContainerTypes.LOADING,
                });
            }
            await PrepConfigApi.ConfigList(
                buildConfig(localStorage?.getItem("ep_token") || ({} as any))
            ).then((response: any) => {
                const res = response.response;
                if (res !== null) {
                    const FiltredData = res?.items?.filter(
                        (q) => q._id === id
                    )?.[0];
                    setData(FiltredData);
                    setCategory(FiltredData.showSubjectWeightage);

                    dispatch({
                        type: ContainerTypes.LOADED,
                    });
                    dispatch({
                        type: ContainerTypes.REFRESH_LOADED,
                    });
                }
            });
        };
        if (id) {
            GetData();
        } else {
            dispatch({
                type: ContainerTypes.LOADED,
            });
            dispatch({
                type: ContainerTypes.REFRESH_LOADED,
            });
        }
    }, [id]);

    const onSubmit = async (formData: any) => {
        if (id) {
            await PrepConfigApi.UpdateConfig(
                {
                    ...formData,
                    showSubjectWeightage: category,
                    name: formData.Name,
                    totalQuestions: formData.TotalQuestions,
                },
                id || "",
                buildConfig(localStorage?.getItem("ep_token") || ({} as any))
            )
                .then((response) => {
                    if (response.success) successToast(response?.message);
                    else errorToast(response?.message);
                    navigate("..");
                })
                .catch((e) => {
                    errorToast(e?.message);
                });
        } else {
            await PrepConfigApi.AddConfig(
                {
                    ...formData,
                    showSubjectWeightage: category,
                    name: formData.Name,
                    totalQuestions: formData.TotalQuestions,
                },
                buildConfig(localStorage?.getItem("ep_token") || ({} as any))
            )
                .then((response) => {
                    if (response.success) successToast(response?.message);
                    navigate("..");
                })
                .catch((e) => {
                    errorToast(e?.message);
                });
        }
    };

    const { errors } = formState;

    return (
        <Fragment>
            {isLoading ? (
                <AppLoader coverContainer={true} />
            ) : isRefreshLoading ? (
                <AppLoader />
            ) : null}
            <AppBreadcrumb
                linkText={t("common.breadcrumb.prepConfig")}
                linkUrl={".."}
            />
            <AppPageHeader
                title={
                    id
                        ? t("admin.prepConfig.form.header.editTitle")
                        : t("admin.prepConfig.form.header.title")
                }
            />
            {!isLoading && !isRefreshLoading && (
                <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col md={12}>
                            <AppCard>
                                <Row className="m-0">
                                    <Col className="p-0" md={12} lg={12}>
                                        <Row className="m-0">
                                            <AppFormInput
                                                lg={6}
                                                xl={6}
                                                name={"Name"}
                                                label={t(
                                                    "admin.prepConfig.form.label.name"
                                                )}
                                                defaultValue={data?.name}
                                                required={true}
                                                {...validation(
                                                    "Name",
                                                    formState,
                                                    false
                                                )}
                                                errorMessage={
                                                    errors.Name?.message
                                                }
                                                control={control as any}
                                            />
                                            <AppFormSwitch
                                                label={t(
                                                    "admin.prepConfig.form.label.showSubjectWeightage"
                                                )}
                                                md={12}
                                                sm={12}
                                                lg={6}
                                                xl={6}
                                                name={"showSubjectWeightage"}
                                                defaultChecked={
                                                    category ? category : false
                                                }
                                                control={control}
                                                onChange={(e) =>
                                                    setCategory(
                                                        e.currentTarget.checked
                                                    )
                                                }
                                            />
                                        </Row>
                                        <Row className="m-0">
                                            <AppFormInput
                                                lg={6}
                                                xl={6}
                                                name={"TotalQuestions"}
                                                defaultValue={
                                                    data?.totalQuestions
                                                }
                                                label={t(
                                                    "admin.prepConfig.form.label.totalQuestions"
                                                )}
                                                type="number"
                                                required={true}
                                                {...validation(
                                                    "TotalQuestions",
                                                    formState,
                                                    false
                                                )}
                                                errorMessage={
                                                    errors.TotalQuestions
                                                        ?.message
                                                }
                                                control={control as any}
                                            />
                                            <AppFormInput
                                                lg={6}
                                                xl={6}
                                                className=""
                                                defaultValue={
                                                    data?.totalTime || 0
                                                }
                                                name={"totalTime"}
                                                label={t(
                                                    "admin.prepConfig.form.label.totalTime"
                                                )}
                                                type={"number"}
                                                required={true}
                                                {...validation(
                                                    "totalTime",
                                                    formState,
                                                    false
                                                )}
                                                errorMessage={
                                                    errors.totalTime?.message
                                                }
                                                control={control as any}
                                            />
                                        </Row>
                                        <Row className="m-0">
                                            <AppFormRange
                                                lg={4}
                                                xl={4}
                                                name={"correctMarks"}
                                                defaultValue={
                                                    data?.correctMarks || 1
                                                }
                                                className="rangeInput"
                                                label={t(
                                                    "admin.prepConfig.form.label.correctMarks"
                                                )}
                                                required={true}
                                                {...validation(
                                                    "correctMarks",
                                                    formState,
                                                    false
                                                )}
                                                disableMinusOn={true}
                                                errorMessage={
                                                    errors.correctMarks?.message
                                                }
                                                control={control as any}
                                            />
                                            <AppFormRange
                                                lg={4}
                                                xl={4}
                                                className="rangeInput"
                                                name={"skippedMarks"}
                                                label={t(
                                                    "admin.prepConfig.form.label.skippedMarks"
                                                )}
                                                defaultValue={
                                                    data?.skippedMarks || 0
                                                }
                                                disablePlusOn={true}
                                                required={true}
                                                {...validation(
                                                    "skippedMarks",
                                                    formState,
                                                    false
                                                )}
                                                errorMessage={
                                                    errors.skippedMarks?.message
                                                }
                                                control={control as any}
                                            />
                                            <AppFormRange
                                                lg={4}
                                                xl={4}
                                                className="rangeInput"
                                                defaultValue={
                                                    data?.incorrectMarks || 0
                                                }
                                                name={"incorrectMarks"}
                                                label={t(
                                                    "admin.prepConfig.form.label.incorrectMarks"
                                                )}
                                                required={true}
                                                {...validation(
                                                    "incorrectMarks",
                                                    formState,
                                                    false
                                                )}
                                                disablePlusOn={true}
                                                errorMessage={
                                                    errors.incorrectMarks
                                                        ?.message
                                                }
                                                control={control as any}
                                            />
                                        </Row>
                                    </Col>
                                </Row>
                            </AppCard>
                        </Col>

                        <AppFormActions
                            isEditMode={false}
                            navigation={navigate}
                            isLoading={formState.isSubmitting}
                        />
                    </Row>
                </Form>
            )}
        </Fragment>
    );
};
