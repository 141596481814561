import React from "react";
import { SimpleObject } from "../models";

interface GlobalState {
    status: "LOADED" | "LOADING" | "ERROR";
}
const Global = React.createContext<GlobalState | null>(null);

export const useGlobalData = (): GlobalState => {
    const context = React.useContext(Global);
    if (context === null) {
        throw new Error("useGlobalData must be used within a GlobalProvider");
    }

    return context;
};

export const GlobalProvider: React.FC<any> = (props: SimpleObject<any>) => {
    const [state, setState] = React.useState<GlobalState>({
        status: "LOADING",
    });

    React.useEffect(() => {
        setState({
            status: "LOADING",
        });
        (async (): Promise<void> => {})();
    }, [setState]);

    return <Global.Provider value={state}>{props.children}</Global.Provider>;
};
