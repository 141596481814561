import React, { useEffect } from "react";
import { Fragment } from "react/jsx-runtime";
import { useRef, useState } from "react";
import { errorToast, showLoader, successToast } from "../../utils";
import {
    AppFilterButton,
    AppListPageToolbar,
    AppLoader,
    AppPageHeader,
} from "../../components";

import "./assets/scss/style.scss";
import { useTranslation } from "react-i18next";
import { Canceler } from "axios";
import { buildConfig } from "../../../AuthModule/contexts";
import { QuestionApi } from "../../apis/QuestionAPI";
import { AppContext, ContainerTypes } from "../../contexts/AppContext";
import { AppQuestionListItem } from "../../containers/AppQuestionListItem/AppQuestionListItem";
import { AppliedFilter } from "../../containers/AppGrid/AppGrid";
import { FilterDrawer } from "../../components/AppFilter/FilterDrawer";
import { ReactComponent as Logo } from "./assets/noData.svg";
import { AppInfiniteScroll } from "../../containers/AppInfiniteScroll";
import { AppScrollButton } from "../../components/AppScrollButton";
export const QuestionList = () => {
    const [FilterItems, setFilterItems] = useState<number>(0);
    const [questionData, setQuestionData] = useState<Array<any>>([]);
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const { t } = useTranslation();
    const cancelTokenSourcesRef = useRef<Canceler[]>([]);
    const { state, dispatch } = React.useContext(AppContext);
    const [searchVal, setSearch] = useState("");
    const [isDataDeleted, setIsDataDeleted] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [hasMoreLoading, setHasMoreLoading] = useState(true);
    const [selectedFilter, setSelectedFilter] = useState<AppliedFilter>({
        categoryFilter: [],
        typeFilter: [],
        tagFilter: [],
        topic: [],
        examFilter: [],
        subjectFilter: [],
    });
    const [page, setPage] = useState(1);

    const { isLoading, isRefreshLoading } = state;
    useEffect(() => {
        dispatch({
            type: ContainerTypes.SHOW_APP_NAV,
        });
    }, []);

    async function uploadFile(e: any) {
        const formData = new FormData();
        formData.append("file", e.target.files?.[0]);
        showLoader(t("admin.users.list:importingusers"));
    }

    const getDataSource = async (
        appliedFilter,
        search = "",
        active = 1,
        clearData = false,
        pageSize = 10
    ) => {
        setHasMoreLoading(true);
        if (clearData) {
            dispatch({
                type: ContainerTypes.LOADING,
            });
        }
        await QuestionApi.QuestionList(
            buildConfig(localStorage?.getItem("ep_token") || ({} as any)),
            { page: active, limit: pageSize, ...appliedFilter, search }
        ).then((response: any) => {
            const res = response.response;
            if (res?.items?.length) {
                if (clearData) {
                    setQuestionData([...res?.items]);
                    if (res?.totalItems > res?.items?.length) setHasMore(true);
                    else setHasMore(false);
                } else {
                    setQuestionData([...questionData, ...res?.items]);
                    if (
                        res?.totalItems >
                        [...questionData, ...res?.items]?.length
                    ) {
                        setHasMore(true);
                    } else {
                        setHasMore(false);
                    }
                }
            } else if (page === 1) {
                setQuestionData([]);
            }

            if (!res?.items?.length) {
                setHasMore(false);
            }

            setHasMoreLoading(false);
            dispatch({
                type: ContainerTypes.REFRESH_LOADED,
            });
            dispatch({
                type: ContainerTypes.LOADED,
            });
        });
        if (isDataDeleted) {
            setIsDataDeleted(false);
        }
    };

    useEffect(() => {
        if (hasMore && page !== 1) {
            getDataSource(selectedFilter, searchVal, page);
        }
    }, [page]);

    useEffect(() => {
        setFilterItems(
            selectedFilter.categoryFilter?.length +
                selectedFilter.tagFilter?.length +
                selectedFilter.typeFilter?.length +
                selectedFilter.topic?.length +
                selectedFilter.subjectFilter?.length +
                selectedFilter.examFilter?.length
        );
        setPage(1);
        getDataSource(selectedFilter, searchVal, 1, true);
    }, [selectedFilter]);

    async function handleDelete(id: any) {
        return QuestionApi.deleteQuestion(
            id,
            buildConfig(localStorage?.getItem("ep_token") || ({} as any))
        ).then((response) => {
            if (response.success) {
                successToast(response?.message);
                getDataSource(selectedFilter, searchVal, 1, true);
                setPage(1);
            } else errorToast(response.message);
        });
    }
    const renderData = () => {
        if (questionData?.length) {
            return questionData?.map((question) => (
                <AppQuestionListItem
                    key={question._id}
                    question={question}
                    handleDelete={handleDelete}
                />
            ));
        } else if (!hasMoreLoading) {
            return (
                <div className="no-data-wrapper">
                    <Logo height={100} width={100} />
                    <div className="no-data">No Data Found</div>
                </div>
            );
        }
    };
    return (
        <Fragment>
            {isRefreshLoading ? <AppLoader /> : null}
            <input
                ref={fileInputRef}
                onChange={uploadFile}
                id="select-file"
                type="file"
                accept=".csv"
                hidden={true}
            />
            <AppPageHeader title={"Questions"} customToolbar>
                <div className="d-block mb-3 mb-md-5">
                    <div className="d-block d-sm-flex pt-2 justify-content-end user-header-width">
                        <AppFilterButton
                            className={`user-filter mr-2 ${FilterItems > 0 ? "active" : ""}`}
                            onClick={() => {
                                setShowFilter(true);
                            }}
                        >
                            <span>
                                {t("common.button.filter")}
                                {FilterItems > 0 && ` (${FilterItems})`}
                            </span>
                        </AppFilterButton>
                        <AppListPageToolbar
                            createLink={"/questions/new"}
                            createLabel="common.button.create"
                            onQuickFilterChange={(searchStr) => {
                                setSearch(searchStr);
                                setPage(1);
                                getDataSource(
                                    selectedFilter,
                                    searchStr,
                                    1,
                                    true
                                );
                            }}
                            cancelTokenSources={cancelTokenSourcesRef.current}
                        />
                    </div>
                </div>
            </AppPageHeader>
            {isLoading ? (
                <AppLoader
                    coverContainer={true}
                    containerClassName="filter-modal-loader"
                />
            ) : (
                <AppInfiniteScroll
                    renderData={renderData}
                    hasMore={hasMore}
                    page={page}
                    setPage={setPage}
                    hasMoreLoading={hasMoreLoading}
                    dataLength={questionData?.length}
                />
            )}

            {showFilter && setShowFilter && (
                <FilterDrawer
                    appliedFilter={selectedFilter}
                    setAppliedFilter={setSelectedFilter}
                    showFilter={showFilter}
                    setShowFilter={setShowFilter}
                    handleFilter={() => {}}
                />
            )}
            <AppScrollButton />
        </Fragment>
    );
};
