import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { Form, Row } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    AppFormInputPassword,
    AppFormModal,
} from "../../../AppModule/components";
import { useTranslation } from "../../../AppModule/hooks";
import { validation } from "../../../AppModule/utils";
import { resetPasswordSchema } from "./schema";

interface UserSetDefaultPasswordForm {
    plainPassword: string;
    confirmPassword?: string;
}
interface UserResetPasswordModalProps {
    show: boolean;
    handleChangeStatus: (status: boolean) => void;
    userId: string | undefined;
}

export const UserResetPasswordModal: FC<UserResetPasswordModalProps> = ({
    show,
    handleChangeStatus,
}) => {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState<boolean>(false);

    const { control, handleSubmit, formState } =
        useForm<UserSetDefaultPasswordForm>({
            resolver: yupResolver(resetPasswordSchema),
            mode: "all",
        });
    const { errors } = formState;

    const onSubmit = async () => {
        setLoading(true);
        setLoading(false);
    };
    return (
        <AppFormModal
            iconTitle={"far fa-lock-alt"}
            title={t("admin.users.list.setDefaultPassword.modal.title")}
            handleClose={() => {
                handleChangeStatus(false);
            }}
            isLoading={loading}
            handleSubmit={handleSubmit(onSubmit)}
            show={show}
        >
            <Form>
                <Form.Group className="mb-0">
                    <Row>
                        <AppFormInputPassword
                            className="mb-1"
                            md={12}
                            lg={12}
                            xl={12}
                            name={"plainPassword"}
                            label={t("admin.users.form.label.password")}
                            required={true}
                            placeholder={t(
                                "admin.users.form.label.passwordPlaceholder"
                            )}
                            {...validation("plainPassword", formState, false)}
                            errorMessage={errors.plainPassword?.message}
                            control={control}
                        />
                    </Row>
                    <Row>
                        <AppFormInputPassword
                            className="mb-1"
                            md={12}
                            lg={12}
                            xl={12}
                            name={"confirmPassword"}
                            label={t("admin.users.form.label.confirmPassword")}
                            required={true}
                            placeholder={t(
                                "admin.users.form.label.confirmPasswordPlaceHolder"
                            )}
                            {...validation("confirmPassword", formState, false)}
                            errorMessage={errors.confirmPassword?.message}
                            control={control}
                        />
                    </Row>
                </Form.Group>
            </Form>
        </AppFormModal>
    );
};
