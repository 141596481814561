import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Fragment } from "react/jsx-runtime";
import { useRef, useState } from "react";
import {
    AppFilterButton,
    AppListPageToolbar,
    AppLoader,
} from "../../components";
import { useTranslation } from "react-i18next";
import { Canceler } from "axios";
import { buildConfig } from "../../../AuthModule/contexts";
import { QuestionApi } from "../../apis/QuestionAPI";
import { AppContext, ContainerTypes } from "../../contexts/AppContext";
import { PrimitiveObject } from "../../models";
import { AppQuestionListItem } from "../../containers/AppQuestionListItem/AppQuestionListItem";
import { errorToast } from "../../utils";
import { FilterDrawer } from "../../components/AppFilter/FilterDrawer";
import { AppliedFilter } from "../../containers/AppGrid/AppGrid";
import { AppInfiniteScroll } from "../../containers";
import { ReactComponent as Logo } from "../QuestionList/assets/noData.svg";
import { AppScrollButton } from "../../components/AppScrollButton";
interface PrepQuestionListProps {
    setSubjectList: (subjectList) => void;
    setDifficulty: (difficulty) => void;
    subjectList: Array<PrimitiveObject>;
    setSelectedQuestion: (selectedQuestion) => void;
    selectedQuestion: Array<any>;
    selectedQuestionList: Array<string>;
    setSelectedQuestionList: (selectedQuestionList) => void;
    configTotalQuestion: number;
    selectedFilter: AppliedFilter;
    setSelectedFilter: React.Dispatch<React.SetStateAction<AppliedFilter>>;
}

export const PrepQuestionList = ({
    selectedQuestionList,
    setSelectedQuestionList,
    selectedQuestion,
    setSelectedQuestion,
    setDifficulty,
    setSubjectList,
    subjectList,
    configTotalQuestion,
    selectedFilter,
    setSelectedFilter,
}: PrepQuestionListProps) => {
    const [FilterItems, setFilterItems] = useState<number>(-1);
    const [totalQuestions, setTotalQuestions] = useState<Array<any>>([]);

    const [showFilter, setShowFilter] = useState<boolean>(false);
    const { t } = useTranslation();
    const cancelTokenSourcesRef = useRef<Canceler[]>([]);
    const { state, dispatch } = React.useContext(AppContext);
    const [isDataDeleted, setIsDataDeleted] = useState(false);
    const [searchVal, setSearchVal] = useState("");
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);
    const [hasMoreLoading, setHasMoreLoading] = useState(true);

    const { isRefreshLoading } = state;
    useEffect(() => {
        dispatch({
            type: ContainerTypes.SHOW_APP_NAV,
        });
    }, []);
    const chartColor = [
        "#FF5733",
        "#33FF57",
        "#3357FF",
        "#FF33A1",
        "#A133FF",
        "#FF8C33",
        "#33FF8C",
        "#8C33FF",
        "#FF5733",
        "#33FF57",
        "#FF33A1",
        "#A133FF",
        "#33FF8C",
        "#FF8C33",
        "#FF5733",
        "#33FF57",
        "#3357FF",
        "#FF33A1",
        "#A133FF",
        "#FF8C33",
        "#33FF8C",
        "#8C33FF",
        "#FF5733",
        "#33FF57",
        "#FF33A1",
        "#A133FF",
        "#33FF8C",
        "#FF8C33",
        "#FF5733",
        "#33FF57",
        "#3357FF",
        "#FF33A1",
        "#A133FF",
        "#FF8C33",
        "#33FF8C",
        "#8C33FF",
        "#FF5733",
        "#33FF57",
        "#FF33A1",
        "#A133FF",
        "#33FF8C",
        "#FF8C33",
        "#FF5733",
        "#33FF57",
        "#3357FF",
        "#FF33A1",
        "#A133FF",
        "#FF8C33",
        "#33FF8C",
        "#8C33FF",
    ];

    const getDataSource = async (
        appliedFilter,
        search = "",
        active = 1,
        clearData = false,
        pageSize = 10
    ) => {
        setHasMoreLoading(true);
        await QuestionApi.QuestionList(
            buildConfig(localStorage?.getItem("ep_token") || ({} as any)),
            { page: active, limit: pageSize, ...appliedFilter, search }
        ).then((response: any) => {
            const res = response.response;
            if (res?.items?.length) {
                if (clearData) {
                    setTotalQuestions([...res?.items]);
                    if (res?.totalItems > res?.items?.length) setHasMore(true);
                } else {
                    setTotalQuestions([...totalQuestions, ...res?.items]);
                    if (
                        res?.totalItems >
                        [...totalQuestions, ...res?.items]?.length
                    )
                        setHasMore(true);
                }
            } else if (active === 1) {
                setTotalQuestions([]);
            }

            if (!res?.items?.length) {
                setHasMore(false);
            }

            setHasMoreLoading(false);
            dispatch({
                type: ContainerTypes.REFRESH_LOADED,
            });
        });
        if (isDataDeleted) {
            setIsDataDeleted(false);
        }
    };

    useEffect(() => {
        if (hasMore && page !== 1) {
            getDataSource(selectedFilter, searchVal, page);
        }
    }, [page]);

    useEffect(() => {
        setFilterItems(
            selectedFilter.categoryFilter?.length +
                selectedFilter.tagFilter?.length +
                selectedFilter.typeFilter?.length +
                selectedFilter.topic?.length +
                selectedFilter?.subjectFilter?.length
        );
        setPage(1);
        getDataSource(selectedFilter, searchVal, 1, true);
    }, [selectedFilter]);

    const handleLevel = () => {
        let totalWeightage = 0;

        selectedQuestion?.map((Que) => {
            totalWeightage = selectedQuestionList.includes(Que._id)
                ? Que.difficultyLevel === "Easy"
                    ? totalWeightage + 1
                    : Que.difficultyLevel === "Medium"
                      ? totalWeightage + 2
                      : Que.difficultyLevel === "Hard"
                        ? totalWeightage + 3
                        : totalWeightage + 0
                : totalWeightage + 0;
        });
        const level =
            selectedQuestionList?.length > 1
                ? Math.round(totalWeightage / selectedQuestionList?.length)
                : totalWeightage;
        setDifficulty(
            level === 1
                ? "Easy"
                : level === 2
                  ? "Medium"
                  : level === 3
                    ? "Hard"
                    : ""
        );
    };
    useEffect(() => {
        handleLevel();
    }, [selectedQuestionList, totalQuestions]);
    const handleTopic = (id: number, QAdded: boolean) => {
        const currentQuestion = totalQuestions?.filter(
            (Que) => Que._id === id
        )?.[0];

        let tempcat = selectedQuestion;
        if (!QAdded) {
            tempcat = tempcat?.filter((q) => q._id !== id);
            if (
                subjectList?.filter(
                    (to) => to?.value === currentQuestion.subject?._id
                )?.[0]?.noOfQuestion > 1
            ) {
                const updatedSubject = subjectList?.map((subject) =>
                    subject?.value === currentQuestion.subject?._id
                        ? {
                              ...subject,
                              noOfQuestion: subject?.noOfQuestion - 1,
                          }
                        : subject
                );
                setSubjectList(updatedSubject);
            } else {
                const updatedSubject = subjectList?.filter(
                    (subject) => subject?.value !== currentQuestion.subject?._id
                );
                setSubjectList(updatedSubject);
            }
        } else {
            tempcat.push(currentQuestion);
            if (
                subjectList.find(
                    (to) => to?.value === currentQuestion.subject?._id
                )
            ) {
                const updatedSubejct = subjectList?.map((subject) =>
                    subject?.value === currentQuestion.subject?._id
                        ? {
                              ...subject,
                              noOfQuestion: subject?.noOfQuestion + 1,
                          }
                        : subject
                );
                setSubjectList(updatedSubejct);
            } else {
                setSubjectList([
                    ...subjectList,
                    {
                        label: currentQuestion.subject?.name,
                        value: currentQuestion.subject?._id,
                        color: chartColor?.filter(
                            (val) =>
                                !subjectList
                                    ?.map((list) => list?.color)
                                    ?.includes(val)
                        )?.[0],
                        noOfQuestion: 1,
                    },
                ]);
            }
        }
        setSelectedQuestion([...tempcat]);
    };

    const handleCheckbox = (e, id) => {
        let tempcat = selectedQuestionList;
        if (!e) {
            tempcat = tempcat?.filter((q) => q !== id);
            handleTopic(id, false);
            handleLevel();
        } else {
            tempcat.push(id);
            handleTopic(id, true);
            handleLevel();
        }
        return setSelectedQuestionList([...tempcat]);
    };

    const renderData = () => {
        if (totalQuestions?.length) {
            {
                return totalQuestions?.map((question) => (
                    <AppQuestionListItem
                        handleCheckBox={(e, id) =>
                            e &&
                            selectedQuestionList?.length >= configTotalQuestion
                                ? errorToast(
                                      "You have selected more then total question"
                                  )
                                : handleCheckbox(e, id)
                        }
                        checked={
                            !!selectedQuestionList?.filter(
                                (que) => que === question._id
                            )?.length
                        }
                        isPrepQuestionList={true}
                        showActions={false}
                        question={question}
                        configTotalQue={configTotalQuestion}
                        selectedTotalQue={selectedQuestionList?.length}
                    />
                ));
            }
        } else if (!hasMoreLoading) {
            return (
                <div className="no-data-wrapper">
                    <Logo height={100} width={100} />
                    <div className="no-data">No Data Found</div>
                </div>
            );
        }
    };
    return (
        <Fragment>
            {isRefreshLoading ? <AppLoader /> : null}
            <div className="d-block mb-2">
                <h4 className="pt-3">Select Question</h4>
                <div className="d-block d-sm-flex pt-2 justify-content-end user-header-width">
                    <AppFilterButton
                        className={`user-filter mr-2 ${FilterItems > 0 ? "active" : ""}`}
                        onClick={() => {
                            setShowFilter(true);
                        }}
                    >
                        {t("common.button.filter")}
                        {FilterItems > 0 && ` (${FilterItems})`}
                    </AppFilterButton>
                    <AppListPageToolbar
                        onQuickFilterChange={(searchStr) => {
                            setSearchVal(searchStr);
                            setPage(1);
                            getDataSource(selectedFilter, searchStr, 1, true);
                        }}
                        cancelTokenSources={cancelTokenSourcesRef.current}
                    />
                </div>
            </div>
            <Row>
                <Col>
                    <AppInfiniteScroll
                        renderData={renderData}
                        hasMore={hasMore}
                        page={page}
                        setPage={setPage}
                        hasMoreLoading={hasMoreLoading}
                        dataLength={totalQuestions?.length}
                    />
                </Col>
            </Row>
            {showFilter && setShowFilter && (
                <FilterDrawer
                    appliedFilter={selectedFilter}
                    setAppliedFilter={setSelectedFilter}
                    showFilter={showFilter}
                    setShowFilter={setShowFilter}
                    handleFilter={() => {}}
                    isPrepPage={true}
                />
            )}
            <AppScrollButton scrolTo={400} />
        </Fragment>
    );
};
