import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Form, ProgressBar, Nav } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
    AppPageHeader,
    AppBreadcrumb,
    AppFormActions,
    AppCard,
    AppFormInput,
    AppLoader,
    AppFormSelect,
    AppFormMultiSelectCreatable,
    // AppLoader,
} from "../../components";
import "./assets/scss/style.scss";
import { PrimitiveObject } from "../../models";
import { errorToast, successToast, validation } from "../../utils";
import { find as _find } from "lodash";

import { useTranslation } from "../../hooks";
import { schema } from "./schema";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext, ContainerTypes } from "../../contexts/AppContext";
import { ConfigResponse, PrepConfigApi } from "../../apis/PrepConfigApi";
import { buildConfig } from "../../../AuthModule/contexts";
import { PrepQuestionList } from "../PrepQuestionList";
import { PrepPreviewList } from "../PrepPreviewList";
import { PrepApi } from "../../apis/PrepApi";
import { AppliedFilter } from "../../containers/AppGrid/AppGrid";

export const PrepAddEdit = ({ isReadOnly = false }): JSX.Element => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { state, dispatch } = React.useContext(AppContext);
    const navigate = useNavigate();
    const { isLoading, isRefreshLoading } = state;
    const [configList, setConfigList] = useState<Array<any>>([]);
    const [defaultData, setDefaultData] = useState<any>({});
    const [subjectList, setSubjectList] = useState<Array<PrimitiveObject>>([]);
    const [difficulty, setDifficulty] = useState("");
    const [selectedQuestion, setSelectedQuestion] = useState<Array<any>>([]);
    const [selectedQuestionList, setSelectedQuestionList] = useState<
        Array<string>
    >([]);
    const [selectedExam, setSelectedExam] = useState<any>();
    const [examList, setExamList] = useState<Array<any>>([]);
    const [prpeName, setPrepName] = useState("");
    const { control, handleSubmit, formState, setValue } = useForm<any>({
        resolver: yupResolver<any>(schema()),
        mode: "all",
    });
    const chartColor = [
        "#FF5733",
        "#33FF57",
        "#3357FF",
        "#FF33A1",
        "#A133FF",
        "#FF8C33",
        "#33FF8C",
        "#8C33FF",
        "#FF5733",
        "#33FF57",
        "#FF33A1",
        "#A133FF",
        "#33FF8C",
        "#FF8C33",
        "#FF5733",
        "#33FF57",
        "#3357FF",
        "#FF33A1",
        "#A133FF",
        "#FF8C33",
        "#33FF8C",
        "#8C33FF",
        "#FF5733",
        "#33FF57",
        "#FF33A1",
        "#A133FF",
        "#33FF8C",
        "#FF8C33",
        "#FF5733",
        "#33FF57",
        "#3357FF",
        "#FF33A1",
        "#A133FF",
        "#FF8C33",
        "#33FF8C",
        "#8C33FF",
        "#FF5733",
        "#33FF57",
        "#FF33A1",
        "#A133FF",
        "#33FF8C",
        "#FF8C33",
        "#FF5733",
        "#33FF57",
        "#3357FF",
        "#FF33A1",
        "#A133FF",
        "#FF8C33",
        "#33FF8C",
        "#8C33FF",
    ];
    const [selectedConfig, setSelectedConfig] = useState<ConfigResponse>();
    const [isPreview, setIsPreview] = useState<boolean>(false);
    const [publishLoading, setPublishLoading] = useState<boolean>(false);
    const [selectedFilter, setSelectedFilter] = useState<AppliedFilter>({
        categoryFilter: [],
        typeFilter: [],
        tagFilter: [],
        topic: [],
        subjectFilter: [],
        examFilter: [],
    });

    useEffect(() => {
        setSelectedFilter({
            ...selectedFilter,
            examFilter: selectedExam?.map((val) => val.value),
        });
    }, [selectedExam]);

    useEffect(() => {
        const est = localStorage.getItem("ep_est");
        const estArray = JSON.parse(est || "");

        if (est) {
            setExamList(
                estArray?.exams?.map((exam) => {
                    return { label: exam.name, value: exam._id };
                })
            );
            if (estArray?.exams?.length == 1) {
                setSelectedExam([
                    {
                        label: estArray?.exams?.[0].name,
                        value: estArray?.exams?.[0]._id,
                    },
                ]);
            }
        }
    }, []);

    useEffect(() => {
        const GetData = async () => {
            if (!isRefreshLoading) {
                dispatch({
                    type: ContainerTypes.LOADING,
                });
            }
            await PrepConfigApi.ConfigList(
                buildConfig(localStorage?.getItem("ep_token") || ({} as any))
            ).then((response: any) => {
                const res = response.response;
                if (res !== null) {
                    setConfigList([...res?.items]);
                    dispatch({
                        type: ContainerTypes.LOADED,
                    });
                    dispatch({
                        type: ContainerTypes.REFRESH_LOADED,
                    });
                }
            });
        };

        const getPrepData = async () => {
            if (!isRefreshLoading) {
                dispatch({
                    type: ContainerTypes.LOADING,
                });
            }
            if (id) {
                await PrepApi.GetPrepById(
                    id,
                    buildConfig(
                        localStorage?.getItem("ep_token") || ({} as any)
                    )
                ).then((response: any) => {
                    const res = response.response;
                    if (res !== null) {
                        const data = res?.items;
                        if (!isReadOnly && data?.isPublish) {
                            return navigate("/preps");
                        }
                        setDefaultData(data);
                        setPrepName(data.name);
                        setDifficulty(data.difficultyLevel);
                        setSelectedQuestionList(
                            data.questions?.map((q) => q._id)
                        );
                        setSubjectList(
                            data.subjectWeightage?.map((topic, i) => {
                                return {
                                    label: topic.name,
                                    value: topic.subjectId,
                                    color: chartColor[i],
                                    noOfQuestion: Math.round(
                                        (data.questions?.length * topic.count) /
                                            100
                                    ),
                                };
                            })
                        );
                        setSelectedExam(
                            data.exams.map((se) => {
                                return { label: se.name, value: se._id };
                            })
                        );
                        setSelectedQuestion(data.questions);
                        setValue("PrepConfig", data?.prepConfig?._id);
                        dispatch({
                            type: ContainerTypes.LOADED,
                        });
                        dispatch({
                            type: ContainerTypes.REFRESH_LOADED,
                        });
                    }
                });
            }
        };

        if (id) {
            getPrepData();
        }
        GetData();
    }, [id]);

    useEffect(() => {
        if (defaultData && configList) {
            setSelectedConfig(
                configList?.filter(
                    (config) => config?._id === defaultData?.prepConfig?._id
                )?.[0]
            );
        }
    }, [defaultData, configList]);
    const getFromData = () => {
        return {
            name: prpeName,
            prepConfigId: selectedConfig?._id || "0",
            questionId: selectedQuestionList?.map((q) => {
                return q;
            }),
        };
    };
    const onSubmit = async () => {
        const data = getFromData();
        if (!data || !selectedExam?.length) {
            errorToast("Please select exam");
            return;
        }
        if (!data || !data.prepConfigId) {
            errorToast("Please select Configuration");
            return;
        }
        if (
            !!selectedConfig?.totalQuestions &&
            selectedQuestionList?.length > selectedConfig?.totalQuestions
        ) {
            errorToast("You have selected more then total question");
            return;
        }
        if (id) {
            await PrepApi.UpdatePrep(
                {
                    ...data,
                    isPublish: false,
                    isVisible: false,
                    examId: selectedExam?.map((se) => {
                        return se?.value;
                    }),
                },
                id,
                buildConfig(localStorage?.getItem("ep_token") || ({} as any))
            )
                .then((response) => {
                    if (response.success) successToast(response?.message);
                    else errorToast(response?.message);
                    navigate("..");
                })
                .catch((e) => {
                    errorToast(e?.message);
                });
        } else {
            await PrepApi.AddPrep(
                {
                    ...data,
                    isPublish: false,
                    isVisible: false,
                    examId: selectedExam?.map((se) => {
                        return se?.value;
                    }),
                },
                buildConfig(localStorage?.getItem("ep_token") || ({} as any))
            )
                .then((response) => {
                    if (response.success) successToast(response?.message);
                    else errorToast(response?.message);
                    navigate("..");
                })
                .catch((e) => {
                    errorToast(e?.message);
                });
        }
    };
    const handlePublish = async () => {
        setPublishLoading(true);
        const data = getFromData();
        if (id) {
            await PrepApi.UpdatePrep(
                {
                    ...data,
                    isPublish: true,
                    isVisible: true,
                    examId: selectedExam?.map((se) => {
                        return se?.value;
                    }),
                },
                id,
                buildConfig(localStorage?.getItem("ep_token") || ({} as any))
            )
                .then((response) => {
                    setPublishLoading(false);
                    if (response.success)
                        successToast("Your prep published successfully.");
                    else errorToast(response?.message);
                    navigate("..");
                })
                .catch((e) => {
                    setPublishLoading(false);
                    errorToast(e?.message);
                });
        } else {
            await PrepApi.AddPrep(
                {
                    ...data,
                    isPublish: true,
                    isVisible: true,
                    examId: selectedExam?.map((se) => {
                        return se?.value;
                    }),
                },
                buildConfig(localStorage?.getItem("ep_token") || ({} as any))
            )
                .then((response) => {
                    setPublishLoading(false);
                    if (response.success)
                        successToast("Your prep published successfully.");
                    else errorToast(response?.message);
                    navigate("..");
                })
                .catch((e) => {
                    setPublishLoading(false);
                    errorToast(e?.message);
                });
        }
    };
    const { errors } = formState;

    return (
        <Fragment>
            {isLoading ? (
                <AppLoader coverContainer={true} />
            ) : isRefreshLoading ? (
                <AppLoader />
            ) : null}
            <AppBreadcrumb
                linkText={t("common.breadcrumb.prep")}
                linkUrl={isReadOnly ? "/preps" : ".."}
            />
            <AppPageHeader
                title={
                    isReadOnly
                        ? t("admin.prep.form.header.viewTitle")
                        : id
                          ? t("admin.prep.form.header.editTitle")
                          : t("admin.prep.form.header.title")
                }
            />
            {!isLoading && !isRefreshLoading && (
                <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col md={12}>
                            <AppCard>
                                <Row className="m-0">
                                    {((examList?.length > 1 &&
                                        id &&
                                        selectedExam &&
                                        !isReadOnly) ||
                                        (examList?.length > 1 && !id)) && (
                                        <AppFormMultiSelectCreatable
                                            id={"Exam"}
                                            name={"Exam"}
                                            label={t(
                                                "admin.prep.form.label.exam"
                                            )}
                                            className="role-container "
                                            md={12}
                                            lg={4}
                                            xl={4}
                                            required={true}
                                            {...validation(
                                                "Exam",
                                                formState,
                                                false
                                            )}
                                            onChangeHandler={(e) => {
                                                setSelectedQuestion([]);
                                                setSelectedQuestionList([]);
                                                setSubjectList([]);
                                                setSelectedFilter({
                                                    ...selectedFilter,
                                                    examFilter: e?.map(
                                                        (val) => val.value
                                                    ),
                                                });
                                                setSelectedExam(e);
                                            }}
                                            isValidNewOption={false}
                                            value={selectedExam}
                                            placeholder={"Exam"}
                                            // errorMessage={errors.role?.message}
                                            options={examList}
                                            control={control as any}
                                        />
                                    )}
                                    {(!(examList?.length > 1) ||
                                        isReadOnly) && (
                                        <Col md={12} lg={4} xl={4}>
                                            <div
                                                style={{
                                                    fontWeight: "700",
                                                    fontSize: "12px",
                                                }}
                                            >
                                                Exam
                                            </div>
                                            <div className="d-flex">
                                                {selectedExam?.[0].label}
                                            </div>
                                        </Col>
                                    )}
                                    {(id && !isReadOnly && prpeName != "") ||
                                    !id ? (
                                        <AppFormInput
                                            lg={4}
                                            xl={4}
                                            name={"Name"}
                                            label={t(
                                                "admin.prep.form.label.name"
                                            )}
                                            defaultValue={prpeName}
                                            required={true}
                                            onChange={setPrepName}
                                            {...validation(
                                                "Name",
                                                formState,
                                                false
                                            )}
                                            errorMessage={errors.Name?.message}
                                            control={control as any}
                                        />
                                    ) : (
                                        <Col md={12} lg={4} xl={4}>
                                            <div
                                                style={{
                                                    fontWeight: "700",
                                                    fontSize: "12px",
                                                }}
                                            >
                                                Name
                                            </div>
                                            <div className="d-flex">
                                                {prpeName}
                                            </div>
                                        </Col>
                                    )}
                                    {(id &&
                                        selectedConfig !== undefined &&
                                        !isReadOnly) ||
                                    !id ? (
                                        <AppFormSelect
                                            id={"PrepConfig"}
                                            name={"PrepConfig"}
                                            label={t(
                                                "admin.prep.form.label.prepConfig"
                                            )}
                                            className="role-container "
                                            errorMessage={
                                                errors.PrepConfig?.message
                                            }
                                            md={12}
                                            lg={4}
                                            xl={4}
                                            required={true}
                                            {...validation(
                                                "PrepConfig",
                                                formState,
                                                false
                                            )}
                                            defaultValue={{
                                                label: selectedConfig?.name,
                                                value: selectedConfig?._id,
                                            }}
                                            placeholder={"Configuration"}
                                            NoMessageText={
                                                "Please create a Prep Configuration"
                                            }
                                            options={configList?.map((item) => {
                                                return {
                                                    label: item?.name,
                                                    value: item?._id,
                                                };
                                            })}
                                            control={control as any}
                                            transform={{
                                                output: (
                                                    template: PrimitiveObject
                                                ) => {
                                                    setSelectedConfig(
                                                        configList?.filter(
                                                            (config) =>
                                                                config._id ===
                                                                template?.value
                                                        )?.[0]
                                                    );
                                                    return template?.value;
                                                },
                                                input: (value: string) => {
                                                    return _find([], {
                                                        value,
                                                    });
                                                },
                                            }}
                                        />
                                    ) : (
                                        <Col md={12} lg={4} xl={4}>
                                            <div
                                                style={{
                                                    fontWeight: "700",
                                                    fontSize: "12px",
                                                }}
                                            >
                                                Configuration
                                            </div>
                                            <div className="d-flex">
                                                {selectedConfig?.name}
                                            </div>
                                        </Col>
                                    )}
                                </Row>
                                {selectedConfig ? (
                                    <>
                                        <Row className="m-0">
                                            <Col
                                                md={6}
                                                lg={4}
                                                xl={4}
                                                className="mb-3"
                                            >
                                                <div
                                                    style={{
                                                        fontWeight: "700",
                                                        fontSize: "12px",
                                                    }}
                                                >
                                                    Selected PrepConfiguration
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="m-0">
                                            <Col md={6} lg={2} xl={2}>
                                                <div
                                                    className={
                                                        "view-field-name"
                                                    }
                                                >
                                                    Total Question
                                                </div>
                                                <div className="d-flex">
                                                    {
                                                        selectedConfig?.totalQuestions
                                                    }
                                                </div>
                                            </Col>
                                            <Col md={6} lg={2} xl={2}>
                                                <div
                                                    className={
                                                        "view-field-name"
                                                    }
                                                >
                                                    Total Time
                                                </div>
                                                <div className="d-flex">
                                                    {selectedConfig?.totalTime}
                                                </div>
                                            </Col>
                                            <Col md={4} lg={2} xl={2}>
                                                <div
                                                    className={
                                                        "view-field-name"
                                                    }
                                                >
                                                    Correct answer
                                                </div>
                                                <div className="d-flex">
                                                    {
                                                        selectedConfig?.correctMarks
                                                    }
                                                </div>
                                            </Col>
                                            <Col md={4} lg={2} xl={2}>
                                                <div
                                                    className={
                                                        "view-field-name"
                                                    }
                                                >
                                                    Skipped Question
                                                </div>
                                                <div className="d-flex">
                                                    {
                                                        selectedConfig?.skippedMarks
                                                    }
                                                </div>
                                            </Col>
                                            <Col md={4} lg={2} xl={2}>
                                                <div
                                                    className={
                                                        "view-field-name"
                                                    }
                                                >
                                                    Incorrect Answer
                                                </div>
                                                <div className="d-flex">
                                                    {
                                                        selectedConfig?.incorrectMarks
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </AppCard>
                            {selectedConfig?.totalQuestions && (
                                <>
                                    <Col
                                        className="overview-wrapper"
                                        md={
                                            selectedConfig?.showSubjectWeightage
                                                ? 12
                                                : 4
                                        }
                                    >
                                        <AppCard className="p-4">
                                            <h4>Overview</h4>
                                            <Row className="m-0">
                                                {selectedConfig && (
                                                    <>
                                                        {selectedConfig?.showSubjectWeightage ? (
                                                            <Col
                                                                lg={10}
                                                                xl={10}
                                                            >
                                                                <Form.Label>
                                                                    {"Weight"}
                                                                </Form.Label>

                                                                <ProgressBar>
                                                                    {selectedConfig?.totalQuestions &&
                                                                        subjectList?.map(
                                                                            (
                                                                                subject
                                                                            ) => {
                                                                                return (
                                                                                    <ProgressBar
                                                                                        now={
                                                                                            (subject?.noOfQuestion *
                                                                                                100) /
                                                                                            selectedConfig?.totalQuestions
                                                                                        }
                                                                                        key={
                                                                                            selectedConfig?.totalQuestions
                                                                                        }
                                                                                        style={{
                                                                                            backgroundColor: `${subject?.color}`,
                                                                                        }}
                                                                                    />
                                                                                );
                                                                            }
                                                                        )}
                                                                </ProgressBar>
                                                                <div className="topic-list-wrapper mt-2">
                                                                    <div className="d-flex">
                                                                        {subjectList?.map(
                                                                            (
                                                                                subject
                                                                            ) => {
                                                                                return (
                                                                                    <div className="d-flex align-items-baseline me-2">
                                                                                        <div
                                                                                            className="topic-color me-1"
                                                                                            style={{
                                                                                                backgroundColor:
                                                                                                    subject?.color,
                                                                                            }}
                                                                                        ></div>
                                                                                        <div>
                                                                                            {
                                                                                                subject.label
                                                                                            }
                                                                                            {` (${subject.noOfQuestion})`}
                                                                                        </div>
                                                                                    </div>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </div>
                                                                    <div>
                                                                        {`Selected: ${selectedQuestionList?.length} / ${selectedConfig?.totalQuestions}`}
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        ) : (
                                                            <Col lg={6} xl={6}>
                                                                {`Selected: ${selectedQuestionList?.length} / ${selectedConfig?.totalQuestions}`}
                                                            </Col>
                                                        )}
                                                        <Col
                                                            lg={
                                                                selectedConfig?.showSubjectWeightage
                                                                    ? 2
                                                                    : 6
                                                            }
                                                            xl={
                                                                selectedConfig?.showSubjectWeightage
                                                                    ? 2
                                                                    : 6
                                                            }
                                                        >
                                                            <Form.Label>
                                                                {
                                                                    "Difficulty Level"
                                                                }
                                                            </Form.Label>
                                                            <p>{difficulty}</p>
                                                        </Col>
                                                    </>
                                                )}
                                            </Row>
                                        </AppCard>
                                    </Col>
                                    <AppCard>
                                        {!isReadOnly ? (
                                            <Nav
                                                variant="tabs"
                                                defaultActiveKey="/home"
                                            >
                                                <Nav.Item
                                                    role="button"
                                                    className={`nav-link ${!isPreview ? "active" : ""}`}
                                                    onClick={() =>
                                                        setIsPreview(false)
                                                    }
                                                >
                                                    List
                                                </Nav.Item>
                                                <Nav.Item
                                                    role="button"
                                                    className={`nav-link ${isPreview ? "active" : ""}`}
                                                    onClick={() =>
                                                        setIsPreview(true)
                                                    }
                                                >
                                                    Preview
                                                </Nav.Item>
                                            </Nav>
                                        ) : (
                                            <></>
                                        )}
                                        {!isReadOnly && !isPreview ? (
                                            <PrepQuestionList
                                                setSelectedQuestionList={
                                                    setSelectedQuestionList
                                                }
                                                selectedQuestionList={
                                                    selectedQuestionList
                                                }
                                                configTotalQuestion={
                                                    selectedConfig?.totalQuestions ||
                                                    0
                                                }
                                                selectedQuestion={
                                                    selectedQuestion
                                                }
                                                setSelectedQuestion={
                                                    setSelectedQuestion
                                                }
                                                setDifficulty={setDifficulty}
                                                setSubjectList={setSubjectList}
                                                subjectList={subjectList}
                                                selectedFilter={selectedFilter}
                                                setSelectedFilter={
                                                    setSelectedFilter
                                                }
                                            />
                                        ) : (
                                            <PrepPreviewList
                                                setDifficulty={setDifficulty}
                                                setSubjectList={setSubjectList}
                                                subjectList={subjectList}
                                                isReadOnly={isReadOnly}
                                                setSelectedQuestionList={
                                                    setSelectedQuestionList
                                                }
                                                selectedQuestionList={
                                                    selectedQuestionList
                                                }
                                                selectedQuestion={
                                                    selectedQuestion
                                                }
                                                setSelectedQuestion={
                                                    setSelectedQuestion
                                                }
                                            />
                                        )}
                                    </AppCard>
                                </>
                            )}
                        </Col>

                        {!isReadOnly && (
                            <AppFormActions
                                className="prep-actions"
                                isEditMode={false}
                                cancelLabel={"Publish"}
                                cancelingLabel={"Publishing..."}
                                cancelLoading={publishLoading}
                                publishDisabled={
                                    selectedConfig
                                        ? selectedQuestionList?.length !==
                                              selectedConfig?.totalQuestions ||
                                          !prpeName
                                        : true
                                }
                                backAction={handlePublish}
                                navigation={navigate}
                                isLoading={formState.isSubmitting}
                            />
                        )}
                    </Row>
                </Form>
            )}
        </Fragment>
    );
};
