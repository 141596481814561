import * as yup from "yup";

const validations = {
    slugKey: {
        min: 2,
        max: 64,
    },
    title: {
        min: 2,
        max: 16,
    },
};

const schema = (isEditMode: boolean) => {
    let validationShape = {
        Question: yup.string().required(),
        OptionA: yup.string().required(),
        OptionB: yup.string().required(),
        OptionC: yup.string().required(),
        OptionD: yup.string().required(),
        Solution: yup.string(),
        Subject: yup.string().required(),
        Tags: yup.array().nullable(),
        Topic: yup.string().required(),
        // email: yup.string().email().required(),
        // locale: yup.string().nullable(),
        // role: yup.string().required("Role is Reqired"),
        // relationManager: yup.string(),
        // timezone: yup.string().nullable(),
        // isBlocked: yup.boolean(),
    };
    if (!isEditMode) {
        validationShape = {
            ...validationShape,
        };
    }

    return yup.object().shape(validationShape);
};

export { schema, validations };
