import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { API } from "../../AppModule/apis/API";
import { ROUTES } from "../../config";
import { FinalResponse, ServerError } from "../../AppModule/models";

const {
    api_users_login_check_collection: API_LOGIN_CHECK,
    api_users_me_collection: API_ME,
    api_est: API_EST,
} = ROUTES;

export interface LoginResponse {
    token: string;
    refreshToken: string;
}

export interface LoginPayload {
    email: string;
    password: string;
}

export class AuthApi extends API {
    static async login(credentials: LoginPayload): Promise<LoginResponse> {
        const res: AxiosResponse<LoginResponse> = await this.makePost<
            LoginResponse,
            LoginPayload
        >(API_LOGIN_CHECK, credentials);
        return res?.data;
    }

    static async estList<E>(config: AxiosRequestConfig = {}) {
        return this.makeGet<E>(API_EST, {}, config)
            .then(({ data }: any) => {
                return data;
            })
            .catch((error: AxiosError | ServerError) => {
                const { message } = error;
                return Promise.resolve(new FinalResponse(null, message));
            });
    }

    public static async me(config: AxiosRequestConfig = {}): Promise<any> {
        const res: AxiosResponse<LoginResponse> =
            await this.makeOptions<LoginResponse>(API_ME, {}, config);
        return res;
    }
}
