import React, { FC } from "react";
import CreatableSelect from "react-select/creatable";
import { MenuPlacement } from "react-select";
import { Control, Controller } from "react-hook-form";
import { Form, Col } from "react-bootstrap";
import { PrimitiveObject } from "../../models";

import "./assets/scss/style.scss";

export interface AppFormSelectCreatableProps {
    id: string;
    name: string;
    value: PrimitiveObject[];
    placeholder?: string;
    isValidNewOption?: boolean;
    label?: string;
    size?: "lg" | "sm";
    sm?: string | number;
    md?: string | number;
    lg?: string | number;
    xl?: string | number;
    options: PrimitiveObject[];
    menuPlacement?: MenuPlacement;
    required?: boolean;
    className?: string;
    description?: string;
    errorMessage?: string;
    control?: Control<any>;
    handleCreate?: (value: any) => void;
    onChangeHandler?: (value: any) => void;
}

export const AppFormMultiSelectCreatable: FC<AppFormSelectCreatableProps> = ({
    id,
    name,
    value,
    isValidNewOption = true,
    placeholder = "",
    options,
    className = "",
    control,
    description,
    sm = 12,
    md = 6,
    lg = 4,
    xl = 4,
    required = false,
    errorMessage,
    label = "",
    onChangeHandler = () => {},
    handleCreate = () => {},
}): JSX.Element => {
    const controlId = id || name;
    return (
        <Form.Group
            as={Col}
            md={md}
            sm={sm}
            lg={lg}
            xl={xl}
            controlId={controlId}
            className={`mb-0 ${className}`}
        >
            {label?.length > 0 ? (
                <Form.Label>
                    {label}
                    {required && <span className="required">*</span>}
                    {description && (
                        <div className="custom-input-description">
                            <span>i</span>
                            <div className="custom-input-description-content">
                                {description}
                            </div>
                        </div>
                    )}
                </Form.Label>
            ) : null}
            {control ? (
                <Controller
                    name={name}
                    control={control}
                    render={({ field }) => (
                        <CreatableSelect
                            {...field}
                            isMulti
                            value={value}
                            options={options}
                            placeholder={placeholder}
                            isValidNewOption={() => isValidNewOption}
                            className="custom-select-container form-control"
                            classNamePrefix="custom-select"
                            onChange={onChangeHandler}
                            onCreateOption={handleCreate}
                            menuPortalTarget={document.body}
                            styles={{
                                menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                }),
                            }}
                        />
                    )}
                />
            ) : (
                <CreatableSelect
                    isMulti
                    value={value}
                    options={options}
                    placeholder={placeholder}
                    isValidNewOption={() => isValidNewOption}
                    className="custom-select-container form-control"
                    classNamePrefix="custom-select"
                    onChange={onChangeHandler}
                    onCreateOption={handleCreate}
                    menuPortalTarget={document.body}
                    styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                />
            )}
            <span className="invalid-feedback d-block">
                {errorMessage && errorMessage}
            </span>
        </Form.Group>
    );
};
