import { FC } from "react";
import { Col, Row } from "react-bootstrap";
import { Canceler } from "axios";
import { useTranslation } from "../../hooks";
import { AppListPageToolbar } from "../AppListPageToolbar";
import "./assets/scss/style.scss";

export interface AppPageHeaderProps {
    title: string;
    subTitle?: string;
    isSubTitleTranslated?: boolean;
    createLink?: string;
    showToolbar?: boolean;
    customToolbar?: boolean;
    createLabel?: string;
    onQuickFilterChange?: (s: string) => void;
    cancelTokenSources?: Canceler[];
    showSearchBar?: boolean;
    children?: any;
}

/**
 * @Deprecated instead of this use `AppPageHeaderTranslatable`
 * */
export const AppPageHeader: FC<AppPageHeaderProps> = ({
    title,
    subTitle,
    createLink,
    createLabel = "common.button:create",
    showToolbar = false,
    customToolbar = false,
    onQuickFilterChange,
    cancelTokenSources,
    children,
    showSearchBar,
}): JSX.Element => {
    let mdSize = 6;
    if (customToolbar) {
        mdSize = 12;
    } else if (showToolbar === false) {
        mdSize = 12;
    }
    return (
        <Row className="pt-sm-3">
            <Col xs={12} md={mdSize} lg={"auto"} className="page-title">
                <h1 className={subTitle ? "mb-0" : ""}>{title}</h1>
                {subTitle && <span className="sub-title">{subTitle}</span>}
            </Col>
            {showToolbar && (
                <Col md={6} className="d-flex justify-content-end mr-0 ml-auto">
                    <AppListPageToolbar
                        createLabel={createLabel}
                        createLink={createLink}
                        onQuickFilterChange={onQuickFilterChange}
                        cancelTokenSources={cancelTokenSources}
                        showSearchBar={showSearchBar}
                    />
                </Col>
            )}
            {customToolbar && (
                <Col
                    className={`d-flex justify-content-start justify-content-sm-end mr-0 ${
                        showToolbar ? "ml-0 pl-0" : "ms-auto"
                    }`}
                    xs={12}
                    md={12}
                    lg={"auto"}
                >
                    {children}
                </Col>
            )}
        </Row>
    );
};

export interface AppPageHeaderTranslatableProps extends AppPageHeaderProps {
    isSubTitleTranslated?: boolean;
    isTitleTranslated?: boolean;
}

export const AppPageHeaderTranslatable: FC<AppPageHeaderTranslatableProps> = ({
    title = "",
    subTitle,
    isSubTitleTranslated = true,
    isTitleTranslated = true,
    ...rest
}): JSX.Element => {
    const { t } = useTranslation();
    const props: AppPageHeaderTranslatableProps = {
        title: isTitleTranslated ? t(title) : title,
        ...rest,
    };
    if (subTitle) {
        props.subTitle = isSubTitleTranslated ? subTitle : t(subTitle);
    }
    return <AppPageHeader {...props}></AppPageHeader>;
};
