import {
    AUTH_USER_KEY,
    AUTH_TOKEN_KEY,
    REFRESH_TOKEN_KEY,
} from "../../AppModule/config/app-env";

export const clearAuthStorage = async () => {
    localStorage.removeItem(AUTH_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    localStorage.removeItem(AUTH_USER_KEY);
};
