import React from "react";
import "./assets/scss/style.scss";

export const AppScrollButton = ({ scrolTo = 0 }): JSX.Element => {
    //Get the button
    const mybutton = document.getElementById("btn-back-to-top");

    function scrollFunction() {
        if (
            document.body.scrollTop > 500 ||
            document.documentElement.scrollTop > 500
        ) {
            if (mybutton) mybutton.style.display = "block";
        } else {
            if (mybutton) mybutton.style.display = "none";
        }
    }

    // When the user scrolls down 20px from the top of the document, show the button
    window.onscroll = function () {
        scrollFunction();
    };

    function backToTop() {
        document.body.scrollTop = scrolTo;
        document.documentElement.scrollTop = scrolTo;
    }

    // When the user clicks on the button, scroll to the top of the document
    if (mybutton) mybutton.addEventListener("click", backToTop);

    return (
        <button
            type="button"
            className="btn btn-floating btn-lg"
            id="btn-back-to-top"
        >
            <i className="fas fa-arrow-up"></i>
        </button>
    );
};
