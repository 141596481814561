import { ColDef } from "ag-grid-community/dist/lib/entities/colDef";
import { appGridFrameworkComponents } from "./app-grid-framework-components";
import { AppCellActionParamsUserList } from "./AppCellActionParamsUserList";

let getWidth = "right";
if (window.innerWidth < 760) {
    getWidth = "";
}
export const appGridColDef = ({
    onPressDelete,
}: AppCellActionParamsUserList): ColDef[] => [
    {
        headerName: "admin.prepConfig.list.name",
        field: "name",
        filter: "text",
        minWidth: 300,
        flex: 1,
        sortable: true,
    },
    {
        headerName: "admin.prepConfig.list.totalQuestions",
        field: "totalQuestions",
        filter: "text",
        sortable: true,
        minWidth: 100,
    },
    {
        headerName: "admin.prepConfig.list.totalTime",
        field: "totalTime",
        filter: "text",
        minWidth: 100,
        sortable: true,
    },
    {
        headerName: "admin.prepConfig.list.totalMark",
        field: "totalMarks",
        filter: "text",
        sortable: true,
        minWidth: 100,
    },
    {
        headerName: "admin.prepConfig.list.correctAnswerMarks",
        field: "correctMarks",
        filter: "text",
        sortable: true,
        minWidth: 100,
    },
    {
        headerName: "admin.prepConfig.list.incorrectAnswerMarks",
        field: "incorrectMarks",
        filter: "text",
        sortable: true,
        minWidth: 100,
    },
    {
        headerName: "admin.prepConfig.list.skippedQuestionMarks",
        field: "skippedMarks",
        filter: "text",
        sortable: true,
        minWidth: 100,
    },

    {
        headerName: "admin.prepConfig.list.used",
        field: "linkedCount",
        filter: "text",
        sortable: true,
        minWidth: 100,
    },
    {
        headerName: "question.users.list.column.actions",
        field: "_id",
        sortable: false,
        minWidth: 150,
        maxWidth: 150,
        cellClass: "text-right justify-content-end",
        headerClass: "action-header",
        cellRenderer: appGridFrameworkComponents.AppConfigRenderer as any,
        cellRendererParams: {
            onPressDelete,
        },
        lockPinned: true,
        pinned: getWidth,
    },
];
