import { ColDef } from "ag-grid-community/dist/lib/entities/colDef";
import { appGridFrameworkComponents } from "./app-grid-framework-components";
import { AppCellActionParamsUserList } from "./AppCellActionParamsUserList";

let getWidth = "right";
if (window.innerWidth < 760) {
    getWidth = "";
}
export const appGridColDef = ({
    onPressDelete,
    onPressVisible,
}: AppCellActionParamsUserList): ColDef[] => [
    {
        headerName: "admin.prep.list.name",
        field: "name",
        filter: "text",
        minWidth: 300,
        flex: 1,
        sortable: true,
    },
    {
        headerName: "admin.prep.list.level",
        field: "difficultyLevel",
        filter: "text",
        minWidth: 100,
        sortable: true,
    },
    {
        headerName: "admin.prep.list.used",
        field: "participatedCount",
        filter: "text",
        sortable: true,
        minWidth: 100,
    },
    {
        headerName: "admin.prep.list.isPublish",
        field: "isPublish",
        filter: "text",
        cellRenderer: appGridFrameworkComponents.AppIsPublish as any,
        sortable: true,
        minWidth: 100,
    },
    {
        headerName: "admin.prep.list.isVisible",
        field: "isVisible",
        filter: "text",
        cellRenderer: appGridFrameworkComponents.AppIsVisible as any,
        sortable: true,
        minWidth: 100,
    },
    {
        headerName: "question.users.list.column.actions",
        field: "_id",
        sortable: false,
        minWidth: 200,
        maxWidth: 200,
        cellClass: "text-right justify-content-end",
        headerClass: "action-header",
        cellRenderer: appGridFrameworkComponents.AppConfigRenderer as any,
        cellRendererParams: {
            onPressDelete,
            onPressVisible,
        },
        lockPinned: true,
        pinned: getWidth,
    },
];
