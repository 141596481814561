// eslint-disable-next-line import/no-extraneous-dependencies
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";

export const FroalaEditorParser = ({ model, className = "" }) => {
    return (
        <div className={`froala-editor-view ${className}`}>
            <FroalaEditorView model={model} />
        </div>
    );
};
