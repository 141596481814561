import { FC } from "react";
import { Col, Row } from "react-bootstrap";
import { AppIcon } from "../AppIcon";
import "./assets/scss/style.scss";
import { Link } from "react-router-dom";

export interface AppBreadcrumbProps {
    linkUrl: string;
    linkText: string;
    toolbar?: JSX.Element;
    toolbarClass?: string;
}

export const AppBreadcrumb: FC<AppBreadcrumbProps> = ({
    linkText,
    linkUrl,
    toolbar,
    toolbarClass,
}): JSX.Element => {
    return (
        <Row>
            <Col>
                <nav className={"es-breadcrumb d-flex align-items-center"}>
                    <AppIcon name={"ChevronLeft"} />
                    <Link to={linkUrl} className="ms-1">
                        {linkText}
                    </Link>
                </nav>
            </Col>
            {toolbar && <Col className={toolbarClass}>{toolbar}</Col>}
        </Row>
    );
};
