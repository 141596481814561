import { Col, Row } from "react-bootstrap";
import { Fragment } from "react/jsx-runtime";
import { AppGrid } from "../../containers/AppGrid";
import { useRef, useState } from "react";
import { GridApi } from "ag-grid-community";
import { AppListPageToolbar, AppLoader, AppPageHeader } from "../../components";
import { appGridColDef } from "./app-grid-col-def";
import { Question } from "../../models/entities/Question";
import { buildConfig } from "../../../AuthModule/contexts";
import { AppContext, ContainerTypes } from "../../contexts/AppContext";
import React from "react";
import { PrepConfigApi } from "../../apis/PrepConfigApi";
import { errorToast, successToast } from "../../utils";

export const PrepConfigList = () => {
    const [totalItems, setTotalItems] = useState<number>(0);
    const [isDataDeleted, setIsDataDeleted] = useState(false);
    const appGridApi = useRef<GridApi>();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const { state, dispatch } = React.useContext(AppContext);
    const { isRefreshLoading } = state;

    const handleDelete = async (id: any) => {
        return PrepConfigApi.deleteConfig(
            id,
            buildConfig(localStorage?.getItem("ep_token") || ({} as any))
        ).then((Response) => {
            if (Response.success) {
                successToast(Response?.message);
                setIsDataDeleted(true);
            } else {
                errorToast(Response?.message);
            }
        });
    };

    const getDataSource = async () => {
        dispatch({
            type: ContainerTypes.LOADING,
        });
        let data: Question[] = [];
        await PrepConfigApi.ConfigList(
            buildConfig(localStorage?.getItem("ep_token") || ({} as any))
        )
            .then((response: any) => {
                const res = response.response;
                if (res !== null) {
                    // core.handleUserList(res?.items);
                    setTotalItems(res?.totalItems);
                    data = {
                        ...res,
                        items: res?.items?.map((item) => {
                            return {
                                ...item,
                                totalMarks:
                                    item.correctMarks * item.totalQuestions,
                            };
                        }),
                    };
                }
            })
            .finally(() => {
                dispatch({
                    type: ContainerTypes.REFRESH_LOADED,
                });
            });
        if (isDataDeleted) {
            setIsDataDeleted(false);
        }
        return data;
    };

    return (
        <Fragment>
            {isRefreshLoading ? <AppLoader /> : null}
            <input
                ref={fileInputRef}
                id="select-file"
                type="file"
                accept=".csv"
                hidden={true}
            />
            <AppPageHeader title={"Prep Configuration"} customToolbar>
                <div className="d-block mb-3 mb-md-5">
                    <div className="d-block d-sm-flex pt-2 justify-content-end user-header-width">
                        <AppListPageToolbar
                            showSearchBar={false}
                            createLink={"/preps-config/new"}
                            createLabel="common.button.create"
                        />
                    </div>
                </div>
            </AppPageHeader>
            <Row>
                <Col>
                    <AppGrid
                        columnDef={appGridColDef({
                            onPressDelete: handleDelete,
                        })}
                        isDataDeleted={isDataDeleted}
                        getDataSource={getDataSource}
                        totalItems={totalItems}
                        onReady={(event) => {
                            appGridApi.current = event.api;
                        }}
                    />
                </Col>
            </Row>
        </Fragment>
    );
};
