import React, { FC } from "react";
import { useTranslation } from "react-i18next";

export interface AppGridNoRowsOverlayProps {
    noRowsMessageFunc: () => string;
}

export const AppGridNoRowsOverlay: FC<AppGridNoRowsOverlayProps> = ({}) => {
    const { t } = useTranslation();
    return (
        <div className="ag-overlay-no-rows-wrapper">
            <div>{t("common.list.message.nodata")}</div>
        </div>
    );
};
