import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

import { ROUTES } from "../config";
import {
    FinalResponse,
    ListResponse,
    RequestParamsType,
    ServerError,
    SimpleObject,
} from "../models";
import { EntityAPI } from "./EntityAPI";
import { onFindAllResponseJson } from "./transformer";

const {
    api_question: API_QUESTION,
    api_upload: API_UPLOAD,
    api_search: API_SEARCH,
    api_filter: API_FILTER,
    api_tags: API_TAGS,
    api_topic: API_TOPIC,
    api_exam: API_EXAM,
    api_subjects: API_SUBJECT,
} = ROUTES;

export interface QuestionResponse {
    success: boolean;
    message: string;
    questionData: {
        question: { description: string };
        options: [
            {
                description: string;
                isCorrect: boolean;
                _id: string;
            },
            {
                description: string;
                isCorrect: boolean;
                _id: string;
            },
            {
                description: string;
                isCorrect: boolean;
                _id: string;
            },
            {
                description: string;
                isCorrect: boolean;
                _id: string;
            },
        ];
        isMultiSelect: boolean;
        soluiton: { description: string };
        difficultyLevel: string;
        exams: Array<SimpleObject<string>>;
        subject: SimpleObject<string>;
        topic: SimpleObject<string>;
        tags: Array<SimpleObject<string>>;
    };
}

export interface Options {
    description: string;
    isCorrect: boolean;
}

export interface TagsPayload {
    name: string;
}

export interface QuestionPayload {
    question: { description: string };
    options: Array<Options>;
    solution: { description: string };
    difficultyLevel: string;
    tagId: Array<string>;
    topicId: string;
    examId: Array<string>;
    subjectId: string;
}

export interface FilterPayload {
    categoryFilter: Array<string>;
    typeFilter: Array<string>;
    tagFilter: Array<string>;
}

export class QuestionApi extends EntityAPI {
    static async AddQuestion(
        data: QuestionPayload,
        config: AxiosRequestConfig = {}
    ): Promise<QuestionResponse> {
        const res: AxiosResponse<QuestionResponse> = await this.makePost<
            QuestionResponse,
            QuestionPayload
        >(API_QUESTION, data, {}, config);
        return res?.data;
    }

    static async AddTags(
        data: TagsPayload,
        config: AxiosRequestConfig = {}
    ): Promise<any> {
        const res: AxiosResponse<any> = await this.makePost<
            QuestionResponse,
            TagsPayload
        >(API_TAGS, data, {}, config);
        return res?.data;
    }

    static async AddTopic(
        data: TagsPayload,
        config: AxiosRequestConfig = {}
    ): Promise<any> {
        const res: AxiosResponse<any> = await this.makePost<
            QuestionResponse,
            TagsPayload
        >(API_TOPIC, data, {}, config);
        return res?.data;
    }

    static async AddImage(data, config: AxiosRequestConfig = {}): Promise<any> {
        const res: AxiosResponse<any> = await this.makePost<any, any>(
            API_UPLOAD,
            data,
            {},
            config
        );
        return res?.data;
    }

    static async UpdateQuestion(
        data: QuestionPayload,
        id: string,
        config: AxiosRequestConfig = {}
    ): Promise<QuestionResponse> {
        const res: AxiosResponse<QuestionResponse> = await this.makePut<
            QuestionResponse,
            QuestionPayload
        >(`${API_QUESTION}/${id}`, data, {}, config);
        return res?.data;
    }

    public static async deleteQuestion(
        id: number,
        config: AxiosRequestConfig = {}
    ): Promise<any> {
        return this.makeDelete(`${API_QUESTION}/${id}`, config)
            .then(({ data }) => {
                return data;
            })
            .catch((error: AxiosError | ServerError) => {
                const { message } = error;
                return Promise.resolve(new FinalResponse(null, message));
            });
    }

    static async QuestionList<E>(
        config: AxiosRequestConfig = {},
        params: RequestParamsType = {}
    ) {
        return this.makeGet<E>(API_QUESTION, params, config)
            .then(({ data }: any) => {
                const list = onFindAllResponseJson<E>(data);
                return Promise.resolve(
                    new FinalResponse<ListResponse<E>>(list)
                );
            })
            .catch((error: AxiosError | ServerError) => {
                const { message } = error;
                return Promise.resolve(new FinalResponse(null, message));
            });
    }

    static async GetQuestion<E>(
        id: string,
        config: AxiosRequestConfig = {},
        params: RequestParamsType = {}
    ) {
        return this.makeGet<E>(`${API_QUESTION}/${id}`, params, config)
            .then(({ data }: any) => {
                const list = onFindAllResponseJson<E>(data);
                return Promise.resolve(
                    new FinalResponse<ListResponse<E>>(list)
                );
            })
            .catch((error: AxiosError | ServerError) => {
                const { message } = error;
                return Promise.resolve(new FinalResponse(null, message));
            });
    }

    static async TagList<E>(config: AxiosRequestConfig = {}) {
        return this.makeGet<E>(API_TAGS, {}, config)
            .then(({ data }: any) => {
                const list = onFindAllResponseJson<E>(data);
                return Promise.resolve(
                    new FinalResponse<ListResponse<E>>(list)
                );
            })
            .catch((error: AxiosError | ServerError) => {
                const { message } = error;
                return Promise.resolve(new FinalResponse(null, message));
            });
    }

    static async TopicList<E>(config: AxiosRequestConfig = {}) {
        return this.makeGet<E>(API_TOPIC, {}, config)
            .then(({ data }: any) => {
                const list = onFindAllResponseJson<E>(data);
                return Promise.resolve(
                    new FinalResponse<ListResponse<E>>(list)
                );
            })
            .catch((error: AxiosError | ServerError) => {
                const { message } = error;
                return Promise.resolve(new FinalResponse(null, message));
            });
    }

    static async ExamList<E>(config: AxiosRequestConfig = {}) {
        return this.makeGet<E>(API_EXAM, {}, config)
            .then(({ data }: any) => {
                const list = onFindAllResponseJson<E>(data);
                return Promise.resolve(
                    new FinalResponse<ListResponse<E>>(list)
                );
            })
            .catch((error: AxiosError | ServerError) => {
                const { message } = error;
                return Promise.resolve(new FinalResponse(null, message));
            });
    }

    static async SubjecticList<E>(config: AxiosRequestConfig = {}) {
        return this.makeGet<E>(API_SUBJECT, {}, config)
            .then(({ data }: any) => {
                const list = onFindAllResponseJson<E>(data);
                return Promise.resolve(
                    new FinalResponse<ListResponse<E>>(list)
                );
            })
            .catch((error: AxiosError | ServerError) => {
                const { message } = error;
                return Promise.resolve(new FinalResponse(null, message));
            });
    }

    static async SearchQuestionList<E>(
        config: AxiosRequestConfig = {},
        params: RequestParamsType = {}
    ) {
        return this.makeGet<E>(API_SEARCH, params, config)
            .then(({ data }: any) => {
                const list = onFindAllResponseJson<E>(data);
                return Promise.resolve(
                    new FinalResponse<ListResponse<E>>(list)
                );
            })
            .catch((error: AxiosError | ServerError) => {
                const { message } = error;
                return Promise.resolve(new FinalResponse(null, message));
            });
    }

    static async FilterQuestionList(
        data: FilterPayload,
        config: AxiosRequestConfig = {},
        params: RequestParamsType = {}
    ): Promise<QuestionResponse> {
        const res: AxiosResponse<QuestionResponse> = await this.makePost<
            QuestionResponse,
            FilterPayload
        >(API_FILTER, data, params, config);
        return res?.data;
    }
}
