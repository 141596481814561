import React, { createContext } from "react";
import { AuthApi, LoginResponse } from "../apis/AuthApi";
import {
    AUTH_TOKEN_KEY,
    AUTH_USER_KEY,
    REFRESH_TOKEN_KEY,
} from "../../AppModule/config/app-env";
import { AuthState } from "../models/context/AuthState";
import { clearAuthStorage } from "../utils";
import { QuestionApi } from "../../AppModule/apis/QuestionAPI";

interface IAuthAction {
    type: AuthActionTypes;
    payload: AuthState;
}

const initialState: AuthState = {
    isAuthenticated: null,
    token: null,
    role: null,
};

enum AuthActionTypes {
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    LOGOUT,
}
export const AuthContext = createContext<AuthState | any>(initialState);

export const buildConfig = (authToken: string) => {
    return {
        headers: {
            Authorization: `Bearer ${authToken}`,
            "ngrok-skip-browser-warning": "true",
        },
    };
};

function reducer(state: AuthState, action: IAuthAction): AuthState {
    switch (action.type) {
        case AuthActionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                isAuthenticated: action.payload.isAuthenticated,
                token: action.payload.token,
                role: action.payload.role,
            };
        case AuthActionTypes.LOGIN_ERROR:
            return {
                ...state,
                isAuthenticated: action.payload.isAuthenticated,
                token: action.payload.token,
                role: action.payload.role,
            };
        case AuthActionTypes.LOGOUT:
            return {
                ...state,
                isAuthenticated: action.payload.isAuthenticated,
                token: action.payload.token,
                role: action.payload.role,
            };
        default:
            return state;
    }
}

type Props = {
    children: JSX.Element;
};

export const logoutAction = async (
    dispatch: React.Dispatch<IAuthAction>
): Promise<void> => {
    await clearAuthStorage();
    dispatch({
        type: AuthActionTypes.LOGOUT,
        payload: {
            token: null,
            isAuthenticated: false,
            role: null,
        },
    });
    window.location.href = "/auth/login";
};

export interface JWT {
    ip: string | null;
    roles: string[];
    cid: number;
    cntid: number;
}

export const loginFailureAction = async (
    dispatch: React.Dispatch<IAuthAction>
) => {
    await clearAuthStorage();
    dispatch({
        type: AuthActionTypes.LOGIN_ERROR,
        payload: {
            isAuthenticated: false,
            token: null,
            role: null,
        },
    });
};

export const loginAction = async (
    username: string,
    password: string,
    dispatch: React.Dispatch<IAuthAction>,
    role: string,
    examId: Array<string>,
    userName: string
): Promise<any> => {
    try {
        const result: LoginResponse = await AuthApi.login({
            email: username,
            password,
        });
        const { token, refreshToken } = result;
        if (token) {
            // const user = await AuthApi.me(buildConfig(token));
            localStorage.setItem(AUTH_TOKEN_KEY, token);
            localStorage.setItem("ep_role", role);
            localStorage.setItem("ep_isAuthenticated", "true");
            localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
            localStorage.setItem(AUTH_USER_KEY, userName);
            await AuthApi.estList(buildConfig(token || ({} as any))).then(
                (response: any) => {
                    if (response.response !== null) {
                        localStorage.setItem(
                            "ep_est",
                            JSON.stringify(response)
                        );
                    }
                }
            );
            await QuestionApi.TagList(buildConfig(token || ({} as any))).then(
                (response: any) => {
                    if (response.response !== null) {
                        const res = response.response;
                        const tags = res?.items?.map((tag) => {
                            return { label: tag.name, value: tag._id };
                        });
                        localStorage.setItem("ep_tags", JSON.stringify(tags));
                    }
                }
            );
            dispatch({
                type: AuthActionTypes.LOGIN_SUCCESS,
                payload: {
                    isAuthenticated: true,
                    token,
                    role: role,
                },
            });
        }
        return token;
    } catch (err) {
        await loginFailureAction(dispatch);
        return Promise.reject(err);
    }
};

export default function AuthProvider({ children }: Props): JSX.Element {
    const [state, dispatch] = React.useReducer(reducer, initialState);

    // const fetchSession = async () => {
    //     const tok = localStorage.getItem(AUTH_TOKEN_KEY);

    //     if (tok) {
    //         await AuthApi.me(buildConfig(tok))
    //             .then((res) => {
    //                 const { token, refreshToken } = res;
    //                 localStorage.setItem(AUTH_TOKEN_KEY, token);
    //                 localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
    //                 localStorage.setItem(AUTH_USER_KEY, JSON.stringify(null));

    //                 dispatch({
    //                     type: AuthActionTypes.LOGIN_SUCCESS,
    //                     payload: {
    //                         isAuthenticated: true,
    //                         token,
    //                         role: state.role,
    //                     },
    //                 });
    //             })
    //             .catch((error: any) => {
    //                 if (error.code === 403) {
    //                     logoutAction(dispatch);
    //                 } else {
    //                     errorToast(error.errorMessage);
    //                 }
    //             });
    //     } else {
    //         if (window.location.pathname !== "/auth/login")
    //             window.location.href = "/auth/login";
    //         dispatch({
    //             type: AuthActionTypes.LOGIN_SUCCESS,
    //             payload: {
    //                 isAuthenticated: false,
    //                 token: null,
    //                 role: null,
    //             },
    //         });
    //     }
    // };

    // useEffect(() => {
    //     fetchSession().then();
    // }, []);

    return (
        <AuthContext.Provider value={{ state, dispatch }}>
            {children}
        </AuthContext.Provider>
    );
}
