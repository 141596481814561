import { Col, Row } from "react-bootstrap";
import { Fragment } from "react/jsx-runtime";
import { PrimitiveObject } from "../../models";
import { AppQuestionListItem } from "../../containers/AppQuestionListItem/AppQuestionListItem";
import { AppScrollButton } from "../../components/AppScrollButton";

interface PrepPreviewListProps {
    setSelectedQuestion: (selectedQuestion) => void;
    selectedQuestion: Array<any>;
    selectedQuestionList: Array<string>;
    setSelectedQuestionList: (selectedQuestionList) => void;
    isReadOnly: boolean;
    setSubjectList: (subjectList) => void;
    setDifficulty: (difficulty) => void;
    subjectList: Array<PrimitiveObject>;
}

export const PrepPreviewList = ({
    isReadOnly,
    selectedQuestionList,
    setSelectedQuestionList,
    selectedQuestion,
    setSelectedQuestion,
    setDifficulty,
    setSubjectList,
    subjectList,
}: PrepPreviewListProps) => {
    const handleTopic = (id: number, QAdded: boolean) => {
        const currentQuestion = selectedQuestion?.filter(
            (Que) => Que._id === id
        )?.[0];

        let tempcat = selectedQuestion;
        if (!QAdded) {
            tempcat = tempcat?.filter((q) => q._id !== id);
            if (
                subjectList?.filter(
                    (to) => to?.value === currentQuestion.subject?._id
                )?.[0]?.noOfQuestion > 1
            ) {
                const updatedTopic = subjectList?.map((topic) =>
                    topic?.value === currentQuestion.subject?._id
                        ? {
                              ...topic,
                              noOfQuestion: topic?.noOfQuestion - 1,
                          }
                        : topic
                );
                setSubjectList(updatedTopic);
            } else {
                const updatedTopic = subjectList?.filter(
                    (topic) => topic?.value !== currentQuestion.subject?._id
                );
                setSubjectList(updatedTopic);
            }
            setSelectedQuestion([...tempcat]);
        }
    };
    const handleLevel = (tempcat) => {
        let totalWeightage = 0;

        tempcat?.map((Que) => {
            totalWeightage = tempcat.includes(Que._id)
                ? Que.difficultyLevel === "Easy"
                    ? totalWeightage + 1
                    : Que.difficultyLevel === "Medium"
                      ? totalWeightage + 2
                      : Que.difficultyLevel === "Hard"
                        ? totalWeightage + 3
                        : totalWeightage + 0
                : totalWeightage + 0;
        });
        const level =
            selectedQuestionList?.length > 1
                ? Math.round(totalWeightage / selectedQuestionList?.length)
                : totalWeightage;

        if (tempcat?.length > 0) {
            setDifficulty(
                level === 1
                    ? "Easy"
                    : level === 2
                      ? "Medium"
                      : level === 3
                        ? "Hard"
                        : ""
            );
        } else {
            setDifficulty("");
        }
    };
    const handleDelete = (id) => {
        const currentQuestion = selectedQuestion?.filter(
            (Que) => Que._id === id
        )?.[0];
        let tempSelectedQuestion = selectedQuestion;
        if (id)
            tempSelectedQuestion = tempSelectedQuestion?.filter(
                (Que) => Que._id !== currentQuestion._id
            );
        let tempcat = selectedQuestionList;

        if (id) {
            tempcat = tempcat?.filter((q) => q !== id);
            handleLevel(tempcat);
            handleTopic(id, false);
        }
        setSelectedQuestionList([...tempcat]);
        setSelectedQuestion([...tempSelectedQuestion]);
    };
    return (
        <Fragment>
            <div className="d-block mb-2">
                <h4 className="pt-3">Preview Selected Questions</h4>
            </div>
            <Row>
                <Col>
                    {selectedQuestion?.map((question) => {
                        return (
                            <AppQuestionListItem
                                showActions={isReadOnly ? false : true}
                                isPrepPreviewList={isReadOnly ? false : true}
                                question={question}
                                handleDelete={handleDelete}
                            />
                        );
                    })}
                </Col>
            </Row>
            <AppScrollButton scrolTo={400} />
        </Fragment>
    );
};
