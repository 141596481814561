import React, { FC, useState } from "react";
import { Form } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { useGridHelper, useInputPlaceholder } from "../../hooks";
import { AppFormLabel } from "../AppFormLabel";
import {
    AppFormLayoutProps,
    AppReactHookFormProps,
    AppFormElementProps,
} from "../../models/components";

import "./assets/scss/style.scss";
import { AppButton } from "../AppButton";

export interface AppFormRangeProps
    extends AppFormElementProps,
        AppFormLayoutProps,
        AppReactHookFormProps {
    defaultValue?: number;
    type?: string;
    value?: number;
    maxCount?: number;
    gridOff?: boolean;
    inputIcon?: string;
    disableMinusOn?: boolean;
    disablePlusOn?: boolean;
    onBlurHandler?: (value: React.FocusEvent<HTMLInputElement>) => void;
    onChange?: (value: string) => void;
}

export const AppFormRange: FC<AppFormRangeProps> = ({
    id,
    name,
    defaultValue = 0,
    placeholder,
    errorMessage,
    label = "",
    type = "number",
    description,
    isInvalid,
    isValid,
    required = true,
    gridOff = false,
    maxCount,
    control,
    value,
    onBlurHandler,
    onChange,
    block,
    readOnly,
    disabled,
    inputIcon,
    disablePlusOn,
    disableMinusOn,
    ...props
}): JSX.Element => {
    const [data, setData] = useState<number>(defaultValue as number);
    const placeholderText = useInputPlaceholder(name, placeholder, label);
    const controlId = id || name;
    const {
        sm = 12,
        md = block ? 12 : 6,
        lg = block ? 12 : 4,
        xl = block ? 12 : 4,
        className = "",
    } = props;

    const labelProps = { label, required, maxCount, description };
    const controllerProps = { name, defaultValue, control };
    const controlProps = {
        placeholder: placeholderText,
        isValid,
        isInvalid,
        type,
        readOnly,
        disabled,
    };
    const { getColumnClasses } = useGridHelper();
    const colClasses = gridOff ? "" : getColumnClasses(sm, md, lg, xl);

    return (
        <Form.Group
            className={`col form-group  mb-0 ${colClasses} ${className}`}
            controlId={controlId}
        >
            <AppFormLabel {...labelProps} />
            {inputIcon && <span className={inputIcon}></span>}
            <Controller
                {...controllerProps}
                render={({ field }) => (
                    <div className={"d-flex"}>
                        <AppButton
                            {...field}
                            className={"range-increment-btn"}
                            onClick={() => {
                                setData(data + 0.25);
                                field.onChange(data + 0.25);
                            }}
                            disabled={disablePlusOn ? data >= 0 : false}
                        >
                            +
                        </AppButton>
                        <Form.Control
                            {...field}
                            value={data}
                            onChange={(e) => {
                                field.onChange(e);
                            }}
                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                                if (onBlurHandler) onBlurHandler(e);
                            }}
                            {...controlProps}
                        />
                        <AppButton
                            className={"range-decrement-btn"}
                            {...field}
                            onClick={() => {
                                setData(data - 0.25);
                                field.onChange(data - 0.25);
                            }}
                            disabled={disableMinusOn ? data <= 1 : false}
                        >
                            -
                        </AppButton>
                    </div>
                )}
            />

            <Form.Control.Feedback className={"d-block"} type="invalid">
                {errorMessage}
            </Form.Control.Feedback>
        </Form.Group>
    );
};
