import { default as Axios, AxiosRequestConfig, AxiosInstance } from "axios";
import QueryString from "qs";
import { API_HOST, ACCEPTABLE_RESPONSE } from "./app-env";
import {
    onRequestFulfilled,
    onRequestRejected,
    onResponseFulfilled,
    onResponseRejected,
} from "../apis/interceptors";
import { AcceptableResponse } from "../models";

const defaultConfig: AxiosRequestConfig = {
    baseURL: API_HOST,
    timeout: 1000 * 300,
    headers: {
        accept: AcceptableResponse.header(ACCEPTABLE_RESPONSE),
    },
    paramsSerializer: (params) => {
        return QueryString.stringify(params, { arrayFormat: "comma" });
    },
};

if (defaultConfig.baseURL) Axios.defaults.baseURL = defaultConfig.baseURL;
if (defaultConfig.timeout) Axios.defaults.timeout = defaultConfig.timeout;
if (defaultConfig.headers?.accept)
    Axios.defaults.headers.accept = defaultConfig.headers.accept;
if (defaultConfig.paramsSerializer)
    Axios.defaults.paramsSerializer = defaultConfig.paramsSerializer;

export let axios: AxiosInstance;

export const createAxios = (
    configuration: AxiosRequestConfig
): AxiosInstance => {
    return Axios.create(configuration);
};

export const init = (): void => {
    axios = createAxios(defaultConfig);
    axios.interceptors.request.use(
        onRequestFulfilled as any,
        onRequestRejected
    );
    axios.interceptors.response.use(onResponseFulfilled, onResponseRejected);
};
