import { useState } from "react";
import { PrimitiveObject, SimpleObject } from "../models";
import { useParams } from "react-router-dom";

type EntityAddEditType<E> = {
    id: string | undefined;
    isEditMode: boolean;
    setIsAdmin: (isAdmin: boolean) => void;
    isAdmin: boolean;
    isLoading: boolean;
    data: E;
    setIsLoading: (status: boolean) => void;
    setData: (data: E) => void;
    transOutput: <T>(
        fields: string[],
        formTranslations: PrimitiveObject
    ) => T[];
    transInput: <T>(
        fields: string[],
        dbTranslations: T[]
    ) => SimpleObject<PrimitiveObject>;
};

export function useEntityAddEdit<E>(initData: E): EntityAddEditType<E> {
    const { id } = useParams();
    const isEditMode: boolean = id !== null && id !== undefined;
    const [data, setData] = useState<E>(initData);
    const [isAdmin, setIsAdmin] = useState(true);
    const [isLoading, setIsLoading] = useState<boolean>(isEditMode);

    const transOutput = <T>() => {
        const translations: T[] = [];
        return translations;
    };

    const transInput = () => {
        const translations: SimpleObject<PrimitiveObject> = {};

        return translations;
    };

    return {
        id,
        isEditMode,
        setIsAdmin,
        isAdmin,
        data,
        setData,
        isLoading,
        setIsLoading,
        transOutput,
        transInput,
    };
}
