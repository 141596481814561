import { AxiosError, AxiosResponse } from "axios";
import { errorToast } from "../../utils";
import { clearAuthStorage } from "../../../AuthModule/utils";

export const onResponseFulfilled = (response: AxiosResponse): AxiosResponse => {
    return response;
};

export const onResponseRejected = (error: AxiosError | any): Promise<any> => {
    const status = error?.response?.status;
    const data = error?.response?.data || error;

    if (status) {
        if (status === 403) {
            clearAuthStorage().then(() => {
                window.location.href = "/auth/login";
            });
        }
    } else if (!!error?.message) {
        errorToast(error?.message);
    }

    return Promise.reject(data);
};
