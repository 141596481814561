import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

import {
    FinalResponse,
    ListResponse,
    ServerError,
} from "../../AppModule/models";
import { EntityAPI } from "../../AppModule/apis/EntityAPI";
import { onFindAllResponseJson } from "../../AppModule/apis/transformer";
import { ROUTES } from "../../config";

const { api_subjects: API_SUBJECTS } = ROUTES;

export interface SubjectResponse {
    success: boolean;
    message: string;
    subjectData: {
        subject: string;
        admin: string;
    };
}

export interface SubjectPayload {
    subject: string;
    admin: string;
}

export class SubjectApi extends EntityAPI {
    static async AddSubject(
        data: SubjectPayload,
        config: AxiosRequestConfig = {}
    ): Promise<SubjectResponse> {
        const res: AxiosResponse<SubjectResponse> = await this.makePost<
            SubjectResponse,
            SubjectPayload
        >(API_SUBJECTS, data, {}, config);
        return res?.data;
    }

    static async UpdateSubject(
        data: SubjectPayload,
        id: string,
        config: AxiosRequestConfig = {}
    ): Promise<SubjectResponse> {
        const res: AxiosResponse<SubjectResponse> = await this.makePut<
            SubjectResponse,
            SubjectPayload
        >(`${API_SUBJECTS}/${id}`, data, {}, config);
        return res?.data;
    }

    public static async deleteSuject(
        id: number,
        config: AxiosRequestConfig = {}
    ): Promise<any> {
        return this.makeDelete(`${API_SUBJECTS}/${id}`, config)
            .then(({ data }) => {
                return data;
            })
            .catch((error: AxiosError | ServerError) => {
                const { message } = error;
                return Promise.resolve(new FinalResponse(null, message));
            });
    }

    static async SubjectList<E>(config: AxiosRequestConfig = {}) {
        return this.makeGet<E>(API_SUBJECTS, {}, config)
            .then(({ data }: any) => {
                const list = onFindAllResponseJson<E>(data);
                return Promise.resolve(
                    new FinalResponse<ListResponse<E>>(list)
                );
            })
            .catch((error: AxiosError | ServerError) => {
                const { message } = error;
                return Promise.resolve(new FinalResponse(null, message));
            });
    }
}
