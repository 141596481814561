import { Col } from "react-bootstrap";
import { AppCard } from "../AppCard";
import { AppRightSideDrawer } from "../AppRightSideDrawer";
import "./assets/style.scss";
import { Fragment, useEffect, useState } from "react";
import { AppFormLabel } from "../AppFormLabel";
import { useTranslation } from "react-i18next";
import { AppButton } from "../AppButton";
import { AppliedFilter } from "../../containers/AppGrid/AppGrid";
import { SimpleObject } from "../../models";
import { AppFormMultiSelectCreatable } from "../AppFormSelectCreatable";

interface FilterDrawrProps {
    showFilter: boolean;
    setShowFilter: (showFilter: boolean) => void;
    handleFilter: () => void;
    setAppliedFilter: (appliedFilter: any) => void;
    appliedFilter: AppliedFilter;
    isPrepPage?: boolean;
}
interface TagType {
    label: string;
    value: string;
}
export const FilterDrawer = ({
    setShowFilter,
    showFilter,
    setAppliedFilter,
    appliedFilter,
    isPrepPage = false,
}: FilterDrawrProps) => {
    const { t } = useTranslation();

    const categories = ["Easy", "Medium", "Hard"];
    const QuestionTypes = [
        { label: "Single", value: false },
        { label: "Multiselect", value: true },
    ];
    const [tags, setTags] = useState([]);
    const [topics, setTopics] = useState<Array<TagType>>([]);
    const [subjectList, setSubjectList] = useState<Array<TagType>>([]);
    const [examList, setExamList] = useState<Array<TagType>>([]);
    const [activeCat, setActiveCat] = useState<Array<string>>(
        [...appliedFilter.categoryFilter] || []
    );
    const [activeType, setActiveType] = useState<Array<boolean>>(
        [...appliedFilter.typeFilter] || []
    );
    const [activeTag, setActivTag] = useState<Array<TagType>>([]);
    const [activeTopic, setActivTopic] = useState<Array<TagType>>([]);
    const [activeExam, setActivExam] = useState<Array<string>>(
        [...appliedFilter.examFilter] || []
    );
    const [activeSubject, setActivSubject] = useState<Array<string>>(
        [...appliedFilter?.subjectFilter] || []
    );

    const [estData, setEstData] = useState<SimpleObject<Array<any>>>({});
    const getSuejcts = () => {
        const subjectlist = subjectList?.map((sub) => sub?.value);
        if (activeExam?.length) {
            return activeSubject?.filter((as) => subjectlist.includes(as));
        } else {
            return activeSubject;
        }
    };
    useEffect(() => {
        setActivSubject([]);
        const seId = activeExam;

        if (seId?.length > 0) {
            const items = estData?.subjects?.filter((sub) =>
                seId?.every((eid) => sub.examId.includes(eid))
            );
            if (items?.length > 0) {
                setSubjectList(
                    items?.map((item) => {
                        return { label: item.name, value: item._id };
                    })
                );
            } else {
                setSubjectList([]);
            }
        } else {
            const filteredSubjectList = estData?.subjects?.filter((sub) =>
                examList?.some((eid) => sub.examId.includes(eid.value))
            );

            setSubjectList(
                filteredSubjectList?.map((sub) => {
                    return { label: sub.name, value: sub._id };
                })
            );
        }
        setActivSubject([...appliedFilter?.subjectFilter]);
    }, [activeExam, estData]);

    useEffect(() => {
        let items;
        if (activeSubject?.length > 0) {
            items =
                activeSubject?.length > 1
                    ? []
                    : estData?.topics?.filter(
                          (topic) => topic.subjectId === activeSubject?.[0]
                      );
            setActivTopic([]);

            if (activeSubject?.length) {
                if (items?.length > 0 && !(activeSubject?.length > 1)) {
                    setTopics(
                        items?.map((item) => {
                            return { label: item.name, value: item._id };
                        })
                    );
                } else {
                    setTopics([]);
                }
            } else {
                setTopics(
                    estData.topics?.map((topic) => {
                        return { label: topic.name, value: topic._id };
                    })
                );
            }
        }
        if (appliedFilter.topic?.length) {
            const defaultActiveTopic = estData.topics?.filter((tag) =>
                appliedFilter.topic.includes(tag._id)
            );
            if (items?.length) {
                const filteredTopic = items?.map((item) => item._id);
                const aTopic = defaultActiveTopic?.filter((dat) =>
                    filteredTopic.includes(dat._id)
                );
                setActivTopic(
                    aTopic?.map((at) => {
                        return { label: at.name, value: at._id };
                    })
                );
            } else if (!activeSubject?.length) {
                setActivTopic(
                    defaultActiveTopic?.map((tag) => {
                        return {
                            label: tag.name,
                            value: tag._id,
                        };
                    })
                );
            }
        }
    }, [activeSubject, estData]);

    useEffect(() => {
        const est = localStorage.getItem("ep_est");
        const allTags = JSON.parse(localStorage.getItem("ep_tags") || "");
        if (allTags) {
            setTags(allTags);
            if (appliedFilter.tagFilter?.length) {
                const defaultActiveTag = allTags?.filter((tag) =>
                    appliedFilter.tagFilter.includes(tag.value)
                );
                setActivTag(
                    defaultActiveTag?.map((tag) => {
                        return {
                            label: tag.label,
                            value: tag.value,
                        };
                    })
                );
            }
        }
        if (est) {
            const estArray = JSON.parse(est || "");

            setEstData(estArray);
            setExamList(
                estArray?.exams?.map((exam) => {
                    return { label: exam.name, value: exam._id };
                })
            );
            const filteredSubjectList = estArray?.subjects?.filter((sub) =>
                estArray?.exams?.some((eid) => sub.examId.includes(eid._id))
            );
            const filteredSubjectListId = filteredSubjectList?.map(
                (fsl) => fsl._id
            );
            setSubjectList(
                filteredSubjectList?.map((sub) => {
                    return { label: sub.name, value: sub._id };
                })
            );
            const filteredTopicList = estArray?.topics?.filter((topic) => {
                return filteredSubjectListId?.includes(topic.subjectId);
            });
            setTopics(
                filteredTopicList?.map((ftl) => {
                    return { label: ftl.name, value: ftl._id };
                })
            );
        }
    }, []);

    return (
        <AppRightSideDrawer
            title="Filter Questioins"
            handleClose={() => {
                setShowFilter(false);
            }}
            show={showFilter}
            bodyContent={
                <Fragment>
                    <AppCard className="filter-cate-type-wrapper mt-2 p-4">
                        <Col md={6} className="px-0">
                            <AppFormLabel
                                label={t("common.label.category")}
                                required={false}
                            />
                            <Col xs={12} className="d-flex mt-2 flex-wrap">
                                {categories?.map((e) => {
                                    return (
                                        <div
                                            key={e}
                                            className={`lang-item f16 me-2 mb-2 ${
                                                activeCat?.includes(e) &&
                                                "active"
                                            }`}
                                            onClick={() => {
                                                const tempcat = activeCat;
                                                const index =
                                                    tempcat.indexOf(e);
                                                if (index != -1) {
                                                    tempcat.splice(index, 1);
                                                } else {
                                                    tempcat.push(e);
                                                }
                                                return setActiveCat([
                                                    ...tempcat,
                                                ]);
                                            }}
                                        >
                                            {e}
                                        </div>
                                    );
                                })}
                            </Col>
                        </Col>
                        <Col md={6} className="px-0">
                            <AppFormLabel
                                label={t("common.label.type")}
                                required={false}
                            />
                            <Col xs={12} className="d-flex mt-2 flex-wrap">
                                {QuestionTypes?.map((e) => {
                                    return (
                                        <div
                                            key={e.label}
                                            className={`lang-item f16 me-2 mb-2 ${
                                                activeType?.includes(
                                                    e?.value
                                                ) && "active"
                                            }`}
                                            onClick={() => {
                                                const tempcat = activeType;
                                                const index = tempcat.indexOf(
                                                    e?.value
                                                );
                                                if (index != -1) {
                                                    tempcat.splice(index, 1);
                                                } else {
                                                    tempcat.push(e?.value);
                                                }
                                                return setActiveType([
                                                    ...tempcat,
                                                ]);
                                            }}
                                        >
                                            {e.label}
                                        </div>
                                    );
                                })}
                            </Col>
                        </Col>
                    </AppCard>

                    {!isPrepPage && examList?.length > 1 && (
                        <AppCard>
                            <Col md={12} className="px-0 mt-2">
                                <AppFormLabel
                                    label={t("common.label.exam")}
                                    required={false}
                                />
                                <Col xs={12} className="d-flex mt-2 flex-wrap">
                                    {examList?.map((e: TagType) => {
                                        return (
                                            <div
                                                key={e?.value}
                                                className={`lang-item f16 me-2 mb-2 ${
                                                    activeExam?.includes(
                                                        e?.value
                                                    ) && "active"
                                                }`}
                                                onClick={() => {
                                                    const tempcat = activeExam;
                                                    const index =
                                                        tempcat.indexOf(
                                                            e?.value
                                                        );
                                                    if (index != -1) {
                                                        tempcat.splice(
                                                            index,
                                                            1
                                                        );
                                                    } else {
                                                        tempcat.push(e?.value);
                                                    }
                                                    return setActivExam([
                                                        ...tempcat,
                                                    ]);
                                                }}
                                            >
                                                {e.label}
                                            </div>
                                        );
                                    })}
                                </Col>
                            </Col>
                        </AppCard>
                    )}
                    <AppCard>
                        <Col md={12} className="px-0 mt-2">
                            <AppFormLabel
                                label={t("common.label.subject")}
                                required={false}
                            />
                            <Col xs={12} className="d-flex mt-2 flex-wrap">
                                {subjectList?.map((e: TagType) => {
                                    return (
                                        <div
                                            key={e?.value}
                                            className={`lang-item f16 me-2 mb-2 ${
                                                activeSubject?.includes(
                                                    e?.value
                                                ) && "active"
                                            }`}
                                            onClick={() => {
                                                const tempcat = activeSubject;
                                                const index = tempcat.indexOf(
                                                    e?.value
                                                );
                                                if (index != -1) {
                                                    tempcat.splice(index, 1);
                                                } else {
                                                    tempcat.push(e?.value);
                                                }
                                                return setActivSubject([
                                                    ...tempcat,
                                                ]);
                                            }}
                                        >
                                            {e.label}
                                        </div>
                                    );
                                })}
                            </Col>
                        </Col>
                        {!!activeSubject?.length ? (
                            <Col className="d-flex flex-wrap mb-10 mt-3">
                                {
                                    <AppFormMultiSelectCreatable
                                        id={"topics"}
                                        name={"topics"}
                                        md={12}
                                        xl={12}
                                        lg={12}
                                        label={t("common.label.topic")}
                                        className="role-container "
                                        onChangeHandler={setActivTopic}
                                        isValidNewOption={false}
                                        value={activeTopic}
                                        placeholder={"Select Topic"}
                                        // errorMessage={errors.role?.message}
                                        options={topics}
                                    />
                                }
                            </Col>
                        ) : (
                            <></>
                        )}
                    </AppCard>
                    <AppCard>
                        <Col className="d-flex mt-2 flex-wrap">
                            {
                                <AppFormMultiSelectCreatable
                                    id={"tags"}
                                    name={"tags"}
                                    label={t("common.label.tag")}
                                    className="role-container"
                                    md={12}
                                    xl={12}
                                    lg={12}
                                    onChangeHandler={setActivTag}
                                    isValidNewOption={false}
                                    value={activeTag}
                                    placeholder={"Select Tag"}
                                    // errorMessage={errors.role?.message}
                                    options={tags}
                                />
                            }
                        </Col>
                    </AppCard>
                    <div className="d-flex justify-content-end w-100">
                        <AppButton
                            className="me-2"
                            variant="secondary"
                            onClick={() => {
                                setAppliedFilter({
                                    categoryFilter: [],
                                    typeFilter: [],
                                    tagFilter: [],
                                    topic: [],
                                    subjectFilter: [],
                                    examFilter: [],
                                });

                                setShowFilter(false);
                            }}
                        >
                            {t("common.button.clear")}
                        </AppButton>
                        <div style={{ height: "300px" }}></div>
                        <AppButton
                            type="submit"
                            onClick={() => {
                                setAppliedFilter({
                                    categoryFilter: activeCat,
                                    typeFilter: activeType,
                                    tagFilter: activeTag?.map(
                                        (tag) => tag?.value
                                    ),
                                    topic: activeTopic?.map(
                                        (topic) => topic?.value
                                    ),
                                    subjectFilter: getSuejcts(),
                                    examFilter: activeExam,
                                });
                                setShowFilter(false);
                            }}
                        >
                            {t("common.button.save")}
                        </AppButton>
                    </div>
                </Fragment>
            }
        />
    );
};
