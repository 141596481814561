import { ColDef } from "ag-grid-community/dist/lib/entities/colDef";
import { appGridFrameworkComponents } from "./app-grid-framework-components";

let getWidth = "right";
if (window.innerWidth < 760) {
    getWidth = "";
}
export const appGridColDef = (): ColDef[] => [
    {
        headerName: "subject",
        field: "subject",
        filter: "text",
        minWidth: 220,
        flex: 1.5,
        sortable: true,
    },
    {
        headerName: "Admin",
        field: "admin",
        filter: "text",
        cellRenderer: appGridFrameworkComponents.appNameTemplateRenderer as any,
        sortable: true,
        minWidth: 210,
        flex: 1,
    },
    {
        headerName: "question.users.list.column.actions",
        field: "_id",
        sortable: false,
        minWidth: 150,
        maxWidth: 150,
        cellClass: "text-right justify-content-end",
        headerClass: "action-header",
        cellRenderer: appGridFrameworkComponents.AppQueActionRenderer as any,
        lockPinned: true,
        pinned: getWidth,
    },
];
