import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { EntityAPI, onFindAllResponseJson } from "../../AppModule/apis";
import {
    FinalResponse,
    ListResponse,
    ServerError,
} from "../../AppModule/models";
import { ROUTES } from "../../config";
import { User } from "../models";

const { api_users_email_exist_collection: EMAIL_EXIST, api_users: API_USERS } =
    ROUTES;

export abstract class UserApi extends EntityAPI {
    public static async emailExist<R, P>(
        entity: P
    ): Promise<FinalResponse<R | null>> {
        return this.makePost<R, P>(EMAIL_EXIST, entity)
            .then(({ data }) => {
                return Promise.resolve(new FinalResponse<R>(data, null));
            })
            .catch((error: AxiosError | ServerError) => {
                const { message } = error;
                return Promise.resolve(new FinalResponse<R>(null, message));
            });
    }

    static async UserList<E>(config: AxiosRequestConfig = {}) {
        return this.makeGet<E>(API_USERS, {}, config)
            .then(({ data }: any) => {
                const list = onFindAllResponseJson<E>(data);
                return Promise.resolve(
                    new FinalResponse<ListResponse<E>>(list)
                );
            })
            .catch((error: AxiosError | ServerError) => {
                const { message } = error;
                return Promise.resolve(new FinalResponse(null, message));
            });
    }

    static async AddUser(data, config: AxiosRequestConfig = {}): Promise<User> {
        const res: AxiosResponse<User> = await this.makePost<User, any>(
            API_USERS,
            data,
            {},
            config
        );
        return res?.data;
    }
}
