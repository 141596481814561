import { ColDef } from "ag-grid-community/dist/lib/entities/colDef";
import { AppCellActionParamsUserList } from "./AppCellActionParamsUserList";
import { appGridFrameworkComponents } from "./app-grid-framework-components";

let getWidth = "right";
if (window.innerWidth < 760) {
    getWidth = "";
}
export const appGridColDef = ({
    onPressDelete,
    onSelectChange,
}: AppCellActionParamsUserList): ColDef[] => [
    {
        headerName: "admin.users.list.column.Name",
        field: "userName",
        filter: "text",
        minWidth: 220,
        flex: 1,
        sortable: true,
    },
    {
        headerName: "admin.users.list.column.email",
        field: "email",
        filter: "text",
        sortable: true,
        flex: 1,
    },
    {
        headerName: "admin.users.list.column.role",
        field: "role",
        cellRenderer: appGridFrameworkComponents.appSelect as any,
        minWidth: 150,
        maxWidth: 150,
        flex: 0.5,
        sortable: true,
        cellRendererParams: {
            onSelectChange,
        },
    },
    {
        headerName: "admin.users.list.column.actions",
        field: "id",
        sortable: false,
        minWidth: 150,
        maxWidth: 150,
        flex: 0.5,
        cellClass: "text-right justify-content-center p-0",
        headerClass: "action-header",
        cellRenderer: appGridFrameworkComponents.AppGridActionRenderer as any,
        cellRendererParams: {
            onPressDelete,
        },
        lockPinned: true,
        pinned: getWidth,
    },
];
