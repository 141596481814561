export abstract class AcceptableResponse {
    public static JSON = "JSON";

    public static REQUEST_ACCEPT_HEADERS = {
        [AcceptableResponse.JSON]: "application/json",
    };

    public static header = (type: string): string => {
        if (!AcceptableResponse.REQUEST_ACCEPT_HEADERS[type]) {
            throw new Error(
                "Unknown response type passed, that is not acceptable."
            );
        }
        return AcceptableResponse.REQUEST_ACCEPT_HEADERS[type];
    };
}
