import React from "react";
import { RecoilRoot } from "recoil";
import ReactDOM from "react-dom/client";
import App from "./App";
import { GlobalProvider } from "./AppModule/contexts";
import AuthProvider from "./AuthModule/contexts/AuthContext";
import { axios } from "./AppModule/config";
import "./AppModule/config/i18n";
import "./index.css";
import { AppProvider } from "./AppModule/contexts/AppContext";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    <React.StrictMode>
        <RecoilRoot>
            <AppProvider>
                <GlobalProvider>
                    <AuthProvider>
                        <App />
                    </AuthProvider>
                </GlobalProvider>
            </AppProvider>
        </RecoilRoot>
    </React.StrictMode>
);

axios.init();
