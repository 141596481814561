import * as yup from "yup";

const validations = {
    firstName: {
        min: 2,
    },
    lastName: {
        min: 2,
    },
    plainPassword: {
        min: 6,
    },
};
const authValidationShape = {
    plainPassword: yup.string().required(),
    confirmPassword: yup
        .string()
        .oneOf(
            [yup.ref("plainPassword"), undefined],
            "Passwords must be match"
        ),
};
const schema = (isEditMode: boolean) => {
    let validationShape = {
        firstName: yup.string().nullable(),
        lastName: yup.string().nullable(),
        email: yup.string().email().required(),
        role: yup.string().required("Role is Reqired"),
        plainPassword: yup.string().required(),
        confirmPassword: yup
            .string()
            .oneOf(
                [yup.ref("plainPassword"), undefined],
                "Passwords must be match"
            ),
    };
    if (!isEditMode) {
        validationShape = {
            ...validationShape,
            ...authValidationShape,
        };
    }

    return yup.object().shape(validationShape);
};

const resetPasswordSchema = yup.object().shape({
    plainPassword: yup.string().required(),
    confirmPassword: yup
        .string()
        .oneOf(
            [yup.ref("plainPassword"), undefined],
            "Passwords must be match"
        ),
});

export { schema, validations, resetPasswordSchema };
