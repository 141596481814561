import React, { Fragment, useEffect, useState } from "react";
import {
    AppBreadcrumb,
    AppCard,
    AppFormActions,
    AppFormMultiSelectCreatable,
    AppFormRichTextArea,
    AppFormSelect,
    AppLoader,
    AppPageHeader,
} from "../../components";
import { useTranslation } from "react-i18next";
import { useEntityAddEdit } from "../../hooks";
import { Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { SimpleObject, PrimitiveObject } from "../../models";
import { AppCategorySwitcher } from "../../containers/AppLanguageSwitcher";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "../schema";
import { errorToast, successToast, validation } from "../../utils";
import { Question } from "../../models/entities/Question";
import { QuestionApi, Options } from "../../apis/QuestionAPI";
import { buildConfig } from "../../../AuthModule/contexts";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext, ContainerTypes } from "../../contexts/AppContext";
import { find as _find } from "lodash";

export const QuestionAddEdit = (): JSX.Element => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { isEditMode } = useEntityAddEdit<any>("");
    const [data, setData] = useState<any>();
    const [allTopic, setAllTopic] = useState<Array<any>>([]);
    const [selectedUserTags, setSelectedUserTags] = useState<
        Array<SimpleObject<string>>
    >([]);
    const { state, dispatch } = React.useContext(AppContext);
    const { isLoading, isRefreshLoading } = state;
    const [tags, setTags] = useState([]);
    const [estData, setEstData] = useState<SimpleObject<Array<any>>>({});
    const [selectedExam, setSelectedExam] = useState<any>();
    const [selectedSubject, setSelectedSubject] = useState<any>();
    const [examList, setExamList] = useState<Array<any>>([]);
    const [subjectList, setSubjectList] = useState<Array<any>>([]);
    const [correctAns, setCorrectAns] = useState<Array<boolean>>([
        false,
        false,
        false,
        false,
    ]);
    const navigate = useNavigate();
    const [ActiveCat, setActiveCat] = useState(
        data?.difficultyLevel || "Medium"
    );
    const { control, handleSubmit, formState, setValue, getValues } =
        useForm<Question>({
            resolver: yupResolver<any>(schema(isEditMode)),
            mode: "all",
        });
    const { errors } = formState;

    useEffect(() => {
        const seId = selectedExam?.map((se) => se?.value);
        const items =
            seId?.length &&
            estData?.subjects?.filter((sub) =>
                seId?.every((eid) => sub.examId.includes(eid))
            );

        if (items?.length > 0) {
            if (items?.length === 1) {
                setSelectedSubject({
                    name: items?.[0].name,
                    value: items?.[0]._id,
                });
                setValue("Subject", items?.[0]._id);
            }
            setSubjectList([
                ...items?.map((topics) => {
                    return { label: topics.name, value: topics._id };
                }),
            ]);
        } else {
            setSubjectList([]);
        }
    }, [selectedExam]);

    useEffect(() => {
        const items =
            selectedSubject?.value &&
            estData?.topics?.filter(
                (topic) => topic.subjectId === selectedSubject?.value
            );
        if (items?.length > 0) {
            setAllTopic(
                items?.map((topics) => {
                    return { label: topics.name, value: topics._id };
                })
            );
        } else {
            setAllTopic([]);
        }
    }, [selectedSubject]);

    useEffect(() => {
        const est = localStorage.getItem("ep_est");
        const allTags = JSON.parse(localStorage.getItem("ep_tags") || "");
        if (allTags) {
            setTags(allTags);
        }
        if (est) {
            const estArray = JSON.parse(est || "");

            setEstData(estArray);
            setExamList(
                estArray?.exams?.map((exam) => {
                    return { label: exam.name, value: exam._id };
                })
            );
            if (estArray?.exams?.length === 1) {
                setSelectedExam([
                    {
                        label: estArray?.exams?.[0].name,
                        value: estArray?.exams?.[0]._id,
                    },
                ]);
                if (estArray?.subjects?.length === 1) {
                    setSelectedSubject({
                        name: estArray?.subjects?.[0].name,
                        value: estArray?.subjects?.[0]._id,
                    });
                    setValue("Subject", estArray?.subjects?.[0]._id);
                }
            }
        }
    }, []);
    useEffect(() => {
        const GetData = async () => {
            if (!isRefreshLoading) {
                dispatch({
                    type: ContainerTypes.LOADING,
                });
            }
            await QuestionApi.GetQuestion(
                id as string,
                buildConfig(localStorage?.getItem("ep_token") || ({} as any))
            ).then((response: any) => {
                const res = response.response?.items;
                if (res !== null) {
                    setData({ ...res });
                    setCorrectAns(
                        res?.options?.map(
                            (opt, i) => (correctAns[i] = opt?.isCorrect)
                        )
                    );
                    setSelectedUserTags(
                        res?.tags?.map((tag) => {
                            return { label: tag.name, value: tag._id };
                        })
                    );
                    setSelectedExam(
                        res?.exams?.map((exam) => {
                            return { label: exam.name, value: exam._id };
                        })
                    );
                    setSelectedSubject({
                        name: res?.subject?.name,
                        value: res?.subject?._id,
                    });
                    setValue("Topic", res?.topic?._id);
                    setValue("Subject", res?.subject?._id);
                    setActiveCat(res?.difficultyLevel);
                    dispatch({
                        type: ContainerTypes.LOADED,
                    });
                    dispatch({
                        type: ContainerTypes.REFRESH_LOADED,
                    });
                }
            });
        };
        if (!!id) {
            GetData();
        } else {
            dispatch({
                type: ContainerTypes.LOADED,
            });
            dispatch({
                type: ContainerTypes.REFRESH_LOADED,
            });
        }
    }, [id]);
    const isContentEmpty = (text) => {
        text = text
            .replaceAll("&nbsp;", "")
            .replaceAll(" ", "")
            .replaceAll("<p>", "")
            .replaceAll("</p>", "")
            .trim();

        return text?.length == 0 ? true : false;
    };
    const onSubmit = async (formData: Question) => {
        if (!selectedExam?.length) {
            errorToast("select Exams.");
            return;
        }
        if (
            isContentEmpty(formData.Question?.split(`<p data-f-id="pbf"`)?.[0])
        ) {
            errorToast("Question is required and can't be empty");
            return;
        }
        if (
            isContentEmpty(formData.OptionA?.split(`<p data-f-id="pbf"`)?.[0])
        ) {
            errorToast("Option A is required and can't be empty");
            return;
        }
        if (
            isContentEmpty(formData.OptionB?.split(`<p data-f-id="pbf"`)?.[0])
        ) {
            errorToast("Option B is required and can't be empty");
            return;
        }
        if (
            isContentEmpty(formData.OptionC?.split(`<p data-f-id="pbf"`)?.[0])
        ) {
            errorToast("Question C is required and can't be empty");
            return;
        }
        if (
            isContentEmpty(formData.OptionD?.split(`<p data-f-id="pbf"`)?.[0])
        ) {
            errorToast("Question D is required and can't be empty");
            return;
        }
        if (!correctAns.find((ans) => ans === true)) {
            errorToast("Please select at least one correct answer");
            return;
        }
        const questionData = {
            difficultyLevel: ActiveCat,
            solution: {
                description: isContentEmpty(
                    formData.Solution?.split(`<p data-f-id="pbf"`)?.[0]
                )
                    ? ""
                    : formData.Solution?.split(`<p data-f-id="pbf"`)?.[0], // For remove Froala watermark
            },
            question: {
                description:
                    formData.Question?.split(`<p data-f-id="pbf"`)?.[0], // For remove Froala watermark
            },
            options: [
                {
                    description:
                        formData.OptionA?.split(`<p data-f-id="pbf"`)?.[0], // For remove Froala watermark
                    isCorrect: correctAns?.[0],
                },
                {
                    description:
                        formData.OptionB?.split(`<p data-f-id="pbf"`)?.[0], // For remove Froala watermark
                    isCorrect: correctAns?.[1],
                },
                {
                    description:
                        formData.OptionC?.split(`<p data-f-id="pbf"`)?.[0], // For remove Froala watermark
                    isCorrect: correctAns?.[2],
                },
                {
                    description:
                        formData.OptionD?.split(`<p data-f-id="pbf"`)?.[0], // For remove Froala watermark
                    isCorrect: correctAns?.[3],
                },
            ] as Array<Options>,
            topicId: formData?.Topic,
            isMultiSelect: correctAns?.filter((e) => e === true)?.length > 1,
            tagId: selectedUserTags?.map((tag) => {
                return tag?.value;
            }),
            examId: selectedExam?.map((se) => {
                return se?.value;
            }),
            subjectId: formData?.Subject,
        };
        if (id) {
            await QuestionApi.UpdateQuestion(
                questionData,
                id || "",
                buildConfig(localStorage?.getItem("ep_token") || ({} as any))
            )
                .then((response) => {
                    if (response.success) successToast(response?.message);
                    else errorToast(response?.message);

                    navigate("..");
                })
                .catch((e) => {
                    errorToast(e?.message || "something went  to wrong");
                });
        } else {
            await QuestionApi.AddQuestion(
                questionData,
                buildConfig(localStorage?.getItem("ep_token") || ({} as any))
            )
                .then((response) => {
                    if (response.success) successToast(response?.message);
                    else errorToast(response?.message);
                    navigate("..");
                })
                .catch((e) => {
                    errorToast(e?.message || "something went  to wrong");
                });
        }
    };
    const handleCreateTag = async (value) => {
        if (value.trim()?.length) {
            await QuestionApi.AddTags(
                { name: value },
                buildConfig(localStorage?.getItem("ep_token") || ({} as any))
            )
                .then((response) => {
                    const allTag = response.allTag;
                    const newtag = allTag?.filter(
                        (tag) => tag.name === value
                    )?.[0];
                    const tempTags = selectedUserTags;
                    tempTags?.push({
                        label: newtag.name,
                        value: newtag._id,
                    });
                    setSelectedUserTags(tempTags);
                    setTags(
                        allTag?.map((tag) => {
                            return { label: tag.name, value: tag._id };
                        })
                    );
                    localStorage.setItem(
                        "ep_tags",
                        JSON.stringify(
                            allTag?.map((tag) => {
                                return { label: tag.name, value: tag._id };
                            })
                        )
                    );
                })
                .catch((e) => {
                    errorToast(e.message);
                });
        }
    };

    // const getUserSubject = () => {
    //     if (data) {
    //         const userRole: any = {
    //             value: data.subject?._id,
    //             label: data.subject?.name,
    //         };
    //         return userRole;
    //     }
    //     return {};
    // };

    // const getUserTopic = () => {
    //     if (data) {
    //         const userRole: any = {
    //             value: data.topic?._id,
    //             label: data.topic?.name,
    //         };
    //         return userRole;
    //     }
    //     return {};
    // };

    const handleChangeExam = (e: any) => {
        setSelectedExam(e);
        setSelectedSubject(null);
        setValue("Subject", "");
        setValue("Topic", "");
    };

    const handleChangeSubject = (e: any) => {
        setSelectedSubject(e);
        setValue("Topic", "");
    };

    return (
        <Fragment>
            {isLoading ? (
                <AppLoader coverContainer={true} />
            ) : isRefreshLoading ? (
                <AppLoader />
            ) : null}
            <AppBreadcrumb
                linkText={t("common.breadcrumb.questions")}
                linkUrl={".."}
            />
            <AppPageHeader
                title={
                    isEditMode
                        ? t("question.users.form.header.editTitle")
                        : t("question.users.form.header.title")
                }
            />
            {!isLoading &&
                !isRefreshLoading &&
                (!id || (id && data)) &&
                estData && (
                    <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                        <AppCard>
                            <Col className="d-flex">
                                <AppCategorySwitcher
                                    activeCat={ActiveCat}
                                    onChange={(e) => setActiveCat(e)}
                                />

                                {((examList?.length > 1 &&
                                    id &&
                                    selectedExam) ||
                                    (examList?.length > 1 && !id)) && (
                                    <AppFormMultiSelectCreatable
                                        id={"Exam"}
                                        name={"Exam"}
                                        label={t("admin.prep.form.label.exam")}
                                        className="role-container "
                                        errorMessage={
                                            !selectedExam?.length
                                                ? errors.Exam?.message
                                                : ""
                                        }
                                        md={6}
                                        lg={4}
                                        xl={4}
                                        required={true}
                                        {...validation(
                                            "Exam",
                                            formState,
                                            false
                                        )}
                                        onChangeHandler={handleChangeExam}
                                        isValidNewOption={false}
                                        value={selectedExam}
                                        placeholder={"Exams"}
                                        options={examList}
                                        control={control as any}
                                    />
                                )}
                                {examList?.length == 1 && (
                                    <Col>
                                        <div
                                            style={{
                                                fontWeight: "700",
                                                fontSize: "12px",
                                            }}
                                        >
                                            Exam
                                        </div>
                                        <div className="d-flex">
                                            {selectedExam?.[0].label}
                                        </div>
                                    </Col>
                                )}
                                {subjectList?.length === 1 ? (
                                    <Col
                                        className="me-2 ms-2"
                                        md={6}
                                        lg={4}
                                        xl={4}
                                    >
                                        <div
                                            style={{
                                                fontWeight: "700",
                                                fontSize: "12px",
                                            }}
                                        >
                                            Subject
                                        </div>
                                        <div className="d-flex">
                                            {selectedSubject?.name}
                                        </div>
                                    </Col>
                                ) : (
                                    <AppFormSelect
                                        key={selectedExam}
                                        id={"Subject"}
                                        name={"Subject"}
                                        label={t(
                                            "admin.prep.form.label.subject"
                                        )}
                                        className="role-container me-2 ms-2"
                                        errorMessage={errors.Subject?.message}
                                        md={6}
                                        lg={4}
                                        xl={4}
                                        required={true}
                                        {...validation(
                                            "Subject",
                                            formState,
                                            false
                                        )}
                                        onChangeHandler={handleChangeSubject}
                                        defaultValue={
                                            data?.exams?.length ===
                                                selectedExam?.length &&
                                            data?.subject?._id ===
                                                selectedSubject?.value
                                                ? {
                                                      value: data?.subject?._id,
                                                      label: data?.subject
                                                          ?.name,
                                                  }
                                                : {}
                                        }
                                        placeholder={"Subject"}
                                        NoMessageText={
                                            "Please select/change Exams"
                                        }
                                        options={subjectList?.map((item) => {
                                            return {
                                                label: item?.label,
                                                value: item?.value,
                                            };
                                        })}
                                        control={control as any}
                                        transform={{
                                            output: (
                                                template: PrimitiveObject
                                            ) => {
                                                return template?.value;
                                            },
                                            input: (value: string) => {
                                                return _find([], {
                                                    value,
                                                });
                                            },
                                        }}
                                    />
                                )}
                            </Col>
                            <Col>
                                <AppFormSelect
                                    key={getValues("Subject")}
                                    id={"Topic"}
                                    name={"Topic"}
                                    label={t("question.users.form.label.topic")}
                                    className="role-container "
                                    errorMessage={errors.Topic?.message}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    required={true}
                                    {...validation("Topic", formState, false)}
                                    defaultValue={
                                        data?.subject?._id ===
                                            getValues("Subject") &&
                                        data?.topic?._id === getValues("Topic")
                                            ? {
                                                  value: data?.topic?._id,
                                                  label: data?.topic?.name,
                                              }
                                            : {}
                                    }
                                    placeholder={"Topic"}
                                    NoMessageText={
                                        "Please select/change Subject"
                                    }
                                    options={allTopic?.map((item) => {
                                        return {
                                            label: item?.label,
                                            value: item?.value,
                                        };
                                    })}
                                    control={control as any}
                                    transform={{
                                        output: (template: PrimitiveObject) => {
                                            return template?.value;
                                        },
                                        input: (value: string) => {
                                            return _find([], {
                                                value,
                                            });
                                        },
                                    }}
                                />
                            </Col>
                            <Row>
                                <AppFormRichTextArea
                                    name={"Question"}
                                    label={t(
                                        "question.users.form.label.question"
                                    )}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    required={true}
                                    className={"mb-4"}
                                    control={control}
                                    minHeight={50}
                                    defaultValue={
                                        data?.question?.description || ""
                                    }
                                    {...validation(
                                        "Question",
                                        formState,
                                        isEditMode
                                    )}
                                    errorMessage={errors?.Question?.message}
                                />
                            </Row>
                            <AppFormMultiSelectCreatable
                                name="Tags"
                                label={t("question.users.form.label.userTags")}
                                md={12}
                                sm={12}
                                lg={12}
                                xl={12}
                                id="where-filter"
                                handleCreate={handleCreateTag}
                                onChangeHandler={setSelectedUserTags}
                                value={selectedUserTags}
                                options={tags}
                                control={control}
                            />
                        </AppCard>
                        <AppCard>
                            <Row>
                                <AppFormRichTextArea
                                    name={"OptionA"}
                                    errorMessage={errors?.OptionA?.message}
                                    label={t(
                                        "question.users.form.label.optionA"
                                    )}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    required={true}
                                    control={control}
                                    minHeight={50}
                                    className={"mb-4 add-edit-options"}
                                    defaultValue={
                                        data?.options?.[0]?.description || ""
                                    }
                                    isOption={true}
                                    defaultValueisCorrect={correctAns?.[0]}
                                    handleCorrectAns={(e) =>
                                        e.currentTarget.checked
                                            ? (correctAns[0] = true)
                                            : (correctAns[0] = false)
                                    }
                                    {...validation(
                                        "OptionA",
                                        formState,
                                        isEditMode
                                    )}
                                />
                                <AppFormRichTextArea
                                    name={"OptionB"}
                                    errorMessage={errors?.OptionB?.message}
                                    label={t(
                                        "question.users.form.label.optionB"
                                    )}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    required={true}
                                    control={control}
                                    minHeight={50}
                                    className={"mb-4 add-edit-options"}
                                    defaultValueisCorrect={correctAns?.[1]}
                                    defaultValue={
                                        data?.options?.[1]?.description || ""
                                    }
                                    isOption={true}
                                    handleCorrectAns={(e) =>
                                        e.currentTarget.checked
                                            ? (correctAns[1] = true)
                                            : (correctAns[1] = false)
                                    }
                                    {...validation(
                                        "OptionB",
                                        formState,
                                        isEditMode
                                    )}
                                />
                            </Row>
                            <Row>
                                <AppFormRichTextArea
                                    name={"OptionC"}
                                    errorMessage={errors?.OptionC?.message}
                                    label={t(
                                        "question.users.form.label.optionC"
                                    )}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    required={true}
                                    control={control}
                                    defaultValueisCorrect={correctAns?.[2]}
                                    className={"mb-4 add-edit-options"}
                                    defaultValue={
                                        data?.options?.[2]?.description || ""
                                    }
                                    minHeight={50}
                                    handleCorrectAns={(e) =>
                                        e.currentTarget.checked
                                            ? (correctAns[2] = true)
                                            : (correctAns[2] = false)
                                    }
                                    isOption={true}
                                    {...validation(
                                        "OptionC",
                                        formState,
                                        isEditMode
                                    )}
                                />
                                <AppFormRichTextArea
                                    name={"OptionD"}
                                    errorMessage={errors?.OptionD?.message}
                                    label={t(
                                        "question.users.form.label.optionD"
                                    )}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    required={true}
                                    control={control}
                                    className={"mb-4 add-edit-options"}
                                    defaultValueisCorrect={correctAns?.[3]}
                                    defaultValue={
                                        data?.options?.[3]?.description || ""
                                    }
                                    minHeight={50}
                                    handleCorrectAns={(e) =>
                                        e.currentTarget.checked
                                            ? (correctAns[3] = true)
                                            : (correctAns[3] = false)
                                    }
                                    isOption={true}
                                    {...validation(
                                        "OptionD",
                                        formState,
                                        isEditMode
                                    )}
                                />
                            </Row>
                        </AppCard>
                        <AppCard>
                            <AppFormRichTextArea
                                name={"Solution"}
                                errorMessage={errors?.Solution?.message}
                                label={t("question.users.form.label.solution")}
                                md={12}
                                lg={12}
                                xl={12}
                                className={"mb-4"}
                                defaultValue={data?.solution?.description || ""}
                                {...validation(
                                    "Solution",
                                    formState,
                                    isEditMode
                                )}
                                control={control}
                                minHeight={50}
                            />
                        </AppCard>
                        <AppFormActions
                            isEditMode={isEditMode}
                            navigation={navigate}
                            isLoading={formState.isSubmitting}
                        />
                    </Form>
                )}
        </Fragment>
    );
};
