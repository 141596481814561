import { useContext, useEffect, useRef, useState } from "react";
import { ListGroup, ListGroupItem, Nav, Navbar } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import "./assets/scss/style.scss";
import { CONSTANTS } from "../../../config";
import { AuthContext, logoutAction } from "../../../AuthModule/contexts";
import { useTranslation } from "../../hooks";

export const AppNavigation = () => {
    const { dispatch } = useContext(AuthContext);
    const [navOpen, isNavOpen] = useState<boolean>(false);
    const logoHolder = useRef<HTMLDivElement>(null);
    const { IsAdmin } = CONSTANTS;
    const location = useLocation();
    const [activePage, setActivePage] = useState(location.pathname);
    const { t } = useTranslation();

    useEffect(() => {
        setActivePage(location.pathname);
    }, [location]);

    const handleLogoutEvent = async (): Promise<void> => {
        await logoutAction(dispatch);
    };

    return (
        <aside
            className={
                "left-sidebar left-container d-block navbar-expand-md sidebar"
            }
        >
            <Navbar
                className="row m-0 p-0 mb-md-4 d-block"
                expand="lg"
                onToggle={isNavOpen}
                expanded={navOpen}
            >
                <div className="col-md-12">
                    <div className="logo-holder row">
                        <div
                            ref={logoHolder}
                            className="main-logo-container my-0 my-lg-2 p-md-4 p-xl-2"
                        >
                            {/* {!isMobile ? (
                                <Link to="/" className="main-logo col-xl-9" />
                            ) : ( */}
                            <Link
                                to={"#"}
                                className="main-logo col-xl-9"
                                onClick={() => {
                                    isNavOpen(!navOpen);
                                }}
                            />
                            {/* )} */}
                        </div>
                    </div>
                </div>
                <Navbar id="basic-navbar-nav">
                    <Nav className="w-100 flex-column">
                        <ListGroup>
                            {IsAdmin.includes(
                                localStorage.getItem("ep_role") || ""
                            ) ? (
                                <>
                                    <Link to={"admin/subjects"}>
                                        <ListGroupItem
                                            className={`nav-item px-lg-4 ${
                                                activePage.includes(
                                                    "/subjects" as string
                                                )
                                                    ? "list-group-item-active"
                                                    : ""
                                            }`}
                                        >
                                            <span>
                                                <i className="fa fa-book"></i>{" "}
                                                {t("navigation.subjects")}
                                            </span>
                                        </ListGroupItem>
                                    </Link>
                                    <Link to={"/admin/users"}>
                                        <ListGroupItem
                                            className={`nav-item px-lg-4 ${
                                                activePage.includes(
                                                    "/admin/users" as string
                                                )
                                                    ? "list-group-item-active"
                                                    : ""
                                            }`}
                                        >
                                            <span>
                                                <i
                                                    className="fa fa-users"
                                                    aria-hidden="true"
                                                ></i>{" "}
                                                {t("navigation.users")}
                                            </span>
                                        </ListGroupItem>
                                    </Link>
                                </>
                            ) : (
                                <>
                                    <Link to={"/questions"}>
                                        <ListGroupItem
                                            className={`nav-item px-lg-4 ${
                                                activePage.includes(
                                                    "/questions" as string
                                                )
                                                    ? "list-group-item-active"
                                                    : ""
                                            }`}
                                        >
                                            <span>
                                                <i className="fa fa-list"></i>{" "}
                                                {t("navigation.questions")}
                                            </span>
                                        </ListGroupItem>
                                    </Link>
                                </>
                            )}
                            <Link to={"/preps-config"}>
                                <ListGroupItem
                                    className={`nav-item px-lg-4 ${
                                        activePage.includes(
                                            "/preps-config" as string
                                        )
                                            ? "list-group-item-active"
                                            : ""
                                    }`}
                                >
                                    <span>
                                        <i className="fa fa-gear"></i>
                                        {t("navigation.configuration")}
                                    </span>
                                </ListGroupItem>
                            </Link>
                            <Link to={"/preps"}>
                                <ListGroupItem
                                    className={`nav-item px-lg-4 ${
                                        activePage === ("/preps" as string)
                                            ? "list-group-item-active"
                                            : ""
                                    }`}
                                >
                                    <span>
                                        <i className="fa fa-file-text"></i>{" "}
                                        {t("navigation.preps")}
                                    </span>
                                </ListGroupItem>
                            </Link>
                            <Link to={"/"} onClick={() => handleLogoutEvent()}>
                                <ListGroupItem className="nav-item px-lg-4">
                                    <span>
                                        <i className="fa fa-sign-out"></i>
                                        {t("navigation.logout")}
                                    </span>
                                </ListGroupItem>
                            </Link>
                        </ListGroup>
                        <ListGroupItem
                            className={`nav-item p-3 user-details ${
                                activePage.includes("/subjects" as string)
                                    ? "list-group-item-active"
                                    : ""
                            }`}
                        >
                            <span>
                                <i
                                    className="fa fa-user-circle"
                                    aria-hidden="true"
                                ></i>{" "}
                                {localStorage.getItem("ep_user")}
                            </span>
                        </ListGroupItem>
                    </Nav>
                </Navbar>
            </Navbar>
        </aside>
    );
};
