import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.css";
import "./AppModule/assets/scss/bootstrap.scss";
import "./AppModule/assets/scss/main.scss";
import { ResetPassword } from "./AuthModule/pages/ResetPassword";
import { Login } from "./AuthModule/pages/Login/Login";
import { UserAddEdit, UserList } from "./AdminModule/pages";
import {
    PrepAddEdit,
    PrepList,
    QuestionList,
    SubjectList,
} from "./AppModule/pages";
import { QuestionAddEdit } from "./AppModule/pages/QuestionList";
import { Container } from "react-bootstrap";
import { AppNavigation } from "./AppModule/containers/AppNavigation/AppNavigation";
import { AppContext } from "./AppModule/contexts/AppContext";
import { CONSTANTS } from "./config";
import {
    PerpConfigAddEdit,
    PrepConfigList,
} from "./AppModule/pages/PrepConfig";

// Require Editor CSS files.
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";

export default function App() {
    const { state } = React.useContext(AppContext);
    const { loginRoute } = CONSTANTS;
    const { showAppNav } = state;

    useEffect(() => {
        if (window.location.pathname === "/") {
            if (localStorage.getItem("ep_isAuthenticated") === "true") {
                window.location.href = "/questions";
            } else {
                window.location.href = "/auth/login";
            }
        }
    }, [window.location.pathname]);
    return (
        <React.StrictMode>
            <BrowserRouter>
                <Suspense>
                    <Container className={"p-0 unfixed"} fluid={true}>
                        {localStorage.getItem("ep_isAuthenticated") ===
                            "true" &&
                            (!loginRoute.includes(window.location.pathname) ||
                                showAppNav) && <AppNavigation />}
                        <div
                            className={`app-container ${
                                localStorage.getItem("ep_isAuthenticated") ===
                                    "true" &&
                                (!loginRoute.includes(
                                    window.location.pathname
                                ) ||
                                    showAppNav)
                                    ? "right-container"
                                    : ""
                            } mr-0 ms-auto`}
                        >
                            <div className="col-md-12 col-sm-12 col-xl-12 p-3 p-lg-4">
                                <Routes>
                                    <Route path="/auth">
                                        <Route
                                            path="login"
                                            element={<Login />}
                                        />
                                        <Route
                                            path="reset-password"
                                            element={<ResetPassword />}
                                        />
                                    </Route>
                                    <Route path="/admin">
                                        <Route path="users">
                                            <Route
                                                path=""
                                                element={<UserList />}
                                            />
                                            <Route
                                                path=":id"
                                                element={<UserAddEdit />}
                                            />
                                            <Route
                                                path="new"
                                                element={<UserAddEdit />}
                                            />
                                        </Route>
                                        <Route
                                            path="subjects"
                                            element={<SubjectList />}
                                        />
                                    </Route>
                                    <Route path="preps-config">
                                        <Route
                                            path=""
                                            element={<PrepConfigList />}
                                        />
                                        <Route
                                            path=":id"
                                            element={<PerpConfigAddEdit />}
                                        />
                                        <Route
                                            path="new"
                                            element={<PerpConfigAddEdit />}
                                        />
                                    </Route>
                                    <Route path="preps">
                                        <Route path="" element={<PrepList />} />
                                        <Route
                                            path=":id"
                                            element={<PrepAddEdit />}
                                        />
                                        <Route
                                            path="new"
                                            element={<PrepAddEdit />}
                                        />
                                        <Route path="view">
                                            <Route
                                                path=":id"
                                                element={
                                                    <PrepAddEdit
                                                        isReadOnly={true}
                                                    />
                                                }
                                            />
                                        </Route>
                                    </Route>
                                    <Route path="/">
                                        <Route path="questions">
                                            <Route
                                                path=""
                                                element={<QuestionList />}
                                            />
                                            <Route
                                                path=":id"
                                                element={<QuestionAddEdit />}
                                            />
                                            <Route
                                                path="new"
                                                element={<QuestionAddEdit />}
                                            />
                                        </Route>
                                    </Route>
                                </Routes>
                            </div>
                        </div>
                    </Container>
                </Suspense>
            </BrowserRouter>
        </React.StrictMode>
    );
}
