import { FC, PropsWithChildren } from "react";
import { useTranslation } from "../../hooks";
import { AppButton } from "../AppButton";
import "./assets/scss/style.scss";

interface AppFormActionsProps {
    isEditMode: boolean;
    backLink?: string;
    navigation: any;
    publishDisabled?: boolean;
    isLoading?: boolean;
    cancelLabel?: string;
    cancelingLabel?: string;
    saveLabel?: string;
    savingLabel?: string;
    updateLabel?: string;
    updatingLabel?: string;
    backAction?: () => void;
    className?: string;
    cancelLoading?: boolean;
}
export const AppFormActions: FC<PropsWithChildren<AppFormActionsProps>> = ({
    isEditMode,
    publishDisabled,
    backLink,
    navigation,
    isLoading,
    className = "",
    children = "",
    cancelLabel = "common.button.cancel",
    cancelingLabel = "common.button.canceling",
    saveLabel = "common.button.save",
    savingLabel = "common.button.saving",
    updateLabel = "common.button.update",
    updatingLabel = "common.button.updating",
    backAction,
    cancelLoading = false,
}): JSX.Element => {
    const { t } = useTranslation();
    return (
        <div
            className={`d-flex justify-content-end footer-action w-100 ${className}`}
        >
            {children}
            <AppButton
                type="button"
                isLoading={cancelLoading}
                variant={"secondary"}
                className="me-4"
                disabled={
                    (publishDisabled && publishDisabled) ||
                    isLoading ||
                    cancelLoading
                }
                loadingTxt={cancelingLabel}
                onClick={() => {
                    if (backAction) {
                        backAction();
                    } else if (backLink) {
                        navigation(backLink);
                    } else {
                        navigation("..");
                    }
                }}
            >
                {t(cancelLabel)}
            </AppButton>
            <AppButton
                type="submit"
                isLoading={isLoading}
                disabled={isLoading || cancelLoading}
                loadingTxt={isEditMode ? t(updatingLabel) : t(savingLabel)}
            >
                {isEditMode ? t(updateLabel) : t(saveLabel)}
            </AppButton>
        </div>
    );
};
