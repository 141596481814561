import React, { ReactElement } from "react";
import { ICellRendererParams } from "ag-grid-community";
import {
    AppGridActionProps,
    AppGridAction,
} from "../../../AppModule/components";
import { AppCellActionParamsUserList } from "./AppCellActionParamsUserList";
import { useTranslation } from "react-i18next";

export interface AppCellActionWithRenderParamsUserList
    extends AppCellActionParamsUserList,
        ICellRendererParams {}

export const appGridFrameworkComponents = {
    appNameTemplateRenderer: (params: ICellRendererParams): ReactElement => {
        const { data } = params;
        const { admin } = data;
        const { firstName, lastName } = admin?.[0];
        return (
            <div className="info--det">
                <h3 className="mb-1">
                    {firstName} {lastName}
                </h3>
            </div>
        );
    },
    AppIsPublish: (params: ICellRendererParams): ReactElement => {
        const { data } = params;
        const { isPublish } = data;
        return (
            <div className="info--det">
                <h3 className="mb-1">{isPublish ? "Yes" : "No"}</h3>
            </div>
        );
    },
    AppIsVisible: (params: ICellRendererParams): ReactElement => {
        const { data } = params;
        const { isVisible } = data;
        return (
            <div className="info--det">
                <h3 className="mb-1">{isVisible ? "Yes" : "No"}</h3>
            </div>
        );
    },
    AppConfigRenderer: (
        params: AppCellActionWithRenderParamsUserList
    ): ReactElement => {
        const { t } = useTranslation();
        const { data, onPressDelete } = params;
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { _id, isAuthor, isPublish } = data;
        const id = _id;

        const props: AppGridActionProps = {
            editAction: {
                url: `/preps/${id}`,
                disable: !isAuthor || isPublish,
            },
            deleteAction: {
                confirmationTitle: t(
                    "admin.users.list.delete.modal.confirmationTitle"
                ),
                confirmation: t("admin.users.list.delete.modal.confirmation"),
                onClick: () => {
                    if (onPressDelete) onPressDelete(id);
                },
                disable: !isAuthor || isPublish,
            },
            customClickActions: [],
            customLinkActions: [],
        };
        // props.customClickActions?.push({
        //     confirmationTitle: t("Visible"),
        //     confirmation: t(
        //         `You want to ${isVisible ? "invisible" : "visible"}  this prep`
        //     ),
        //     icon: "Eye",
        //     onClick: () => {
        //         if (onPressVisible) onPressVisible(data);

        //     },
        // });
        props.customLinkActions?.push({
            url: `/preps/view/${id}`,
            icon: "faArrowToRight",
        });
        return <AppGridAction {...props} />;
    },
};
