import { useTranslation as UseTrans } from "react-i18next";

export const useTranslation: any = () => {
    const { t } = UseTrans();

    const translate = (translationKey) => {
        return t(translationKey);
    };

    return { t: translate };
};
