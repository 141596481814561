import React, { useEffect } from "react";
import { AppCard, AppLoader } from "../../components";
export const AppInfiniteScroll = ({
    renderData,
    hasMore,
    page,
    setPage,
    hasMoreLoading,
    dataLength,
}) => {
    const handleScroll = () => {
        if (
            Math.round(
                window.innerHeight + document.documentElement.scrollTop
            ) >
            document.documentElement.offsetHeight - 500
        ) {
            if (hasMore && !hasMoreLoading) {
                setPage(page + 1);
            }
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [hasMoreLoading]);

    return (
        <div>
            {renderData()}
            {hasMore ? (
                <AppLoader
                    containerClassName="filter-modal-loader"
                    coverContainer={true}
                />
            ) : (
                !!dataLength && (
                    <AppCard className="text-center fw-bold p-4">
                        <span>End of results |</span>
                        <span
                            className="ms-1 cursor-pointer"
                            onClick={() => {
                                window?.scrollTo(0, 0);
                            }}
                        >
                            Edit search
                        </span>
                    </AppCard>
                )
            )}
        </div>
    );
};
