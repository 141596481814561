import React, { ReactElement } from "react";
import { ICellRendererParams } from "ag-grid-community";
import {
    AppGridActionProps,
    AppGridAction,
} from "../../../AppModule/components";
import { AppCellActionParamsUserList } from "./AppCellActionParamsUserList";

export interface AppCellActionWithRenderParamsUserList
    extends AppCellActionParamsUserList,
        ICellRendererParams {}

export const appGridFrameworkComponents = {
    appNameTemplateRenderer: (params: ICellRendererParams): ReactElement => {
        const { data } = params;
        const { admin } = data;
        return (
            <div className="info--det">
                <h3 className="mb-1">
                    {admin?.[0]?.firstName || ""} {admin?.[0]?.lastName || ""}
                </h3>
            </div>
        );
    },
    AppQueActionRenderer: (): ReactElement => {
        const props: AppGridActionProps = {
            customLinkActions: [
                {
                    icon: "faArrowToRight",
                    url: "/questions",
                },
            ],
        };

        return <AppGridAction {...props} />;
    },
};
