import Swal, { SweetAlertOptions, SweetAlertResult } from "sweetalert2";

const defaultConfig: SweetAlertOptions = {
    toast: true,
    timer: 5000,
    position: "top-end",
};

const notificationConfig: SweetAlertOptions = {
    toast: true,
    timer: 10000,
    position: "top-end",
};

export const notificationToast = (title: string, content: string): void => {
    Swal.fire({
        customClass: "cus-header-notification",
        iconHtml: '<i class="far fa-bell"></i>',
        html: content,
        title,
        ...notificationConfig,
    }).then();
};

export const successToast = (text: string): void => {
    Swal.fire({
        text,
        title: "Success",
        icon: "success",
        ...defaultConfig,
    }).then();
};

export const warningToast = (text: string): void => {
    Swal.fire({
        text,
        title: "Warning",
        icon: "warning",
        ...defaultConfig,
    }).then();
};

export const errorToast = (text = "Unknown Error"): void => {
    Swal.fire({
        // text,
        title: text,
        icon: "error",
        ...defaultConfig,
    }).then();
};

export const showLoader = (text: string): Promise<SweetAlertResult<any>> => {
    return Swal.fire({
        text,
        didOpen: () => {
            Swal.showLoading();
        },
        ...defaultConfig,

        toast: false,
        backdrop: true,
        position: "center",
        showConfirmButton: false,
    }).then();
};

export const hideLoader = (): void => {
    Swal.close();
};
