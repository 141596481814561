import { BaseEntity, JsonResponseData } from "../../../AppModule/models";
import { UserApi } from "../../apis";

export class User extends BaseEntity {
    firstName: string;

    lastName: string;

    email: string;

    role: string;

    plainPassword: string;

    confirmPassword: string;

    constructor({
        firstName = "",
        lastName = "",
        email = "",
        role = "",
        plainPassword = "",
        confirmPassword = "",
        id,
        createdAt,
        updatedAt,
    }: Partial<User> = {}) {
        super(id, createdAt, updatedAt);
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.role = role;
        this.plainPassword = plainPassword;
        this.confirmPassword = confirmPassword;
    }

    toString(): string {
        return UserApi.toResourceUrl(this.id);
    }

    toJSON(addExtraData = false): JsonResponseData {
        const data: JsonResponseData = {
            ...super.toJSON(addExtraData),
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            role: this.role,
            plainPassword: this.plainPassword,
            confirmPassword: this.confirmPassword,
        };

        return data;
    }
}

export type PUser = Partial<User>;

export type PRUser = {
    "@id": string;
} & PUser;
