import { Col, Row } from "react-bootstrap";
import { Fragment } from "react/jsx-runtime";
import { AppGrid } from "../../containers/AppGrid";
import { useRef, useState } from "react";
import { GridApi } from "ag-grid-community";
import { AppListPageToolbar, AppLoader, AppPageHeader } from "../../components";
import { appGridColDef } from "./app-grid-col-def";
import { Question } from "../../models/entities/Question";
import { Canceler } from "axios";
import { SubjectApi } from "../../../AdminModule/apis/SubjectApi";
import { buildConfig } from "../../../AuthModule/contexts";
import { AppContext, ContainerTypes } from "../../contexts/AppContext";
import React from "react";

export const SubjectList = () => {
    const [totalItems, setTotalItems] = useState<number>(0);
    const appGridApi = useRef<GridApi>();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const cancelTokenSourcesRef = useRef<Canceler[]>([]);
    const { state, dispatch } = React.useContext(AppContext);
    const { isRefreshLoading } = state;
    async function handleFilter(search: string) {
        appGridApi.current?.setFilterModel({
            user_search: {
                filter: search,
            },
        });
    }

    const getDataSource = async () => {
        dispatch({
            type: ContainerTypes.LOADING,
        });
        let data: Question[] = [];
        await SubjectApi.SubjectList(
            buildConfig(localStorage?.getItem("ep_token") || ({} as any))
        )
            .then((response: any) => {
                const res = response.response;
                if (res !== null) {
                    // core.handleUserList(res?.items);
                    setTotalItems(res?.totalItems);
                    data = res;
                }
            })
            .finally(() => {
                dispatch({
                    type: ContainerTypes.REFRESH_LOADED,
                });
            });
        return data;
    };

    return (
        <Fragment>
            {isRefreshLoading ? <AppLoader /> : null}
            <input
                ref={fileInputRef}
                id="select-file"
                type="file"
                accept=".csv"
                hidden={true}
            />
            <AppPageHeader title={"Subjects"} customToolbar>
                <div className="d-block mb-3 mb-md-5">
                    <div className="d-block d-sm-flex pt-2 justify-content-end user-header-width">
                        <AppListPageToolbar
                            createLink={"/questions"}
                            createLabel="common.button.create"
                            onQuickFilterChange={handleFilter}
                            cancelTokenSources={cancelTokenSourcesRef.current}
                        />
                    </div>
                    {/* <div className="d-flex impexp-options justify-content-end">
                        {isGranted(role, ROLE_ADMIN) && (
                            <>
                                <AppButton
                                    onClick={() => {
                                        handleExport();
                                    }}
                                    className="mb-2 ms-2 p-3 user-ex"
                                    variant="secondary"
                                >
                                    <AppIcon className="mr-2" name="Upload" />
                                    {t("common.button:export")}
                                </AppButton>
                            </>
                        )}

                        {!isGranted(role, ROLE_SUPER_ADMIN) &&
                            isGranted(role, ROLE_ADMIN) && (
                                <>
                                    <AppButton
                                        onClick={() => {
                                            handleImport();
                                        }}
                                        className="p-3 user-imp mb-2 ms-2"
                                        variant="secondary"
                                        hidden={true}
                                    >
                                        <AppIcon
                                            className="mr-2"
                                            name="Download"
                                        />
                                        {t("common.button:import")}
                                    </AppButton>
                                </>
                            )}
                    </div> */}
                </div>
            </AppPageHeader>
            <Row>
                <Col>
                    <AppGrid
                        columnDef={appGridColDef()}
                        getDataSource={getDataSource}
                        totalItems={totalItems}
                        onReady={(event) => {
                            appGridApi.current = event.api;
                        }}
                    />
                </Col>
            </Row>
        </Fragment>
    );
};
