import React, { ReactElement } from "react";
import { ICellRendererParams } from "ag-grid-community";
import {
    AppGridActionProps,
    AppGridAction,
} from "../../../AppModule/components";
import { AppCellActionParamsUserList } from "./AppCellActionParamsUserList";
import { useTranslation } from "react-i18next";

export interface AppCellActionWithRenderParamsUserList
    extends AppCellActionParamsUserList,
        ICellRendererParams {}

export const appGridFrameworkComponents = {
    appNameTemplateRenderer: (params: ICellRendererParams): ReactElement => {
        const { data } = params;
        const { admin } = data;
        const { firstName, lastName } = admin?.[0];
        return (
            <div className="info--det">
                <h3 className="mb-1">
                    {firstName} {lastName}
                </h3>
            </div>
        );
    },
    AppTotalMarks: (params: ICellRendererParams): ReactElement => {
        const { data } = params;
        const { correctAnswerMarks, totalQuestions } = data;
        return <div>{correctAnswerMarks * totalQuestions}</div>;
    },
    AppConfigRenderer: (
        params: AppCellActionWithRenderParamsUserList
    ): ReactElement => {
        const { t } = useTranslation();
        const { data, onPressDelete } = params;
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { _id, isAuthor, linkedCount } = data;
        const id = _id;

        const props: AppGridActionProps = {
            editAction: {
                url: `/preps-config/${id}`,
                disable: linkedCount > 0 ? true : !isAuthor,
            },
            deleteAction: {
                confirmationTitle: t(
                    "admin.users.list.delete.modal.confirmationTitle"
                ),
                confirmation: t("admin.users.list.delete.modal.confirmation"),
                onClick: () => {
                    if (onPressDelete) onPressDelete(id);
                },
                disable: linkedCount > 0 ? true : !isAuthor,
            },
        };

        return <AppGridAction {...props} />;
    },
};
