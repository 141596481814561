import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { parse } from "qs";
import { Container, Row, Col, Form } from "react-bootstrap";
import * as yup from "yup";
import { isString as _isString } from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import { AppButton } from "../../../AppModule/components/AppButton";
import { AppAuthHeader, AppAuthFooter } from "../../components";
import "./assets/scss/styles.scss";
import { AppFormInputPassword } from "../../../AppModule/components";
import { validation } from "../../../AppModule/utils";
import { useLocation } from "react-router-dom";

type RestPasswordForm = {
    currentPassword?: string;
    password?: string;
    confirmPassword?: string;
    plainPassword?: string;
    token?: string;
};

const schema = yup.object().shape({
    currentPassword: yup.string().min(6).required(),
    password: yup.string().min(6).required(),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref("password"), undefined], "Passwords must be match"),
});

export const ResetPassword: FC<any> = (): JSX.Element => {
    const { control, handleSubmit, formState } = useForm<RestPasswordForm>({
        resolver: yupResolver<any>(schema),
        mode: "all",
    });
    const [loading, isLoading] = React.useState<boolean>(false);
    const { t } = useTranslation();

    const { errors } = formState;
    const location = useLocation();
    const onSubmit = async () => {
        const searchParams = parse(location.search, {
            ignoreQueryPrefix: true,
        });
        const { token } = searchParams;
        if (_isString(token)) {
            isLoading(true);
        }
    };
    return (
        <Container
            fluid
            className="active-account auth-container with-bg"
            style={{}}
        >
            <div className="auth-container--box">
                <Row className="p-0 m-auto">
                    <AppAuthHeader
                        title={t("resetPassword.title")}
                        description={t("resetPassword.description")}
                    />
                    <div className="active-account-box">
                        <Col md={12} className="active-account-box--auth-form">
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Form.Group>
                                    <Row>
                                        <AppFormInputPassword
                                            md={12}
                                            lg={12}
                                            xl={12}
                                            name={"currentPassword"}
                                            label={""}
                                            required={true}
                                            {...validation(
                                                "currentPassword",
                                                formState,
                                                false
                                            )}
                                            errorMessage={
                                                errors.currentPassword?.message
                                            }
                                            control={control}
                                        />
                                    </Row>
                                    <Row>
                                        <AppFormInputPassword
                                            md={12}
                                            lg={12}
                                            xl={12}
                                            name={"password"}
                                            label={""}
                                            required={true}
                                            {...validation(
                                                "password",
                                                formState,
                                                false
                                            )}
                                            errorMessage={
                                                errors.password?.message
                                            }
                                            control={control}
                                        />
                                    </Row>
                                    <Row>
                                        <AppFormInputPassword
                                            md={12}
                                            lg={12}
                                            xl={12}
                                            name={"confirmPassword"}
                                            label={""}
                                            required={true}
                                            {...validation(
                                                "confirmPassword",
                                                formState,
                                                false
                                            )}
                                            errorMessage={
                                                errors.confirmPassword?.message
                                            }
                                            control={control}
                                        />
                                    </Row>
                                </Form.Group>
                                <div className="d-grid">
                                    <AppButton
                                        disabled={loading}
                                        type={"submit"}
                                    >
                                        {loading
                                            ? `${t("resetPassword.form.button.resetPassword")}...`
                                            : t(
                                                  "resetPassword.form.button.resetPassword"
                                              )}
                                    </AppButton>
                                </div>
                            </Form>
                        </Col>
                    </div>
                </Row>
            </div>
            <AppAuthFooter />
        </Container>
    );
};
