import React, { ChangeEventHandler, FC } from "react";
import { Form, FormCheck } from "react-bootstrap";
import { Control, Controller } from "react-hook-form";
import { useGridHelper } from "../../hooks";

import "./assets/scss/style.scss";

export interface AppFormSwitchProps {
    id?: string;
    name: string;
    xs?: string | number;
    sm?: string | number;
    md?: string | number;
    lg?: string | number;
    xl?: string | number;
    value?: boolean;
    type?: string;
    required?: boolean;
    label?: string;
    description?: string;
    errorMessage?: string;
    isInvalid?: boolean;
    isValid?: boolean;
    control?: Control<any>;
    defaultChecked?: boolean;
    className?: string;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    disabled?: boolean;
    hasDependent?: boolean;
}

export const AppFormSwitch: FC<AppFormSwitchProps> = ({
    id,
    name,
    errorMessage,
    label = "",
    className = "",
    sm = 12,
    md = 6,
    lg = 4,
    xl = 4,
    required = false,
    control,
    isValid,
    isInvalid,
    defaultChecked = false,
    onChange = () => {},
    disabled = false,
    hasDependent = false,
}): JSX.Element => {
    const controlId = id || name;
    const { getColumnClasses } = useGridHelper();
    const colClasses = getColumnClasses(sm, md, lg, xl);

    let classes = "lg ";
    classes += isInvalid ? "is-invalid" : "";
    classes += isValid ? "is-valid" : "";

    return (
        <Form.Group
            className={`form-group ${colClasses} ${className}`}
            controlId={controlId}
        >
            <div className="d-flex justify-content-end">
                <Form.Label className="pt-2 pe-2">
                    {label}
                    {required && <span className="required">*</span>}
                </Form.Label>
                <Controller
                    name={`${name}`}
                    control={control}
                    defaultValue={defaultChecked}
                    render={({ field }) => (
                        <FormCheck
                            inline={true}
                            data-value={name}
                            id={`custom-switch-${name}`}
                            type="switch"
                            label={""}
                            className={classes}
                            {...(hasDependent && { checked: field?.value })}
                            {...field}
                            checked={defaultChecked}
                            onChange={(e) => {
                                onChange(e);
                                defaultChecked = e.currentTarget.checked
                                    ? true
                                    : false;
                                return field.onChange(e);
                            }}
                            disabled={disabled}
                        />
                    )}
                />
            </div>
            <Form.Control.Feedback className={""} type="invalid">
                {errorMessage}
            </Form.Control.Feedback>
        </Form.Group>
    );
};
