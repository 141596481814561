import { AxiosRequestConfig } from "axios";
import { AUTH_TOKEN_KEY } from "../../config/app-env";
import { generateKeyHeader, getUserTimeZone } from "../../utils/api";

export const onRequestFulfilled = (
    config: AxiosRequestConfig
): AxiosRequestConfig | Promise<AxiosRequestConfig> => {
    if (config.headers?.["ES-KEY"])
        config.headers["ES-KEY"] = generateKeyHeader();
    if (config.headers?.["ES-TIMEZONE"])
        config.headers["ES-TIMEZONE"] = getUserTimeZone();
    if (config.headers?.["Content-Type"])
        config.headers["Content-Type"] = "application/ld+json";

    const token = localStorage.getItem(AUTH_TOKEN_KEY);
    if (token) {
        if (config.headers?.Authorization)
            config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
};

export const onRequestRejected = (): null => {
    return null;
};
