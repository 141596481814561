import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

import { ROUTES } from "../config";
import {
    FinalResponse,
    ListResponse,
    RequestParamsType,
    ServerError,
} from "../models";
import { EntityAPI } from "./EntityAPI";
import { onFindAllResponseJson } from "./transformer";

const { api_config: API_CONFIG } = ROUTES;

export interface ConfigResponse {
    success: boolean;
    message: string;
    name: string;
    showSubjectWeightage: boolean;
    correctMarks: number;
    incorrectMarks: number;
    isAuthor: boolean;
    skippedMarks: number;
    totalQuestions: number;
    totalTime: number;
    _id: string;
}

export interface PrepConfigPayload {
    name: string;
    totalQuestions: number;
    totalTime: number;
    correctMarks: number;
    skippedMarks: number;
    incorrectMarks: number;
    showSubjectWeightage: boolean;
}

export class PrepConfigApi extends EntityAPI {
    static async AddConfig(
        data: PrepConfigPayload,
        config: AxiosRequestConfig = {}
    ): Promise<ConfigResponse> {
        const res: AxiosResponse<ConfigResponse> = await this.makePost<
            ConfigResponse,
            PrepConfigPayload
        >(API_CONFIG, data, {}, config);
        return res?.data;
    }

    static async UpdateConfig(
        data: PrepConfigPayload,
        id: string,
        config: AxiosRequestConfig = {}
    ): Promise<ConfigResponse> {
        const res: AxiosResponse<ConfigResponse> = await this.makePut<
            ConfigResponse,
            PrepConfigPayload
        >(`${API_CONFIG}/${id}`, data, {}, config);
        return res?.data;
    }

    public static async deleteConfig(
        id: number,
        config: AxiosRequestConfig = {}
    ): Promise<any> {
        return this.makeDelete(`${API_CONFIG}/${id}`, config)
            .then(({ data }) => {
                return data;
            })
            .catch((error: AxiosError | ServerError) => {
                const { message } = error;
                return Promise.resolve(new FinalResponse(null, message));
            });
    }

    static async ConfigList<E>(
        config: AxiosRequestConfig = {},
        params: RequestParamsType = {}
    ) {
        return this.makeGet<E>(API_CONFIG, params, config)
            .then(({ data }: any) => {
                const list = onFindAllResponseJson<E>(data);
                return Promise.resolve(
                    new FinalResponse<ListResponse<E>>(list)
                );
            })
            .catch((error: AxiosError | ServerError) => {
                const { message } = error;
                return Promise.resolve(new FinalResponse(null, message));
            });
    }
}
