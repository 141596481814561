export class EmailCheckResponse {
    isExist = false;

    isBlocked = false;

    roles = [""];

    examId = [];

    name: "" | undefined;
}
