import React, { FC } from "react";
import { Modal } from "react-bootstrap";
import { AppLoader } from "../AppLoader";
import { useTranslation } from "../../hooks";

import "./assets/scss/style.scss";
import { AppButton } from "../AppButton";

interface AppFormModalProps {
    show: boolean;
    isLoading?: boolean;
    handleClose: () => void;
    handleSubmit: () => void;
    children: React.ReactNode;
    iconTitle?: string;
    title?: string;
    submitLabel?: string;
}

export interface AppFormModalRef {
    title: string;
}

export const AppFormModal: FC<AppFormModalProps> = ({
    show,
    handleClose,
    handleSubmit,
    isLoading = false,
    children,
    iconTitle,
    title,
    submitLabel,
}): JSX.Element => {
    const { t } = useTranslation();

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            className="app-form-modal"
            centered
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title className="text-center">
                    <i className={iconTitle}></i>
                    <h2>{title}</h2>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>{children}</Modal.Body>
            <Modal.Footer className="modal-footer">
                <AppButton
                    className="cancel-btn"
                    variant="secondary"
                    onClick={handleClose}
                >
                    <i className="fak fa-times-light"></i>
                    {submitLabel || t("common.button.cancel")}
                </AppButton>
                <AppButton
                    className="submit-btn"
                    variant="primary"
                    onClick={handleSubmit}
                >
                    <i className="fak fa-check-regular-bold"></i>
                    {submitLabel || t("common.button.submit")}
                </AppButton>
            </Modal.Footer>
            {isLoading && (
                <AppLoader containerClassName="overlay-modal-loader" />
            )}
        </Modal>
    );
};
