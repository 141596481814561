import React, { ReactElement, FC } from "react";
import { ICellRendererParams } from "ag-grid-community";
import {
    AppSwitch,
    AppGridActionProps,
    AppGridAction,
} from "../../../AppModule/components";
import { User } from "../../models";
import { useTranslation } from "../../../AppModule/hooks";
import { AppCellActionParamsUserList } from "./AppCellActionParamsUserList";

export interface AppCellActionWithRenderParamsUserList
    extends AppCellActionParamsUserList,
        ICellRendererParams {}

interface UserInfo {
    data: User;
}

export const UserDetailsInfo: FC<UserInfo> = ({ data }): JSX.Element => {
    const { firstName, lastName } = data;
    return (
        <div className="info">
            <div className="info--det">
                <h3 className="mb-1">
                    {firstName} {lastName}
                </h3>
            </div>
        </div>
    );
};

export const appGridFrameworkComponents = {
    appSelect: (
        params: AppCellActionWithRenderParamsUserList
    ): ReactElement => {
        const { data, onSelectChange } = params;
        const { id } = data as User;

        return (
            <select
                id={`${id}`}
                onChange={(e) => {
                    onSelectChange(id, e.target?.value);
                }}
                name={"role"}
                className="list-deopdown"
            ></select>
        );
    },
    appHtml: (params: any) => {
        const { data } = params;
        const { question } = data;
        return <p dangerouslySetInnerHTML={{ __html: question }} />;
    },
    appOption: (params: any) => {
        const { data } = params;
        const { content } = data;
        return content;
    },
    appTags: (params: any) => {
        const { data } = params;
        const { tags } = data;

        return tags?.map((tag: any, i: number) => {
            return (
                <div key={i} className="me-2">
                    #{tag.tag}
                </div>
            );
        });
    },
    appDifficultyLevel: (params: any) => {
        const { data } = params;
        const { category } = data;
        return <p>{category === true ? "Miltiple" : "Single"}</p>;
    },
    appSwitch: (params: ICellRendererParams): ReactElement => {
        const { data } = params;
        const { id } = data as User;

        return (
            <AppSwitch
                name={`isblocked-${id}`}
                id={`isblocked-${id}`}
                value={false}
                size={"sm"}
                onChange={() => {}}
            />
        );
    },

    AppGridActionRenderer: (
        params: AppCellActionWithRenderParamsUserList
    ): ReactElement => {
        const { t } = useTranslation();
        const { data, onPressDelete } = params;
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { _id } = data;

        const id = _id;

        const props: AppGridActionProps = {
            editAction: {
                url: `/admin/users/${id}`,
            },
            deleteAction: {
                confirmationTitle: t(
                    "admin.users.list.delete.modal.confirmationTitle"
                ),
                confirmation: t("admin.users.list.delete.modal.confirmation"),
                onClick: () => {
                    if (onPressDelete) onPressDelete(id);
                },
            },
        };

        return <AppGridAction {...props} />;
    },
};
