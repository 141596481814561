import { ListResponse, SimpleObject } from "../../models";
import { checkAndParseResponse } from "../../utils";

export const onFindAllResponseJson = <T>(
    data: string | SimpleObject<any>
): ListResponse<T> => {
    const listResponse = new ListResponse<T>();
    const parsedData: SimpleObject<any> = checkAndParseResponse(data);

    if (parsedData?.items) {
        listResponse.items = parsedData.items;
    }
    if (parsedData?.totalItems) {
        listResponse.totalItems = parsedData.totalItems;
    }
    return listResponse;
};
