import React, { FC, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Container, Row, Col, Form } from "react-bootstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { AppButton } from "../../../AppModule/components/AppButton";
import { AuthContext, loginAction } from "../../contexts/AuthContext";
import { AppAuthHeader, AppAuthFooter } from "../../components";
import {
    AppFormInput,
    AppFormInputPassword,
} from "../../../AppModule/components";
import {
    errorToast,
    setViolations,
    validation,
} from "../../../AppModule/utils";
import { UserApi } from "../../../AdminModule/apis";
import { UnprocessableEntityErrorResponse } from "../../../AppModule/models";
import {
    AUTH_TOKEN_KEY,
    AUTH_USER_KEY,
    REFRESH_TOKEN_KEY,
} from "../../../AppModule/config/app-env";
import { EmailCheckRequest, EmailCheckResponse } from "../../models";

import "./assets/scss/styles.scss";
import { CONSTANTS } from "../../../config";

class LoginForm {
    email = "";

    password = "";

    confirmPassword = "";

    isExist = false;
}

const schema = () => {
    return yup.object().shape({
        email: yup.string().email().required(),
        isExist: yup.boolean(),
        password: yup
            .string()
            .when("isExist", ([isExist], schemas) =>
                isExist ? yup.string().min(6).required() : schemas
            ),
    });
};

export const Login: FC = (): JSX.Element => {
    const [emailStatus, setEmailStatus] = useState<string>("");
    const [role, setRole] = useState<string>("");
    const [userName, setUserName] = useState("");
    const [examId, setExamId] = useState([""]);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { IsAdmin } = CONSTANTS;
    const { control, handleSubmit, formState, setError, setValue, reset } =
        useForm<LoginForm>({
            resolver: yupResolver<any>(schema()),
            mode: "all",
        });
    const { errors } = formState;
    const [userStatus, setUserStatus] = useState<EmailCheckResponse>(
        new EmailCheckResponse()
    );

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const m = queryParams.get("m");
        if (m === "ir") {
            errorToast(t("user.login:ssoCs.roleMismatch"));
        }
    }, []);

    useEffect(() => {
        setValue("isExist", userStatus.isExist);
    }, [userStatus]);
    const { dispatch } = React.useContext(AuthContext);
    const onSubmitCheckUser = async ({ email }: LoginForm) => {
        return UserApi.emailExist<EmailCheckResponse, EmailCheckRequest>({
            email,
        }).then(({ error, errorMessage, response }) => {
            if (error instanceof UnprocessableEntityErrorResponse) {
                setViolations<LoginForm>(error, setError);
            } else if (errorMessage) {
                errorToast(errorMessage);
            } else if (response) {
                setUserStatus(response);
                if (response.isExist) {
                    if (response.isBlocked) {
                        errorToast(t("user.login:isBlocked"));
                        setEmailStatus("blocked");
                        setUserStatus(new EmailCheckResponse());
                    } else {
                        setRole(response.roles?.[0]);
                        setExamId(response.examId);
                        setEmailStatus("exist");
                        setUserName(response?.name || "");
                        localStorage.removeItem(AUTH_TOKEN_KEY);
                        localStorage.removeItem(REFRESH_TOKEN_KEY);
                        localStorage.removeItem(AUTH_USER_KEY);
                    }
                } else {
                    setEmailStatus("notexist");
                }
            }
        });
    };

    const onSubmit = async ({ email, password }: LoginForm) => {
        try {
            await loginAction(
                email,
                password,
                dispatch,
                role,
                examId,
                userName
            ).then((response) => {
                if (response) {
                    if (IsAdmin.includes(role)) navigate("/admin/users");
                    else navigate("/questions");
                }
            });
        } catch (e: any) {
            errorToast(e?.message);
        }
    };

    const renderView = () => {
        switch (emailStatus) {
            case "notexist":
                return (
                    <>
                        <AppAuthHeader
                            title={t("login.form.notRegisteredTitle")}
                            description={t(
                                "login.form.notRegisteredDescription"
                            )}
                        />
                        <Col className="text-center justify-content-center d-flex p-3">
                            <AppButton
                                variant="primary"
                                onClick={() => {
                                    setEmailStatus("");
                                }}
                            >
                                <b>{t("login.form:login")}</b>
                            </AppButton>
                        </Col>
                    </>
                );
            case "activationsent":
                return (
                    <>
                        <AppAuthHeader
                            title={t("login.form:activationTitle")}
                            description={t("login.form:activationDescription")}
                        />
                        <Col className="text-center justify-content-center d-flex p-3">
                            <AppButton
                                variant="primary"
                                onClick={() => {
                                    setEmailStatus("");
                                }}
                            >
                                <b>{t("login.form:goBack")}</b>
                            </AppButton>
                        </Col>
                    </>
                );
            case "blocked":
                return (
                    <>
                        <AppAuthHeader
                            title={t("login.form.title")}
                            description={t("login.form:description")}
                        />
                        <div className="active-account-box">
                            <Col
                                md={12}
                                className="active-account-box--auth-form"
                            >
                                <Form
                                    onSubmit={handleSubmit(onSubmitCheckUser)}
                                >
                                    <Form.Group>
                                        <Row>
                                            <AppFormInput
                                                md={12}
                                                lg={12}
                                                xl={12}
                                                type={"email"}
                                                name={"email"}
                                                placeholder={t(
                                                    "login.form.email"
                                                )}
                                                required={true}
                                                {...validation(
                                                    "email",
                                                    formState,
                                                    false
                                                )}
                                                errorMessage={
                                                    errors.email?.message
                                                }
                                                control={control}
                                            />
                                        </Row>
                                    </Form.Group>
                                    <AppButton
                                        block={true}
                                        type={"submit"}
                                        loadingTxt={`${t("login.form.login")} ...`}
                                        disabled={formState.isSubmitting}
                                        isLoading={formState.isSubmitting}
                                    >
                                        {t("login.form.login")}
                                    </AppButton>
                                </Form>
                            </Col>
                        </div>
                    </>
                );
            default:
                return (
                    <>
                        <AppAuthHeader
                            title={t("login.form.title")}
                            description={t("login.form.description")}
                        />
                        <div className="active-account-box">
                            <Col
                                md={12}
                                className="active-account-box--auth-form"
                            >
                                <Form
                                    onSubmit={handleSubmit(
                                        emailStatus === "exist"
                                            ? onSubmit
                                            : onSubmitCheckUser
                                    )}
                                >
                                    <Form.Group>
                                        <Row>
                                            <AppFormInput
                                                md={12}
                                                lg={12}
                                                xl={12}
                                                type={"email"}
                                                name={"email"}
                                                placeholder={t(
                                                    "login.form.email"
                                                )}
                                                required={true}
                                                readOnly={
                                                    emailStatus === "exist"
                                                }
                                                {...validation(
                                                    "email",
                                                    formState,
                                                    false
                                                )}
                                                errorMessage={
                                                    errors.email?.message
                                                }
                                                control={control}
                                            />
                                        </Row>
                                        {emailStatus === "exist" && (
                                            <>
                                                <Row>
                                                    <AppFormInputPassword
                                                        block={true}
                                                        className="m-0"
                                                        name={"password"}
                                                        placeholder={t(
                                                            "login.form.placeholder.password"
                                                        )}
                                                        {...validation(
                                                            "password",
                                                            formState,
                                                            false,
                                                            true
                                                        )}
                                                        control={control}
                                                    />
                                                </Row>
                                            </>
                                        )}
                                        {/* <Link
                                            to={"/auth/forgot-password"}
                                            className="forgot-password d-inline-block"
                                        >
                                            {t("login.form.forgotPassword")}
                                        </Link> */}

                                        {emailStatus === "exist" && (
                                            <Link
                                                to={"/auth/login"}
                                                className="forgot-password float-right d-inline-block"
                                                onClick={() => {
                                                    reset();
                                                    setEmailStatus("");
                                                }}
                                            >
                                                {t("login.form.tryOtherEmail")}
                                            </Link>
                                        )}
                                    </Form.Group>
                                    <div className="d-grid">
                                        <AppButton
                                            type={"submit"}
                                            size="lg"
                                            loadingTxt={`${t("login.form.login")} ...`}
                                            disabled={formState.isSubmitting}
                                            isLoading={formState.isSubmitting}
                                        >
                                            {t("login.form.login")}
                                        </AppButton>
                                    </div>
                                </Form>
                            </Col>
                        </div>
                    </>
                );
        }
    };

    return (
        <Container
            fluid
            className="active-account auth-container with-bg"
            style={{}}
        >
            <div className="auth-container--box">
                <Row className="p-0 m-auto"> {renderView()} </Row>
            </div>
            <AppAuthFooter copyRight={"copyrightContent"} />
        </Container>
    );
};
