import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

import { ROUTES } from "../config";
import {
    FinalResponse,
    ListResponse,
    PrimitiveObject,
    RequestParamsType,
    ServerError,
} from "../models";
import { EntityAPI } from "./EntityAPI";
import { onFindAllResponseJson } from "./transformer";
import { Question } from "../models/entities/Question";

const { api_prep: API_PREP } = ROUTES;

export interface ConfigResponse {
    message: string;
    success: string;
    versionName: string;
    prep: {
        name: string;
        level: string;
        prepConfig: { _id: string; versionName: string };
        questions: Array<Question>;
        used: number;
        isVisible: boolean;
        exams: Array<PrimitiveObject>;
    };
    _id: string;
}

export interface PrepPayload {
    name: string;
    prepConfigId: string;
    questionId: Array<string>;
    isPublish: boolean;
    isVisible: boolean;
    examId: Array<string>;
}

export class PrepApi extends EntityAPI {
    static async AddPrep(
        data: PrepPayload,
        config: AxiosRequestConfig = {}
    ): Promise<ConfigResponse> {
        const res: AxiosResponse<ConfigResponse> = await this.makePost<
            ConfigResponse,
            PrepPayload
        >(API_PREP, data, {}, config);
        return res?.data;
    }

    static async UpdatePrep(
        data: PrepPayload,
        id: string,
        config: AxiosRequestConfig = {}
    ): Promise<ConfigResponse> {
        const res: AxiosResponse<ConfigResponse> = await this.makePut<
            ConfigResponse,
            PrepPayload
        >(`${API_PREP}/${id}`, data, {}, config);
        return res?.data;
    }

    public static async deletePrep(
        id: number,
        config: AxiosRequestConfig = {}
    ): Promise<any> {
        return this.makeDelete(`${API_PREP}/${id}`, config)
            .then(({ data }) => {
                return data;
            })
            .catch((error: AxiosError | ServerError) => {
                const { message } = error;
                return Promise.resolve(new FinalResponse(null, message));
            });
    }

    static async GetPrepById<E>(
        id: string,
        config: AxiosRequestConfig = {},
        params: RequestParamsType = {}
    ) {
        return this.makeGet<E>(`${API_PREP}/${id}`, params, config)
            .then(({ data }: any) => {
                const list = onFindAllResponseJson<E>(data);
                return Promise.resolve(
                    new FinalResponse<ListResponse<E>>(list)
                );
            })
            .catch((error: AxiosError | ServerError) => {
                const { message } = error;
                return Promise.resolve(new FinalResponse(null, message));
            });
    }

    static async PrepList<E>(
        config: AxiosRequestConfig = {},
        params: RequestParamsType = {}
    ) {
        return this.makeGet<E>(API_PREP, params, config)
            .then(({ data }: any) => {
                const list = onFindAllResponseJson<E>(data);
                return Promise.resolve(
                    new FinalResponse<ListResponse<E>>(list)
                );
            })
            .catch((error: AxiosError | ServerError) => {
                const { message } = error;
                return Promise.resolve(new FinalResponse(null, message));
            });
    }
}
