import React, { FC } from "react";
import { Modal } from "react-bootstrap";
import "./assets/scss/style.scss";
import { AppIcon } from "../AppIcon";

interface AppRightSideDrawerProps {
    show: boolean;
    handleClose: () => void;
    handleDelete?: () => void;
    title: string;
    bodyContent: any;
    className?: string;
}

export const AppRightSideDrawer: FC<AppRightSideDrawerProps> = ({
    show,
    handleClose,
    bodyContent,
    title,
    className,
}): JSX.Element => {
    return (
        <Modal
            show={show}
            onHide={() => handleClose}
            backdrop="static"
            keyboard={false}
            className={`filter-drawer ${className}`}
        >
            <Modal.Header className="justify-content-between">
                <Modal.Title>{title} </Modal.Title>
                <button type="button" className="close" onClick={handleClose}>
                    <AppIcon name={"X"} />
                </button>
            </Modal.Header>
            <Modal.Body>{bodyContent}</Modal.Body>
        </Modal>
    );
};
